import React from 'react';
import Slider from 'react-rangeslider';
import SadEmoji from '../../../assets/images/Feedback/sad_emoji.svg';
import BurgerEmoji from '../../../assets/images/Feedback/burger_feedback.svg';
import HappyEmoji from '../../../assets/images/Feedback/happy_emoji.svg';
import 'react-rangeslider/lib/index.css';
import './index.scss';

const SliderComponent = props => {
  const { number, onChangeSlide, theme } = props;

  const data = {
    1: '•',
    2: '•',
    3: '•',
    4: '•',
    5: '•'
  };

  const dataEmoji = [
    {
      images: SadEmoji,
      value: '1'
    },
    {
      images: SadEmoji,
      value: '2'
    },
    {
      images: SadEmoji,
      value: '3'
    },
    {
      images: SadEmoji,
      value: '4'
    },
    {
      images: SadEmoji,
      value: '5'
    }
  ];

  const Dom = (
    <div className={`slider ${theme}`}>
      <Slider
        min={1}
        max={5}
        step={1}
        value={number}
        tooltip={false}
        labels={data}
        onChange={onChangeSlide}
      />
      <div className="slider_text">
        {dataEmoji.map(item => {
          return <p>{item.value}</p>;
        })}
      </div>
      {/* <div className="slider_emojis">
        {dataEmoji.map(item => {
          return (
            <img src={item.images} alt={item.value} width="20" height="20" />
          );
        })}
      </div> */}
    </div>
  );
  return Dom;
};
export default SliderComponent;

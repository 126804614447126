import React from 'react';
import { Provider } from 'react-redux';
import { Route, BrowserRouter } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';

import './index.scss';
import Router from './router/router';
import store from './burger-king-lib/store';

const App = () => {
  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_OAUTH_CLIENTID}>
      <Provider store={store}>
        <BrowserRouter>
          <Route path="/" component={Router} />
        </BrowserRouter>
      </Provider>
    </GoogleOAuthProvider>
  );
};

export default App;

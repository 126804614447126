/* eslint-disable no-unused-expressions */
import './index.scss';

import React from 'react';
import PropTypes from 'prop-types';
import { LocalStorage } from '../../burger-king-lib/utils/storage.web';
import { LocalSessionKey } from '../../burger-king-lib/constants/app-constants';
// import chatBot from '../../utils/chatBot';
const NavMenu = props => {
  const { data, activeMenu, count, theme } = props;
  const isLoggedIn = LocalStorage.get(LocalSessionKey.USER_DATA) || '';
  const callBot = async () => {
    const isLoggedInn =
      (await LocalStorage.get(LocalSessionKey.USER_DATA)) || '';
    if (isLoggedInn) {
      const name = (await LocalStorage.get(LocalSessionKey.NAME)) || '';
      window &&
        window.YellowMessengerPlugin &&
        window.YellowMessengerPlugin.init({
          payload: {
            name,
            mobile: `+91${isLoggedInn}`
          }
        });
    } else {
      window &&
        window.YellowMessengerPlugin &&
        window.YellowMessengerPlugin.hide();
    }
  };
  React.useEffect(() => {
    callBot();
  }, [isLoggedIn]);

  return (
    <ul className={`nav-menu__list ${theme}`}>
      {data.map(item => {
        const { iconpath, name, key, activeIconPath, onClick } = item;
        return (
          <li
            className="nav-menu__item u_cursor_pointer"
            role="presentation"
            onClick={onClick ? () => onClick(key) : ''}
            key={key}
          >
            <img
              src={
                activeMenu.indexOf(key) > -1 ? activeIconPath(theme) : iconpath
              }
              alt={iconpath}
              className="nav-menu__icon"
            />
            {key === '/cart' && count ? (
              <div
                className={`nav-menu__count ${count > 99 ? 'largeCount' : ''}`}
              >
                {count}
              </div>
            ) : (
              ''
            )}
            <span
              className={`nav-menu__name ${
                activeMenu.indexOf(key) > -1 ? 'nav-menu__name_active' : ''
              }`}
            >
              {key === '/account' && isLoggedIn ? 'My Profile' : name}
            </span>
          </li>
        );
      })}
    </ul>
  );
};

NavMenu.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({}))
};

NavMenu.defaultProps = {
  data: []
};

export default NavMenu;

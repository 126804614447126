/* eslint-disable no-nested-ternary */
import './index.scss';

import React from 'react';

import {
  getPriceDelimitedString,
  getCategoryImage
} from '../../utils/services';

const getAddonsName = data => {
  let temp = '';
  if (data && data.length) {
    data.map(item => {
      const { addon_name } = item;
      if (temp) temp += ' + ';
      temp += addon_name;
      return item;
    });
  }
  return temp;
};

const RecentOrderProdList = props => {
  const { data, showImg } = props;

  return (
    <ul className="u_list_style_none or-det-list">
      {data?.map(item => {
        const {
          product_name,
          item_price,
          quantity,
          total_price,
          product_addons,
          total_tax,
          total_payable_amount,
          image_urls,
          variant_name
        } = item;
        const imageUrl = getCategoryImage(image_urls, 'order');
        return (
          <li className="or-det-list__item">
            {showImg ? (
              imageUrl && imageUrl['1x'] ? (
                <div>
                  <picture>
                    <source
                      srcSet={`${imageUrl['1x']}, ${imageUrl['2x']} 2x, ${imageUrl['3x']} 3x`}
                      type="image/jpeg/png"
                    />
                    <img
                      src={`${imageUrl['2x']}`}
                      alt={product_name}
                      className="or-det-list__image"
                    />
                  </picture>
                </div>
              ) : (
                <div className="or-det-list__image-placeholder" />
              )
            ) : (
              ''
            )}

            <div className="or-det-list__detail">
              <span className="or-det-list__name">{product_name}</span>{' '}
              <span className="or-det-list__name">
                {`${!!variant_name ? `(${variant_name})` : ''}`}
              </span>
              {product_addons && product_addons.length ? (
                <p className="or-det-list__addon">
                  {getAddonsName(product_addons)}
                </p>
              ) : (
                ''
              )}
              <p className="or-det-list__qty">
                Rs. {((total_payable_amount - total_tax) / quantity).toFixed(2)}{' '}
                x {quantity}
              </p>
            </div>
            <div className="or-det-list__amount">
              <span className="or-det-list__currency">₹</span>
              {getPriceDelimitedString(total_payable_amount - total_tax)}/-
            </div>
          </li>
        );
      })}
    </ul>
  );
};

export default RecentOrderProdList;

import * as ActionTypes from '../actionTypes';

export const getHomeApiList = (payloadData, meta) => ({
  type: ActionTypes.GET_HOME_API_LIST,
  payload: payloadData,
  meta
});

export const getHomeApiListSuccess = (value, action) => ({
  type: ActionTypes.GET_HOME_API_LIST_SUCCESS,
  value,
  meta: action.meta
});

export const getHomeApiListError = (error, action) => ({
  type: ActionTypes.GET_HOME_API_LIST_ERROR,
  error,
  meta: action.meta
});

export const getMenuList = (payloadData, meta) => ({
  type: ActionTypes.GET_MENU_LIST,
  payload: payloadData,
  meta
});

export const getMenuListSuccess = (value, meta) => ({
  type: ActionTypes.GET_MENU_LIST_SUCCESS,
  value,
  meta
});

export const getMenuListError = (error, action) => ({
  type: ActionTypes.GET_MENU_LIST_ERROR,
  error,
  payload: action.payload,
  meta: action.meta
});

export const getVersionControl = (payloadData, meta) => ({
  type: ActionTypes.GET_VERSION_CONTROL,
  payload: payloadData,
  meta
});

export const getVersionControlSuccess = (value, action) => ({
  type: ActionTypes.GET_VERSION_CONTROL_SUCCESS,
  value,
  meta: action.meta
});

export const getVersionControlError = (error, action) => ({
  type: ActionTypes.GET_VERSION_CONTROL_ERROR,
  error,
  meta: action.meta
});

export const getToken = (payloadData, meta) => ({
  type: ActionTypes.GET_TOKEN,
  payload: payloadData,
  meta
});

export const getTokenSuccess = (value, action) => ({
  type: ActionTypes.GET_TOKEN_SUCCESS,
  value,
  meta: action.meta
});

export const getTokenError = (error, action) => ({
  type: ActionTypes.GET_TOKEN_ERROR,
  error,
  meta: action.meta
});

export const getCategory = (id, meta) => {
  return {
    type: ActionTypes.GET_CATEGORY,
    payload: id,
    meta
  };
};

export const getInvestorTree = (payloadData, meta) => ({
  type: ActionTypes.GET_INVESTORTREEDATA,
  payload: payloadData,
  meta
});
// getInvestorTreeCategory
export const getInvestorTreeCategory = (payloadData, meta) => ({
  type: ActionTypes.GET_INVESTORTREEDATA,
  payload: payloadData,
  meta
});
export const getInvestorTreeSuccess = (value, action) => ({
  type: ActionTypes.GET_INVESTORTREEDATA_SUCCESS,
  value,
  meta: action.meta
});

export const getCategorySuccess = (value, action) => ({
  type: ActionTypes.GET_CATEGORY_SUCCESS,
  value,
  meta: action.meta
});

export const getCategoryError = (error, action) => ({
  type: ActionTypes.GET_INVESTORTREEDATA_ERROR,
  error,
  meta: action.meta
});

export const getInvestorTreeError = (error, action) => ({
  type: ActionTypes.GET_CATEGORY_ERROR,
  error,
  meta: action.meta
});

export const getSubCategory = (payloadData, meta) => ({
  type: ActionTypes.GET_SUBCATEGORY,
  payload: payloadData,
  meta
});

export const getSubCategorySuccess = (value, action) => ({
  type: ActionTypes.GET_SUBCATEGORY_SUCCESS,
  value,
  meta: action.meta
});

export const getSubCategoryError = (error, action) => ({
  type: ActionTypes.GET_SUBCATEGORY_ERROR,
  error,
  payload: action.payload,
  meta: action.meta
});
// getOutletData;
export const getNutrition = (payloadData, meta) => ({
  type: ActionTypes.GET_NUTRITIONAL,
  payload: payloadData,
  meta
});

export const getNutritionSuccess = (value, action) => ({
  type: ActionTypes.GET_NUTRITIONAL_SUCCESS,
  value,
  meta: action.meta
});

export const getNutritionError = (error, action) => ({
  type: ActionTypes.GET_NUTRITIONAL_ERROR,
  error,
  meta: action.meta
});

export const getOutletData = (payloadData, meta) => ({
  type: ActionTypes.GET_OUTLETDATA,
  payload: payloadData,
  meta
});

export const getOutletDataSuccess = (value, action) => ({
  type: ActionTypes.GET_OUTLETDATA_SUCCESS,
  value,
  meta: action.meta
});

export const getOutletDataError = (error, action) => ({
  type: ActionTypes.GET_OUTLETDATA_ERROR,
  error,
  meta: action.meta
});

export const getRedisCartItem = (payloadData, meta) => ({
  type: ActionTypes.GET_REDIS_CART,
  payload: payloadData,
  meta
});

export const getRedisCartItemSuccess = (value, action) => ({
  type: ActionTypes.GET_REDIS_CART_SUCCESS,
  value,
  meta: 'Home'
});

export const getRedisCartItemError = (error, action) => ({
  type: ActionTypes.GET_REDIS_CART_ERROR,
  error,
  payload: action.payload,
  meta: action.meta
});

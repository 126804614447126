import './index.scss';

import React, { Component } from 'react';

import HomeIconImage from '../../../assets/images/home-inactive.svg';
import WorkIconImage from '../../../assets/images/work-black.svg';
import OtherconImage from '../../../assets/images/location-inactive.svg';

class AddressCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDropdownContent: false,
      activeDropdownIndex: ''
    };
  }

  toggleDropdownContent = (e, index) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ showDropdownContent: true, activeDropdownIndex: index });
  };

  editAddressClick = (e, data) => {
    e.preventDefault();
    e.stopPropagation();
    const { editAddressHanlder } = this.props;
    editAddressHanlder(data);
    this.setState({ activeDropdownIndex: '' });
  };

  deleteAddressClick = (e, data) => {
    e.preventDefault();
    e.stopPropagation();
    const { deleteAddressHandler } = this.props;
    deleteAddressHandler(data);
    this.setState({ activeDropdownIndex: '' });
  };

  getLabelIcon = value => {
    if (value === 'work') return WorkIconImage;
    if (value === 'home') return HomeIconImage;
    return OtherconImage;
  };

  render() {
    const { addressList, onClick, theme } = this.props;
    const { showDropdownContent, activeDropdownIndex } = this.state;

    return (
      <div className="address-card u_cursor_pointer">
        {addressList && addressList.length > 0
          ? addressList.map((data, index) => {
              const { label, address, location } = data;
              return (
                <div
                  className="address-card__content"
                  role="presentation"
                  onClick={onClick ? () => onClick(data) : ''}
                >
                  <div>
                    <img
                      src={this.getLabelIcon(label)}
                      alt="icon"
                      className="address-card__image"
                    />
                  </div>
                  <div className="address-card__details">
                    <p className="address-card__name">
                      {label.charAt(0).toUpperCase() + label.slice(1)}
                    </p>
                    <p className="address-card__description">
                      {location}, {address}
                    </p>
                  </div>
                  <div
                    className="address-card__dropdown"
                    role="presentation"
                    onClick={e => this.toggleDropdownContent(e, index)}
                  >
                    <ul className="u_list_style_none address-card__dropdown-dot_wrapper">
                      <li className={`address-card__dropdown-dot ${theme}`} />
                      <li className={`address-card__dropdown-dot ${theme}`} />
                      <li className={`address-card__dropdown-dot ${theme}`} />
                    </ul>
                    {showDropdownContent && activeDropdownIndex === index ? (
                      <ul className="u_list_style_none address-card__drop-content">
                        <li
                          className={`address-card__drop-content-list ${theme}`}
                          role="presentation"
                          onClick={e => this.editAddressClick(e, data)}
                        >
                          Edit
                        </li>
                        <li
                          className={`address-card__drop-content-list ${theme}`}
                          role="presentation"
                          onClick={e => this.deleteAddressClick(e, data)}
                        >
                          Delete
                        </li>
                      </ul>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              );
            })
          : ''}
      </div>
    );
  }
}

export default AddressCard;

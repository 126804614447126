import './index.scss';

import React, { Component } from 'react';
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';

import LocationInputEditable from '../location-input-editable';
import InputLabelFloat from '../input-label-float';
import SaveAddress from '../cart-save-address';

import { getLeftArrowImage } from '../../utils/imageChanges';
import { LocalStorage } from '../../burger-king-lib/utils/storage.web';
// import { LocalSessionKey } from '../../burger-king-lib/constants/app-constants';
import { deliveryAddressStorage } from '../../utils/services';
import { LocalSessionKey } from '../../burger-king-lib/constants/app-constants';

class SetAddress extends Component {
  constructor(props) {
    super(props);
    const deliveryAddress = deliveryAddressStorage.get();
    const { editData } = props;
    const latlng = LocalStorage.get(LocalSessionKey.LAT_LNG);
    const { lat } = latlng;
    const { lng } = latlng;
    this.state = {
      address: (editData && editData.address) || '',
      landmark: (editData && editData.landmark) || '',
      currentFieldFocus: '',
      activeSaveAddress: this.getInitialLabel(),
      location:
        (editData && editData.location) ||
        (deliveryAddress && deliveryAddress.location) ||
        '',
      pincode:
        (editData && editData.pincode) ||
        (deliveryAddress && deliveryAddress.pincode) ||
        '',
      latitude:
        (editData && editData.latitude) ||
        (deliveryAddress && deliveryAddress.latitude) ||
        lat,
      longitude:
        (editData && editData.longitude) ||
        (deliveryAddress && deliveryAddress.longitude) ||
        lng,
      Latlng: {
        lat:
          (editData && editData.latitude) ||
          (deliveryAddress && deliveryAddress.latitude) ||
          lat,
        lng:
          (editData && editData.longitude) ||
          (deliveryAddress && deliveryAddress.longitude) ||
          lng
      },
      activeSaveAsValue: this.getInitialValue()
    };
  }

  componentDidMount() {
    const { location } = this.state;
    const { editData } = this.props;
    if (location && !editData) {
      this.locationChangeHandleSelect(location);
    }
  }

  getInitialLabel = () => {
    const { editData } = this.props;
    if (editData && editData.label) {
      if (editData.label !== 'Work' && editData.label !== 'Home')
        return 'Other';
      return editData.label;
    }
    return '';
  };

  getInitialValue = () => {
    const { editData } = this.props;
    if (editData && editData.label) {
      if (editData.label !== 'Work' && editData.label !== 'Home')
        return editData.label;
    }
    return '';
  };

  locationChangeHandler = value => {
    this.setState({ location: value });
  };

  locationChangeHandleSelect = value => {
    this.setState({ location: value });
    geocodeByAddress(value)
      .then(results => getLatLng(results[0]))
      .then(latLng => {
        const { lat, lng } = latLng;
        const json = { lat, lng };
        this.setState({ latitude: lat, longitude: lng });
        this.getPincode(json);
      })
      .catch(error => console.error('Error', error));
  };

  getPincode = latLng => {
    const { google } = window;
    const geocoder = new google.maps.Geocoder();

    geocoder.geocode(
      { latLng },
      (results, status) => {
        if (status === google.maps.GeocoderStatus.OK) {
          if (
            results &&
            results.length &&
            results[1] &&
            results[1].formatted_address
          ) {
            let pincode = '';
            results[1].address_components.forEach(data => {
              if (data && data.types) {
                const filter = data.types.filter(
                  item => item === 'postal_code'
                );
                if (filter && filter.length) pincode = data.long_name;
              }
              return data;
            });
            const { lat, lng } = latLng;
            this.setState({
              location: results[1].formatted_address,
              pincode,
              latitude: lat,
              longitude: lng,
              Latlng: latLng
            });
          }
        }
      },
      this
    );
  };

  inputChangeHandler = (id, value) => {
    this.setState({ [id]: value });
  };

  inputOnFocus = id => {
    this.setState({ currentFieldFocus: id });
  };

  saveAddressToggle = (key, value) => {
    this.setState({ activeSaveAddress: key, activeSaveAsValue: value });
  };

  onSaveAddressClick = () => {
    const { onSaveAddressClick, editData } = this.props;
    const {
      location,
      activeSaveAsValue,
      activeSaveAddress,
      landmark,
      address,
      latitude,
      longitude,
      pincode
    } = this.state;

    const payload = {
      id: editData && editData.id,
      save_as:
        activeSaveAddress === 'Other' ? activeSaveAsValue : activeSaveAddress,
      location,
      latitude,
      longitude,
      pincode,
      landmark,
      address
    };
    payload.save_as =
      payload.save_as.charAt(0).toUpperCase() + payload.save_as.slice(1);
    if (!payload.latitude || !payload.longitude) {
      const storedLatLng = localStorage.getItem('LAT_LNG');

      if (storedLatLng) {
        const { lat, lng } = JSON.parse(storedLatLng);

        if (!payload.latitude) {
          payload.latitude = parseFloat(lat);
        }

        if (!payload.longitude) {
          payload.longitude = parseFloat(lng);
        }
      }
    }
    onSaveAddressClick(payload);
  };

  render() {
    const {
      address,
      landmark,
      currentFieldFocus,
      activeSaveAddress,
      location,
      activeSaveAsValue,
      Latlng
    } = this.state;
    const { goBackSetAddressClick, theme } = this.props;
    const { onSaveAddressClick } = this;
    const headerDom = (
      <div className="set-address__header">
        <div className="set-address__left">
          <div
            className="set-address__arrow u_cursor_pointer"
            role="presentation"
            onClick={() =>
              goBackSetAddressClick(
                '132',
                address,
                landmark,
                activeSaveAddress,
                location
              )
            }
          >
            <img src={getLeftArrowImage(theme)} alt="left arrow" />
          </div>
          <div className="set-address__title">Delivery Address</div>
        </div>
      </div>
    );

    return (
      <div className="set-address">
        {headerDom}
        <div className="set-address__content">
          <div className="set-address__location">
            <LocationInputEditable
              customClass="set-address__location-input"
              onLocationChange={this.locationChangeHandler}
              handleSelect={this.locationChangeHandleSelect}
              value={location}
              theme={theme}
            />
          </div>
          <div className="set-address__input">
            <InputLabelFloat
              id="address"
              type="text"
              label="Flat No/House/Building"
              value={address}
              onChange={this.inputChangeHandler}
              currentFieldFocus={currentFieldFocus}
              onFocus={this.inputOnFocus}
              theme={theme}
              max="30"
            />
          </div>
          <div className="set-address__input">
            <InputLabelFloat
              id="landmark"
              type="text"
              label="Landmark"
              value={landmark}
              onChange={this.inputChangeHandler}
              currentFieldFocus={currentFieldFocus}
              onFocus={this.inputOnFocus}
              theme={theme}
              max="50"
            />
          </div>
          <div className="set-address__address">
            <SaveAddress
              toggleClick={this.saveAddressToggle}
              activeSaveAddress={activeSaveAddress}
              onSaveAddressClick={onSaveAddressClick}
              addressValue={address}
              landmarkValue={landmark}
              locationValue={location}
              activeSaveAsValue={activeSaveAsValue}
              theme={theme}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default SetAddress;

/* eslint-disable */
// import ReactGA from "react-ga";

const setUserId = value => {
  try {
    window.dataLayer.push({
      userId: value
    });
    window.dataLayer.push({
      dimension1: value
    });
  } catch (ex) {
    console.log('Exception:', ex);
  }
};

const PageView = currentScreen => {
  try {
    if (currentScreen) {
      window.dataLayer.push({
        event: currentScreen
      });
    }
  } catch (ex) {
    console.log('Exception:', ex);
  }
};

const logEvent = data => {
  try {
    window.dataLayer.push({
      event: data.eventName
    });
  } catch (ex) {
    console.log('Exception:', ex);
  }
};

const logEventWithVariable = (data, action) => {
  try {
    if (data) {
      if (data.key) {
        let info = {
          event: data.eventName
        };
        info[data.key] = action;
        window.dataLayer.push(info);
      } else {
        window.dataLayer.push({
          event: data.eventName
        });
      }
    }
  } catch (ex) {
    console.log('Exception:', ex);
  }
};

const Checkout = (event, payload) => {
  try {
    window.dataLayer.push({
      event: event,
      checkout: payload
    });
  } catch (ex) {
    console.log('Exception:', ex);
  }
};

const Order = {
  addToCart: {
    eventName: 'add_to_cart',
    key: 'add_to_cart'
  },
  paymentMode: {
    eventName: 'add_payment_info',
    key: 'add_payment_info'
  },
  login: {
    eventName: 'login',
    key: 'user_login'
  },
  googleLogin: {
    eventName: 'google-login',
    key: 'user_login'
  },
  fbLogin: {
    eventName: 'facebook-login',
    key: 'user_login'
  },
  productView: {
    eventName: 'product_view',
    key: 'product'
  },
  checkout: {
    eventName: 'purchase',
    key: 'purchase'
  },
  location: {
    eventName: 'location',
    key: 'location'
  }
};

export const Analytics = {
  logEvent,
  logEventWithVariable,
  PageView,
  Order,
  Checkout
};

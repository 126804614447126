import './index.scss';

import React from 'react';

import Button from '../button';

import OnboardNoDataImg from '../../../assets/images/onboard-nodata.png';

const OnboardNoData = props => {
  const { isEnterLocationWrong } = props;
  return (
    <div className="ob-search-empty">
      <img
        src={OnboardNoDataImg}
        alt="onboard-nodata"
        height="238px"
        className="ob-search-empty__image"
      />
      <div className="ob-search-empty__content">
        {isEnterLocationWrong ? (
          <>
            <h3 className="ob-search-empty__title">
              Is this a real place? Or is this fantasy?
            </h3>
            <p className="ob-search-empty__desc">
              We can’t find the entered location :( Check for spelling errors or
              places nearby
            </p>
            <div className="ob-search-empty__button">
              <Button
                size="is-medium"
                type="block"
                content="Detect Current Location"
              />
            </div>
          </>
        ) : (
          <>
            <h3 className="ob-search-empty__title">It’s not you, it’s us :(</h3>
            <p className="ob-search-empty__desc">
              We don’t deliver to your current location. We’ll be there soon!
              Till then, try another location
            </p>
          </>
        )}
      </div>
    </div>
  );
};

export default OnboardNoData;

import './index.scss';

import React from 'react';

import Button from '../button';

import KingMustDineImage from '../../../assets/images/king-must-dine.png';

const RecentOrdersEmpty = props => {
  const { orderNowClick, theme } = props;
  return (
    <div className="recent-orders-empty">
      <img className="recent-orders-empty__image" src={KingMustDineImage} alt="recent order empty" />
      <p className="recent-orders-empty__text">A King must dine.</p>
      <div className="recent-orders-empty__btn">
        <Button 
          content="Order Now"
          size="is-large"
          onClick={orderNowClick}
          theme={theme}
        />
      </div>
    </div>
  )
}

export default RecentOrdersEmpty;
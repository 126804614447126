import {
  GET_PRODUCT_LIST,
  GET_PRODUCT_LIST_SUCCESS,
  GET_PRODUCT_LIST_ERROR,
  GET_PRODUCT_LIST2,
  GET_PRODUCT_LIST2_SUCCESS,
  GET_PRODUCT_LIST2_ERROR,
  GET_PRODUCT_FROM_CART,
  GET_PRODUCT_FROM_CART_SUCCESS,
  GET_PRODUCT_FROM_CART_ERROR,
  GET_SEARCH_SUGGESTIONS_ERROR,
  GET_SEARCH_SUGGESTIONS_SUCCESS,
  GET_SEARCH_SUGGESTIONS,
  GET_MENU_CATEGORY,
  GET_MENU_CATEGORY_ERROR,
  GET_MENU_CATEGORY_SUCCESS,
  GET_ALL_ADDONS,
  GET_ALL_ADDONS_LOADER,
  GET_ALL_ADDONS_SUCCESS,
  GET_ALL_ADDONS_ERROR,
  GET_SEARCH_PRODUCT_LIST,
  GET_SEARCH_PRODUCT_LIST_SUCCESS,
  GET_SEARCH_PRODUCT_LIST_ERROR
} from '../actionTypes';

const initialState = {
  productList: {},
  isLoading: true
};

export default function ProductListReducer(state = initialState, action) {
  switch (action.type) {
    case GET_PRODUCT_LIST: {
      return {
        ...state,
        Payload: action.payload,
        action: action.type,
        productList: {},
        Error: null,
        meta: action.meta
      };
    }
    case GET_PRODUCT_LIST_SUCCESS: {
      return {
        ...state,
        Payload: {},
        action: action.type,
        productList: action.value,
        Error: null,
        meta: action.meta
      };
    }
    case GET_PRODUCT_LIST_ERROR: {
      return {
        ...state,
        Payload: action.payload,
        action: action.type,
        productList: {},
        Error: action.error,
        meta: action.meta
      };
    }

    case GET_PRODUCT_LIST2: {
      return {
        ...state,
        Payload: action.payload,
        action: action.type,
        productList: {},
        Error: null,
        meta: action.meta
      };
    }
    case GET_PRODUCT_LIST2_SUCCESS: {
      return {
        ...state,
        Payload: {},
        action: action.type,
        productList: action.value,
        Error: null,
        meta: action.meta
      };
    }
    case GET_PRODUCT_LIST2_ERROR: {
      return {
        ...state,
        Payload: action.payload,
        action: action.type,
        productList: {},
        Error: action.error,
        meta: action.meta
      };
    }

    case GET_SEARCH_PRODUCT_LIST: {
      return {
        ...state,
        Payload: action.payload,
        action: action.type,
        productList: {},
        Error: null,
        meta: action.meta
      };
    }
    case GET_SEARCH_PRODUCT_LIST_SUCCESS: {
      return {
        ...state,
        Payload: {},
        action: action.type,
        productList: action.value,
        Error: null,
        meta: action.meta
      };
    }
    case GET_SEARCH_PRODUCT_LIST_ERROR: {
      return {
        ...state,
        Payload: action.payload,
        action: action.type,
        productList: {},
        Error: action.error,
        meta: action.meta
      };
    }

    case GET_PRODUCT_FROM_CART: {
      return {
        ...state,
        Payload: action.payload,
        action: action.type,
        productCart: {},
        Error: null,
        meta: action.meta
      };
    }
    case GET_PRODUCT_FROM_CART_SUCCESS: {
      return {
        ...state,
        Payload: {},
        action: action.type,
        productCart: action.value,
        Error: null,
        meta: action.meta
      };
    }
    case GET_PRODUCT_FROM_CART_ERROR: {
      return {
        ...state,
        Payload: action.payload,
        action: action.type,
        productCart: {},
        Error: action.error,
        meta: action.meta
      };
    }

    case GET_SEARCH_SUGGESTIONS: {
      return {
        ...state,
        Payload: action.payload,
        action: action.type,
        searchSuggestions: {},
        Error: null,
        meta: action.meta
      };
    }
    case GET_SEARCH_SUGGESTIONS_SUCCESS: {
      return {
        ...state,
        Payload: {},
        action: action.type,
        searchSuggestions: action.value,
        Error: null,
        meta: action.meta
      };
    }
    case GET_SEARCH_SUGGESTIONS_ERROR: {
      return {
        ...state,
        Payload: action.payload,
        action: action.type,
        searchSuggestions: {},
        Error: action.error,
        meta: action.meta
      };
    }

    case GET_MENU_CATEGORY: {
      return {
        ...state,
        Payload: action.payload,
        action: action.type,
        productAddonDetail: {},
        Error: null,
        meta: action.meta
      };
    }
    case GET_MENU_CATEGORY_SUCCESS: {
      return {
        ...state,
        Payload: {},
        action: action.type,
        productAddonDetail: action.value,
        Error: null,
        meta: action.meta
      };
    }
    case GET_MENU_CATEGORY_ERROR: {
      return {
        ...state,
        Payload: action.payload,
        action: action.type,
        productAddonDetail: {},
        Error: action.error,
        meta: action.meta
      };
    }

    case GET_ALL_ADDONS: {
      return {
        ...state,
        Payload: action.payload,
        action: action.type,
        getAllAddons: {},
        Error: null,
        isLoading: true,
        meta: action.meta
      };
    }
    case GET_ALL_ADDONS_SUCCESS: {
      let tempData = undefined;
      tempData = action?.value?.content?.result?.map(item => {
        if (
          action.meta !== 'cart' &&
          action.meta !== 'pl' &&
          action.meta !== 'search' &&
          item?.card_type === 'Vertical' &&
          item?.is_allocated === false
        ) {
          if (
            (action.extraKey && action.extraKey === 'normal') ||
            (!action.extraKey && !action.meta)
          )
            item.products[0].quantity = 1;
        } else if (
          action.meta !== 'cart' &&
          action.meta !== 'pl' &&
          action.meta !== 'search' &&
          item?.card_type === 'Vertical' &&
          item?.is_allocated === true
        ) {
          item.products[0].quantity = 0;
        } else {
          if (
            action.extraKey &&
            action.extraKey === 'normal' &&
            !item?.is_allocated &&
            item?.card_type === 'Vertical'
          ) {
            item.products[0].quantity = 1;
          } else if (
            (action.meta === 'pl' || action.meta === 'cart') &&
            action.extraKey === 'addnew' &&
            item?.is_allocated === false &&
            item?.card_type === 'Vertical'
          ) {
            item.products[0].quantity = 1;
          }
          // item.products[0].isSelected = false;
        }
        return item;
      });
      const response = {
        flex_data: action?.value?.content?.flex_data,
        result: tempData
      };
      return {
        ...state,
        Payload: {},
        action: action.type,
        getAllAddons: { ...action.value, content: response },
        Error: null,
        isLoading: false,
        meta: action.meta
      };
    }
    case GET_ALL_ADDONS_ERROR: {
      return {
        ...state,
        Payload: action.payload,
        action: action.type,
        getAllAddons: {},
        Error: action.error,
        meta: action.meta,
        isLoading: false
      };
    }
    case GET_ALL_ADDONS_LOADER: {
      return {
        ...state,
        isLoading: true
      };
    }
    default: {
      return {
        ...state
      };
    }
  }
}

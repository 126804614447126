import React from 'react';
import moment from 'moment';

import HistoryDropDown from '../history-dropdown';

import './index.scss';

const HistoryTable = props => {
  const {
    data,
    content,
    title,
    customClass,
    onSelectedDropdown,
    theme
  } = props;
  return (
    <div className="history-table">
      <div className="history-table__header">
        <h2 className="history-table__header-title">{title}</h2>
        <HistoryDropDown
          content={content}
          customClass={customClass}
          theme={theme}
          onSelectedDropdown={onSelectedDropdown}
        />
      </div>
      <div className="history-table__contents">
        <ul className="u_list_style_none history-table__contents-list">
          {data && data.length > 0 ? (
            data.map(item => {
              const {
                display_name,
                is_redeemed,
                accural_points,
                reddem_points,
                date
              } = item;
              return (
                <li className="history-table__contents-list-item">
                  <div className="history-table__contents-list-item_content">
                    <p className="history-table__contents-list-item_name">
                      {display_name}
                    </p>
                    <p className="history-table__contents-list-item_value">
                      {date ? moment(date).format('DD MMM YYYY') : ''}
                    </p>
                  </div>
                  <div className="history-table__contents-list-item_crown">
                    <p
                      className={`history-table__contents-list-item_text ${
                        is_redeemed ? 'add' : ''
                      }`}
                    >
                      {!is_redeemed ? accural_points : reddem_points}
                    </p>
                  </div>
                </li>
              );
            })
          ) : (
            <li className="history-table__contents-list-noitem">
              No Data Found
            </li>
          )}
        </ul>
      </div>
    </div>
  );
};

HistoryTable.propTypes = {
  //   columns: PropTypes.arrayOf(PropTypes.shape({})),
  //   data: PropTypes.arrayOf(PropTypes.shape({})),
  //   onColumnClick: PropTypes.func
};

HistoryTable.defaultProps = {
  //   data: [],
  //   columns: [],
  //   onColumnClick: () => {}
};

export default HistoryTable;

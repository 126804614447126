import './index.scss';

import React, { Component } from 'react';
import { withRouter } from 'react-router';

import AccountSubSecTitle from '../../components/accout-subsec-title';
import Footer from '../../containers/footer';

class PrivacyPolicy extends Component {
  goBackOnClick = () => {
    const { history } = this.props;
    history.go(-1);
  };

  render() {
    const { goBackOnClick } = this;
    const { theme } = this.props;
    const { location } = window;
    const { pathname } = location;
    return (
      <div
        className={`privacy-pg_wrapper ${
          pathname === '/privacy-policy' ? 'privacy-pg_full' : ''
        }`}
      >
        {pathname === '/privacy-policy' ? (
          ''
        ) : (
          <AccountSubSecTitle
            title="Privacy Policy"
            goBackOnClick={goBackOnClick}
            hideBackArrow={pathname === '/privacy-policy' || false}
            theme={theme}
          />
        )}
        <div className="privacy-pg">
          {pathname === '/privacy-policy' ? (
            <AccountSubSecTitle
              title="Privacy Policy"
              goBackOnClick={goBackOnClick}
              hideBackArrow={pathname === '/privacy-policy' || false}
              theme={theme}
            />
          ) : (
            ''
          )}
          <div className="privacy-pg__content">
            <p className="privacy-pg__info">
              We are committed to safeguarding the privacy of the Personal
              Information (as defined hereinafter) that we collect, possess,
              use, process, record, store, transfer, disclose, deal, handle and
              receive, by and on behalf of Our employees and customers while
              rendering Services (as defined hereinafter) through or outside of
              Our Sites. Accordingly, set out below is Our Privacy Policy (as
              defined hereinafter), pursuant to Rule 3(1)(a) of the IT
              Intermediary Rules (as defined hereinafter), setting out how We
              intend to safeguard such personal information received and used by
              Us as aforesaid. This is in compliance with the prevailing
              Information Technology Act, 2000 (“IT Act”), the Information
              Technology (Reasonable Security Practices And Procedures And
              Sensitive Personal Data or Information) Rules, 2011 (“SPDI Rules”)
              and the Information Technology (Intermediary Guidelines and
              Digital Media Ethics Code) Rules, 2021 (“Intermediary Rules”) as
              may be amended from time to time. This is an electronic record
              under the IT Act, is generated by a computer system, and does not
              require any physical or digital signatures.
            </p>
            <p className="privacy-pg__info">
              Restaurant Brands Asia Limited incorporated under the Companies
              Act, 1956 (“Company”,
              &quot;RBAL&quot;, &quot;We&quot;, &quot;Our&quot; or &quot;Us&quot;)
              is inter alia engaged in the business of developing, setting up,
              running &amp; operating a chain of restaurants (including Quick
              Service Restaurant)/Eating House etc (“Restaurants”) and has
              designed developed and operates website i.e. www.burgerking.in and
              a mobile application (or any successor site) and its related
              sub-domains, sites, services and tools (“Sites”) which provides
              You information, recommendations and/ or products or services
              offered to You at Our various Restaurants and mode of distribution
              channel and is intended to allow You and other users to browse and
              order products and other goods or services, which will be
              fulfilled by RBAL and/or its channel partners being Third Person
              (as defined hereinafter) subject to the terms and conditions
              mentioned on the respective Sites ( “Services”).
            </p>
            <p className="privacy-pg__info">
              This privacy policy, as amended from time to time, (“Privacy
              Policy” or “Policy”) discloses the privacy practices for the
              respective Sites. It applies to the Company, its affiliates and
              group companies, its directors, employees, consultants, retainers,
              contractual staff, contractors, staff and team members, service
              providers and vendors who collect, possess, use, process, record,
              store, transfer, disclose, deal, handle and receive, the personal
              information by and on behalf of the employees and clients of the
              Company (“You” or “Your”) while rendering the Services in India or
              overseas including but not limited to any consultants, service
              providers, contractors, advisors, accountants, agents, person,
              representatives of the Company etc. who provide services to or on
              the Company’s behalf in connection with the Services rendered
              (“Third Person”) by the Company.
            </p>
            <p className="privacy-pg__info">
              By accessing the respective Sites and/or providing Us with Your
              information You expressly consent to Us processing Your personal
              information in accordance with the terms of the Privacy Policy. We
              reserve the right to delete, modify, change or supplement the
              content of the respective Sites and or this Privacy Policy,
              including the addition and removal of any sub-domain, successor
              sites, mobile application, Services and tools at any time for any
              reason without prior notification, and will use reasonable efforts
              to include up-to-date and accurate information on the respective
              Sites.
            </p>

            <p className="privacy-pg__title  u_text_transform_uppercase">
              PLEASE READ THIS PRIVACY POLICY CAREFULLY. BY ACCESSING OR USING
              OUR SITES, YOU ACKNOWLEDGE THAT YOU HAVE READ, UNDERSTAND, AND
              AGREE TO BE BOUND TO ALL THE TERMS OF THIS PRIVACY POLICY AND OUR
              TERMS OF USE AND ANY OTHER TERMS AND CONDITION ON THE SITES. IF
              YOU DO NOT AGREE TO THESE TERMS ANY OTHER TERMS AND CONDITION ON
              THE SITES, EXIT THIS PAGE AND DO NOT ACCESS OR USE THE SITES.
            </p>
            <ol type="1" className="privacy-pg__list">
              <p className="privacy-pg__info">
                <li>
                  <span className="privacy-pg__title">
                    Change in the Privacy Policy:
                  </span>
                  Changes in law or changes in RBAL’s business may require
                  changes to be made to the terms of this Privacy Policy from
                  time to time so RBAL encourages You to review this Privacy
                  Policy periodically or on every occasion You visit or use the
                  Sites for any such changes. It is Your responsibility to
                  carefully read, agree with the Privacy Policy on each occasion
                  You use the Sites and Your continued use of the Sites shall
                  signify Your acceptance to be bound by the latest Privacy
                  Policy.
                </li>
              </p>
            </ol>
            <p className="privacy-pg__title ">Information Collected </p>
            <p className="privacy-pg__info">
              The Information we collect about you may be broadly described in
              following categories: 
            </p>
            <ol type="1" className="privacy-pg__list">
              <p className="privacy-pg__info">
                <li>
                  <b>Personal Information:</b> Your name, gender, address, phone
                  number, email address, user name, occupation, password, date
                  of birth, billing and credit card information, bank account
                  details, order history, feedback, IP address and location,
                  digital device name, type and configuration, details relating
                  to Your download of Publications via the service (including
                  details relating to the time of the download, any
                  interruptions, and the success or failure of the downloads),
                  the URL from which You accessed the Sites, demographic
                  information or opinions, registration or profile information
                  sent to us by connected services such as social media handles
                  as may be authorized by you, search contact book if consented
                  by the user and other such detail relating to the above as
                  provided to the Company; or any of the information received
                  under the above by the Company for collecting, receiving,
                  possessing, using, processing, recording, storing,
                  transferring, dealing, handling and disclosing under lawful
                  contract or otherwise, collectively referred to as “Personal
                  Information”.
                </li>{' '}
              </p>
              <p className="privacy-pg__info">
                {' '}
                <li>
                  <b>General Information:</b> We may also receive more general
                  information from you as a result of your visits to, and use
                  of, the respective Sites. This general information does not
                  identify you personally, and can include information such as
                  your IP address, the length of time you spend on the Website
                  and the Mobile App, your browsing history, and other such
                  &lsquo;General Information&rsquo;.
                </li>
              </p>
            </ol>
            <p className="privacy-pg__info">
              This Policy does not apply to any information that You may
              disclose publicly, and which is freely available or accessible in
              public domain or furnished under the Right to Information Act,
              2005 or any other law for the time being in force and such
              information shall not be regarded as Personal Information for the
              purposes of this Policy. Further, Your Personal Information shall
              not be disclosed to any Third Person unless the said Third Person
              is required to have the Personal Information of You to provide
              required the Services to You and/or with Your permission and/or to
              help investigate, prevent or take action regarding unlawful and
              illegal activities, suspected fraud, potential threat to the
              safety or security of any person, violations of the terms and
              conditions of use of the Sites or to defend against legal claims
              and special circumstances such as compliance with the rules of any
              stock exchange, subpoenas, court orders, requests/order from legal
              authorities or law enforcement agencies requiring such disclosure.
              We share Your information with advertisers on an aggregate basis
              only.
            </p>
            <p className="privacy-pg__info">
              Further, any information disclosed by You to any service provider
              or trader or any third party that You connect with through the
              Sites, the Company shall not be responsible and/or liable to treat
              the same as the Personal Information to be protected by the
              Company.
            </p>
            <p className="privacy-pg__info">
              You are providing Your consent by providing Your Information (as
              defined hereinabove) to the Company (“Consent”) with respect to
              the purpose of usage of such Information as provided below
              (“Purpose”). You hereby state that You are aware of the:
            </p>

            <ol type="a" className="privacy-pg__list">
              <p className="privacy-pg__info">
                <li>fact that the Personal Information is being collected;</li>
              </p>
              <p className="privacy-pg__info">
                <li>
                  contents and/or type of Personal Information which is being
                  collected;
                </li>
              </p>
              <p className="privacy-pg__info">
                <li>
                  purpose for which the Personal Information is being collected;
                </li>
              </p>
              <p className="privacy-pg__info">
                <li>intended recipients of the Personal Information; and</li>
              </p>
              <p className="privacy-pg__info">
                <li>
                  name and address of the agency that is collecting the Personal
                  Information and retaining the same.
                </li>
              </p>
            </ol>
            <p className="privacy-pg__info">
              {' '}
              All the above Information is collected for the following Purpose:
            </p>
            <ol type="1" className="privacy-pg__list">
              <p className="privacy-pg__info">
                {' '}
                <li>Better servicing and account maintenance;</li>
              </p>
              <p className="privacy-pg__info">
                {' '}
                <li>
                  To seek Your feedback or to contact You in relation to those
                  Services
                </li>
              </p>{' '}
              <p className="privacy-pg__info">
                {' '}
                <li>
                  Making the offerings more relevant to the You and Your desires
                  ensuring no spam mails go through;
                </li>
              </p>{' '}
              <p className="privacy-pg__info">
                {' '}
                <li>
                  Keeping track of the transactions, to help in case of any
                  dispute situations; dealing with enquiries, requests or
                  complaints and other customer care related activities; and all
                  other general administrative and business purposes; marketing
                  products and services; carrying out any activity in connection
                  with a legal, governmental or regulatory requirement or in
                  connection with legal proceedings, crime or fraud prevention,
                  detection or prosecution;
                </li>
              </p>{' '}
              <p className="privacy-pg__info">
                {' '}
                <li>
                  To effectively administer and fulfil Our obligations under any
                  agreement (if any) We may have with You;
                </li>
              </p>{' '}
              <p className="privacy-pg__info">
                {' '}
                <li>
                  maintenance of internal employee databases in accordance with
                  applicable data privacy and labour laws, and the provision of
                  benefits to the employees, including but not limited to
                  provision of group insurance policies, and the monitoring of
                  certain kinds of employee activity;
                </li>
              </p>{' '}
              <p className="privacy-pg__info">
                {' '}
                <li>
                  record keeping and other general administrative and Services
                  related processes;
                </li>
              </p>{' '}
              <p className="privacy-pg__info">
                {' '}
                <li>
                  ensuring the safety and protection of the rights or property
                  of the Company or its business;
                </li>
              </p>{' '}
              <p className="privacy-pg__info">
                {' '}
                <li>
                  complying with applicable legal requirements in India
                  including but not limited to governmental reporting, etc. and
                  fulfilling statutory/legal obligations as a service provider
                  under applicable laws, and adhering to judicial or
                  administrative orders, compliance with laws;
                </li>
              </p>{' '}
              <p className="privacy-pg__info">
                {' '}
                <li>
                  monitoring or recording of certain calls, chats and other
                  interactions as described hereinbelow;
                </li>
              </p>{' '}
              <p className="privacy-pg__info">
                {' '}
                <li>
                  while conducting daily business/operations such Personal
                  Information may be provided to affiliates and group companies,
                  certain levels of employees/staff of the Company and Third
                  Person’s for the purpose of processing such Personal
                  Information for or on Our behalf including but not limited to
                  helping Us to perform statistical analysis, send You email or
                  postal mail, provide Clients and/or prospective Clients the
                  support/support services, arrange for deliveries of programs,
                  information, and services, etc.;
                </li>
              </p>{' '}
              <p className="privacy-pg__info">
                {' '}
                <li>
                  operating the Sites, improving the content of Our Sites to
                  offer You better Services and to ensure that content from Our
                  Sites is presented in the most effective manner for You; and
                  in connection with the business of the Company.
                </li>
              </p>
            </ol>
            <p className="privacy-pg__info">
              {' '}
              To collect the Information, we shall use channels such as physical
              forms, call centre, Website (including cookies), Mobile App
              emails, promotional offers, surveys and mailers. Participation in
              some of these channels is optional but you may be required to
              provide your Information in order to participate. We are not
              liable to verify the authenticity or accuracy of any Information
              we collect from you. 
            </p>
            <p className="privacy-pg__info">
              From time to time We may ask You to update Your Information with
              Us on the Sites, contact centre or through any other approved
              channels. This is to help keep You updated on services, any
              changes in the services and its enhancements. We will not be
              liable for any failure or default to notify You of any service,
              any change in service or its enhancement if You fail to update
              such Information in a timely manner.
            </p>
            <p className="privacy-pg__title">Access to Information:</p>
            <p className="privacy-pg__info">
              {' '}
              The Company collects Your Personal Information to enable Us,
              amongst other things, to provide the Sites to You. We take all
              steps reasonably necessary to protect against the unauthorised
              access, use, alteration, disclosure or destruction of Personal
              Information.
            </p>
            <p className="privacy-pg__title">Information Storage & Sharing:</p>
            <p className="privacy-pg__info">
              Any Personal Information that We collect and store is protected
              using appropriate means and will be collected, received,
              possessed, used, processed, recorded, stored, transferred, dealt,
              handled, retained and disclosed in compliance with the local
              laws/regulations in India including IT Act, SPDI Rules, and
              Intermediary Rules, and although the Company does so, it will not
              be held responsible for any unauthorised access by third parties.
            </p>
            <p className="privacy-pg__info">
              All Information shall be stored by us or one or more of our
              vendors, who will be bound by a non-disclosure agreement with us.
              We reserve the rights to hold your Information indefinitely.{' '}
            </p>
            <p className="privacy-pg__info">
              You agree that all Personal Information shall be retained till
              such time required for the Purpose or required under applicable
              law, whichever is later. Non-Personal Information will be retained
              indefinitely. 
            </p>
            <p className="privacy-pg__info">
              The Company will observe the following guidelines when collecting,
              receiving, possessing, using, processing, recording, storing,
              transferring, dealing, handling, and disclosing:
            </p>
            <ol type="a" className="privacy-pg__list">
              <p className="privacy-pg__info">
                {' '}
                <li>
                  Personal Information will be relevant/necessary to/for the
                  purposes for which it is collected and used;
                </li>
              </p>
              <p className="privacy-pg__info">
                {' '}
                <li>
                  Personal Information will be current and accurate with
                  reasonable steps taken by the Company to rectify or delete
                  inaccurate Personal Information;
                </li>
              </p>
              <p className="privacy-pg__info">
                {' '}
                <li>
                  Personal Information will be kept only as long as necessary
                  for the purposes for which it was collected and processed, and
                  as legally required under the Intermediary Rules and other
                  applicable data privacy and/or labour laws, as the case may
                  be; and
                </li>
              </p>
              <p className="privacy-pg__info">
                {' '}
                <li>
                  Reasonable measures will be taken to prevent unauthorized
                  access or use, unlawful processing, and unauthorized or
                  accidental loss, destruction, or damage to such Personal
                  Information.
                </li>
              </p>
            </ol>
            <p className="privacy-pg__info">
              We may share or disclose the Information collected and received
              under this Privacy Policy:
            </p>
            <ol type="1" className="privacy-pg__list">
              <p className="privacy-pg__info">
                {' '}
                <li>
                  With one or more of our corporate entities and affiliates
                  globally to help detect and prevent identity theft, fraud and
                  other potentially illegal acts; correlate related or multiple
                  accounts to prevent abuse of our services; and to facilitate
                  joint or co-branded services as part of service offered to you
                  through Website. 
                </li>
              </p>
              <p className="privacy-pg__info">
                {' '}
                <li>
                  As required by law, such as to comply with a subpoena, or
                  similar legal process, etc.;
                </li>
              </p>
              <p className="privacy-pg__info">
                {' '}
                <li>
                  When we believe in good faith that disclosure is necessary to
                  protect our rights and interests, protect your safety or the
                  safety of others, investigate fraud, or respond to a
                  government request and the like;
                </li>
              </p>
              <p className="privacy-pg__info">
                {' '}
                <li>
                  With our trusted services providers who work on our behalf, do
                  not have an independent use of the Information we disclose to
                  them, and have agreed to adhere to the rules set forth in this
                  Privacy Policy.
                </li>
              </p>
              <p className="privacy-pg__info">
                {' '}
                <li>
                  If any of the entities or affiliates globally or group company
                  is involved in a merger, acquisition, or sale of all or a
                  portion of its assets, the business entity that consequently
                  acquires such Information will receive and store the same as
                  per this Privacy Policy.
                </li>
              </p>
            </ol>
            <p className="privacy-pg__info">
              {' '}
              Apart from the forgoing’s, we may use third party service
              providers to help us operate our business, the Participating
              Website, or administer activities on our behalf, such as sending
              out newsletters or surveys and the like. We will only share your
              information with these third parties for those limited purposes.
            </p>
            <p className="privacy-pg__info">
              We may also share your Information with third parties under a
              confidentiality agreement which inter alia provides for that such
              third parties not disclosing the Information further unless such
              disclosure is for the purpose. However, RBAL is not responsible
              for any breach of security or for any actions of any third parties
              that receive Your Personal Information. RBAL is not liable for any
              loss or injury caused to You as a result of You providing Your
              Personal Information to third party (including any third party
              websites, even if links to such third party websites are provided
              on the respective Sites).{' '}
            </p>
            <p className="privacy-pg__info">
              You may receive promotional offers, campaign details, company
              news, updates, related product or service Information, etc by
              email among other means. If you have opted to receive promotional
              offers there will be unsubscribe options with all such category of
              offers and you may choose to unsubscribe from such offers at any
              time by following the instructions included at the bottom of each
              email, or by contacting us via our Website or Mobile App.{' '}
            </p>
            <p className="privacy-pg__info">
              Selectively, you may also receive various offers via your mobile
              number or postal mail. We also contact you via email for
              administrative purposes, such as change notifications or if we
              update our Privacy Policy. While you can elect to stop receiving
              email for promotional offers (as described above), you cannot
              unsubscribe from these administrative messages while enrolled on
              the Website and/ or the Mobile App.{' '}
            </p>
            <p className="privacy-pg__info">
              {' '}
              Our customer care department may also contact you in response to
              an inquiry you submit. Customer care may also contact you with
              regard to a particular problem that may have occurred with your
              account or with an offer in which you may have participated. We
              may use this Information to learn more about your participation in
              the Website and /or the Mobile App and make it more relevant to
              your interests.
            </p>
            <p className="privacy-pg__title">Information access by member:</p>
            <p className="privacy-pg__info">
              We respect Your privacy and are committed to ensuring the
              confidentiality of Your Information with us. We may contact You
              with useful Information on products and services through Our
              telemarketing / tele calling activities, which may be beneficial
              for you. However, if You do not wish to be contacted through Our
              telemarketing calls or messages, We request You to kindly register
              at Our call centre and give details of the telephone on which You
              do not wish to be contacted. However, We reserve the right to
              contact You through tele calling for services related to Your
              account. Further, We are committed to follow the laws laid out
              with regards to tele calling and telemarketing and, follow the
              guidelines set by any regulatory authority like TRAI in cases of
              Do Not Disturb (“DND”) policy or any such similar rules which may
              be in force. Once You have registered for any such DND policy,
              Your telephone number(s) will be removed from all Our
              telemarketing calling lists within 30 working days. We will ensure
              that You do not get any further telemarketing calls on the
              telephone number(s) that You have registered.
            </p>
            <p className="privacy-pg__title">
              Voice Call Recordings at Contact Centre:
            </p>
            <p className="privacy-pg__info">
              With a view to providing the best possible service to its
              customers, we will be:
            </p>
            <ol type="1" className="privacy-pg__list">
              <p className="privacy-pg__info">
                <li>
                  Recording voice calls made to our customer care with a view to
                  better provide our services
                </li>{' '}
              </p>
              <p className="privacy-pg__info">
                {' '}
                <li>Monitoring the voice calls recorded; and </li>
              </p>
              <p className="privacy-pg__info">
                {' '}
                <li>
                  Overseeing the services furnished by the customer call
                  centres. 
                </li>
              </p>
              <p className="privacy-pg__info">
                {' '}
                <li>
                  Sharing with one or more our affiliate companies, all or any
                  particular call recordings received from those customers who
                  have been enrolled with us on the Website and/or a report with
                  details of the complaints, if any, received by you or the
                  customer. The sharing of voice recordings received from the
                  call centres with our affiliate companies will be done in due
                  compliance of applicable privacy laws.
                </li>
              </p>
            </ol>
            <p className="privacy-pg__info">
              All of the aforesaid activities are done, inter alia, to:{' '}
            </p>
            <ol type="1" className="privacy-pg__list">
              <p className="privacy-pg__info">
                {' '}
                <li>Improve customer satisfaction</li>
              </p>
              <p className="privacy-pg__info">
                {' '}
                <li>Assure quality management and customer experience</li>
              </p>
              <p className="privacy-pg__info">
                {' '}
                <li>
                  Identify and analyze the patterns and the types of
                  requests/complaints raised by customers
                </li>
              </p>
              <p className="privacy-pg__info">
                {' '}
                <li>
                  Initiate remedial measures to prevent the re-occurrence of
                  such complaints/issues
                </li>
              </p>
              <p className="privacy-pg__info">
                {' '}
                <li>Retain a recording of the voice calls; and</li>
              </p>
              <p className="privacy-pg__info">
                {' '}
                <li>
                  Support any transactions that take place over the phone and/or
                  if there is a dispute
                </li>
              </p>
            </ol>
            <p className="privacy-pg__title">Links to Other Sites:</p>
            <p className="privacy-pg__info">
              The Sites or any activities made on the Sites may contain features
              which link to other websites that may collect Information about
              you. In addition, such other websites may contain certain product
              and service offerings which are carried out independently by such
              third party websites. We are neither responsible for the working
              of such third party websites nor do We warrant or nor do We make
              any assurances of the existence, adequacy or functioning of
              security practices of any such third party websites.
            </p>
            <p className="privacy-pg__title">Cookies: </p>
            <p className="privacy-pg__info">
              We may use cookies or other technologies on the Website. Cookies
              are small files or records that we may place on your web browser
              for collecting Information about your activities on the Website,
              preparing reports, assessing trends, and otherwise monitoring the
              way in which the Website is being used. This is intended to
              enhance convenience for you, and to assist us to run an efficient
              operation. By accepting these Terms, you confirm your consent to
              our use of cookies, and other such technologies, for these
              purposes.
            </p>
            <p className="privacy-pg__title">
              Treatment of Personal Information:
            </p>
            <p className="privacy-pg__info">
              We take all steps reasonably necessary to protect against the
              unauthorised access, use, alteration, disclosure or destruction of
              Personal Information. If RBAL is sold to a third party, or RBAL
              otherwise becomes subject to a merger, consolidation or
              acquisition, it would become necessary for us to transfer your
              Personal Information to a third party, and in such case RBAL would
              require that the third party handles your Personal Information
              with no less care than taken by us.{' '}
            </p>
            <p className="privacy-pg__info">
              The email address you provide will only be used to send you
              information and updates related to the products; and to respond to
              your enquiries and requests. If you decide to opt-in to our
              mailing list, you&apos;ll receive emails that may include company
              news, updates, related product or service information, etc. You
              can unsubscribe from our email list at any time by following the
              instructions included at the bottom of each email, or by
              contacting us via Website or Participating Website.{' '}
            </p>
            <p className="privacy-pg__info">
              We maintain physical, electronic and procedural safeguards to
              protect the Information. Specific employees only are authorized to
              access the Information on a need to know basis for business
              purposes only.{' '}
            </p>
            <p className="privacy-pg__info">
              {' '}
              To keep your Personal Information safe, we ensure that sensitive
              and private data exchanges between you and RBAL happen over a
              Secure Sockets Layer (SSL)-secured communication channel, and are
              encrypted and protected with digital signatures. SSL-secured
              communication channels are specially designed to protect personal
              and sensitive Information (payment details), which is sent over
              the internet. Provided that, your right to claim damages shall be
              limited to the right to claim only statutory damages under
              Information Technology Act, 2000 and You hereby waive and release
              all RBAL entities, its group companies from any claim of damages.
            </p>
            <p className="privacy-pg__info">
              If you choose a payment gateway to complete any transaction on
              Website and/or the Mobile App then Your credit card data may be
              stored in compliance with industry standards/ recommended data
              security standard for security of financial Information such as
              the Payment Card Industry Data Security Standard (PCI-DSS).
            </p>
            <p className="privacy-pg__title">
              Treatment of General Information: 
            </p>
            <p className="privacy-pg__info">
              The Company also collects Your General Information to allow us,
              amongst other things, to better understand Our users and improve
              the layout and functionality of the Website. BKIL may use and
              aggregate anonymized Information derived from Your Personal
              Information, to inform publishers, partners, trusted affiliates,
              advertisers, and other interested parties as to the usage habits
              or characteristics of the Company &#39; customer base, and to
              serve any other business purposes.
            </p>
            <p className="privacy-pg__title">Opt out rights:</p>
            <p className="privacy-pg__info">
              In the event, you do not wish that your Information to be used for
              the purposes mentioned herein or you wish to withdraw consent for
              use of your Information please e-mail us
              at customercare@burgerking.in In such events, we may not be in a
              position to provide you any benefits or Services. Also, in case
              you choose to not share your name and/or mobile number, your
              registration from the Website will be terminated.
            </p>
            <p className="privacy-pg__title">Anti-Spam Policy: </p>
            <p className="privacy-pg__info">
              Since email is an integral part for the Service being rendered,
              your sharing of email contact with us allows us to share the
              offers and promotions relating to the Service or Website via email
              with you. If you later decide not to receive any offers and or
              promotions relating to the Service or the respective Sites, you
              may unsubscribe by communicating to us. Please note that opting
              not to receive email does not exempt you from receiving
              administrative emails.
            </p>
            <p className="privacy-pg__title">
              Data Accuracy, Verification and Supplementation: 
            </p>
            <p className="privacy-pg__info">
              It is in your interest and it is our objective, for us to have
              accurate, current and complete Information concerning you and your
              account. We have strict procedures that our employees abide by to
              meet this objective. While some procedures are required by
              central, state or RBI regulations, we have implemented additional
              procedures to maintain accurate, correct and complete financial
              Information, including processes to update Information and remove
              outdated Information. If you believe that we have incorrect
              Information about you or your accounts, please intimate us. We
              will correct any erroneous Information as soon as possible. Data
              verification involves the use of third-party data processing
              services to standardize and clean your provided Information so
              that it is easily identified and accessed in our database.{' '}
            </p>
            <p className="privacy-pg__info">
              <b>Guarding your own Information:</b> We recommend that you take
              the following precautions to guard against the disclosure and
              unauthorized use of your Information:
            </p>
            <ol type="1" className="privacy-pg__list">
              <p className="privacy-pg__info">
                {' '}
                <li>
                  Never provide Information that can be used to access your
                  account over the phone unless you have initiated the call and
                  know with whom you are speaking;
                </li>
              </p>
              <p className="privacy-pg__info">
                {' '}
                <li>
                  Update any changes in addresses or contact Information
                  immediately through the proper channel;
                </li>
              </p>
            </ol>
            <p className="privacy-pg__title">Force Majeure:</p>
            <p className="privacy-pg__info">
              Notwithstanding anything contained in this Policy or terms of use
              or other terms and condition on the respective Sites or elsewhere,
              RBAL shall not be held responsible for any loss, damage or misuse
              of Your Personal Information, if such loss, damage or misuse is
              attributable to a Force Majeure Event (as defined below).{' '}
            </p>
            <p className="privacy-pg__info">
              {' '}
              &quot;Force Majeure Event&quot; shall mean any event that is
              beyond the reasonable control of RBAL and shall include, without
              limitation, sabotage, fire, flood, explosion, acts of God, civil
              commotion, strikes or industrial action of any kind, riots,
              insurrection, war, acts of government, computer hacking,
              unauthorised access to computer, computer system or computer
              network, computer crashes, breach of security and encryption
              (provided beyond reasonable control of RBAL), power or electricity
              failure or unavailability of adequate power or electricity.
            </p>
            <p className="privacy-pg__info">
              While We will endeavour to take all reasonable and appropriate
              steps to keep secure any Personal Information which We hold about
              You and prevent unauthorized access, You acknowledge that the
              internet or computer networks are not fully secure and that We
              cannot provide any absolute assurance regarding the security of
              Your Personal Information.{' '}
            </p>
            <p className="privacy-pg__title">Grievance officer:</p>
            <p className="privacy-pg__info">
              {' '}
              In accordance with IT Act and SPDI Rules, the name and contact
              details of the Grievance Officer are provided below:
            </p>
            <p className="privacy-pg__info">Ms. Madhuri Shenoy,</p>
            <p className="privacy-pg__info"> Restaurant Brands Asia Limited,</p>
            <p className="privacy-pg__info">
              {' '}
              Unit Nos.1003 to 1007,10th Floor,
            </p>
            <p className="privacy-pg__info">Mittal Commercia Asan Pada Rd,</p>
            <p className="privacy-pg__info"> Chimatpada, Marol,</p>
            <p className="privacy-pg__info">Andheri (E)</p>
            <p className="privacy-pg__info">Mumbai 400059</p>
            <p className="privacy-pg__info">Phone no: 022-71933099</p>
            <p className="privacy-pg__info">
              {' '}
              Email: customercare@burgerking.in{' '}
            </p>
            <p className="privacy-pg__info">Time: Mon – Fri (9:00 - 17:00)  </p>
            <p className="privacy-pg__info">
              {' '}
              In case you have any discrepancies and grievances in connection
              with this Policy or Terms of Use or any other terms and conditions
              mentioned on the Website, you may please contact the Grievance
              Officer mentioned above.
            </p>
            <p className="privacy-pg__info">
              If you have any other questions or clarifications regarding
              privacy, or have questions about practices, please contact us via
              email at customercare@burgerking.in{' '}
            </p>
          </div>
        </div>
        {pathname === '/privacy-policy' ? <Footer /> : ''}
      </div>
    );
  }
}

export default withRouter(PrivacyPolicy);

import './index.scss';

import React, { Component } from 'react';
import LoginOtp from '../../components/login-otp';

class OtpProfile extends Component{
  constructor(props){
    super(props);
    this.state={
      mobileNumber: '9930544770'
    }
    this.verifyOtpFor = 'mobile'
  }

  onVerifyOtpClick = () => {
    let { verifyOtpFor } = this;
    const { onVerifyOtpClick } = this.props;
    
    if(verifyOtpFor === 'mobile'){
      verifyOtpFor = 'email';
      this.setState({ mobileNumber: 'dsada@dasd.com '})
    }else{
      onVerifyOtpClick();
    }
  }

  render(){
    const { mobileNumber } = this.state;
    const { onVerifyOtpClick } = this;
    return(
      <div>
        <LoginOtp mobileNumber={mobileNumber} onVerifyOtpClick={onVerifyOtpClick} />
      </div>
    )
  }
}

export default OtpProfile;
/* eslint-disable no-undef */
import './index.scss';

import React, { Component } from 'react';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from 'react-places-autocomplete';

import SearchIcon from '../../../assets/images/search-black.svg';

import { getDetectLocationImage } from '../../utils/imageChanges';
import { Analytics } from '../../helpers/analytics';

class SearchAutoComplete extends Component {
  searchOnChangeHandler = e => {
    const { onSearchChange } = this.props;
    onSearchChange(e);
  };

  handleSelect = value => {
    const { setInputValue, setLatLng, onloadMap } = this.props;
    setInputValue({ location: value });
    this.latLng = {};
    geocodeByAddress(value)
      .then(results => getLatLng(results[0]))
      .then(latLng => {
        const { lat, lng } = latLng;
        const json = { lat, lng };
        this.latLng = json;
        setLatLng(json);
        this.getPincode(json);
        onloadMap(true, json);
      })
      .catch(error => console.error('Error', error));
  };

  getCurrentLocation = () => {
    const { setInputValue, setLatLng, onloadMap } = this.props;
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        position => {
          if (
            position &&
            position.coords &&
            position.coords.latitude &&
            position.coords.longitude
          ) {
            const json = {
              lat: position.coords.latitude,
              lng: position.coords.longitude
            };
            const { google } = window;
            const geocoder = new google.maps.Geocoder();
            geocoder.geocode(
              { latLng: json },
              (results, status) => {
                if (status === google.maps.GeocoderStatus.OK) {
                  if (
                    results &&
                    results.length &&
                    results[1] &&
                    results[1].formatted_address
                  ) {
                    let pincode = '';
                    let city = '';
                    results[1].address_components.forEach(data => {
                      if (data && data.types) {
                        const filter = data.types.filter(
                          item => item === 'postal_code'
                        );
                        const filters = data.types.filter(
                          item => item === 'locality'
                        );
                        if (filters && filters.length) city = data.long_name;
                        if (filter && filter.length) pincode = data.long_name;
                      }
                      return data;
                    });
                    // eslint-disable-next-line no-lone-blocks

                    setInputValue({
                      location: results[1].formatted_address,
                      pincode,
                      latitude: json.lat,
                      longitude: json.lng,
                      city
                    });
                    const fbscript = document.createElement('script');
                    fbscript.src = '//connect.facebook.net/en_US/fbevents.js';
                    fbscript.onload = () => {
                      fbq('track', 'FindLocation', {
                        af_location: results[1].formatted_address,
                        af_lat: json.lat,
                        af_long: json.lng,
                        af_city: city
                      });
                    };
                    document.head.appendChild(fbscript);
                    Analytics.logEventWithVariable(
                      Analytics.Order.location,
                      JSON.stringify({
                        af_location: results[1].formatted_address,
                        af_lat: json.lat,
                        af_long: json.lng,
                        af_city: city
                      })
                    );
                  }
                }
              },
              this
            );
            setLatLng(json);
            onloadMap(true, json);
          }
          // eslint-disable-next-line no-unused-vars
        },
        error => {
          alert('Unable to fetch the location. Please enter manually');
        }
      );
    } else {
      alert('Unable to fetch the location. Please enter manually');
    }
  };

  getPincode = latLng => {
    const { setInputValue } = this.props;
    const { google } = window;
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode(
      { latLng },
      (results, status) => {
        if (status === google.maps.GeocoderStatus.OK) {
          if (
            results &&
            results.length &&
            results[1] &&
            results[1].formatted_address
          ) {
            let pincode = '';
            let city = '';
            results[1].address_components.forEach(data => {
              if (data && data.types) {
                const filter = data.types.filter(
                  item => item === 'postal_code'
                );
                const filters = data.types.filter(item => item === 'locality');
                if (filters && filters.length) city = data.long_name;
                if (filter && filter.length) pincode = data.long_name;
              }
              return data;
            });
            setInputValue({
              location: results[1].formatted_address,
              pincode,
              latLng,
              city
            });
          }
        }
      },
      this
    );
  };

  getRightContent = () => {
    const { inputValue, isMobileView, onClearClick } = this.props;
    const { theme } = this.props;

    if (inputValue && inputValue.location) {
      return (
        <div
          className={`search-autocomplete__clear ${theme} u_cursor_pointer`}
          role="presentation"
          onClick={onClearClick}
        >
          Clear
        </div>
      );
    }
    if (!isMobileView && !(inputValue && inputValue.location)) {
      return (
        <div
          className="search-autocomplete__location u_cursor_pointer"
          role="presentation"
          onClick={() => this.getCurrentLocation()}
        >
          <img
            src={getDetectLocationImage(theme)}
            alt="detect location"
            className="search-autocomplete__location-img"
          />
          <p className={`search-autocomplete__location-name ${theme}`}>
            Detect location
          </p>
        </div>
      );
    }
    return '';
  };

  render() {
    const { inputValue, customClass, store } = this.props;
    return (
      <div
        className={
          store
            ? 'search-autocomplete__content-store'
            : 'search-autocomplete__content'
        }
      >
        <div className="search-autocomplete__search">
          <PlacesAutocomplete
            value={inputValue && inputValue.location}
            onChange={this.searchOnChangeHandler}
            onSelect={this.handleSelect}
          >
            {({
              getInputProps,
              suggestions,
              getSuggestionItemProps,
              loading
            }) => (
              <div>
                <div className="search-autocomplete__search-left">
                  {!(inputValue && inputValue.location) ? (
                    <img
                      src={SearchIcon}
                      alt="search"
                      className="search-autocomplete__search-img"
                    />
                  ) : (
                    ''
                  )}
                  <input
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...getInputProps({
                      placeholder: 'Search for your location',
                      className:
                        'search-autocomplete__search-input location-search-input'
                    })}
                  />
                  {this.getRightContent()}
                  {/* {
                  inputValue ? <div className="onboard__clear u_cursor_pointer" role="presentation" onClick={() => this.clearSearchInput()}>Clear</div> :''
                } */}
                </div>
                {suggestions && suggestions.length ? (
                  <div className="autocomplete-dropdown-container_tri" />
                ) : (
                  ''
                )}
                {/* {suggestions ? this.setSuggestion(suggestions) : ''} */}
                <div className="autocomplete-dropdown-container">
                  {/* {loading && <div>Loading...</div>} */}
                  <div className="autocomplete-dropdown-container__sug">
                    {suggestions.map(suggestion => {
                      const className = suggestion.active
                        ? 'suggestion-item suggestion-item--active'
                        : 'suggestion-item';

                      return (
                        <div
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...getSuggestionItemProps(suggestion, {
                            className
                          })}
                        >
                          <div>
                            <div className="suggestion-item__title">
                              {suggestion.formattedSuggestion &&
                                suggestion.formattedSuggestion.mainText}
                            </div>
                            {suggestion.formattedSuggestion &&
                              suggestion.formattedSuggestion.secondaryText}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            )}
          </PlacesAutocomplete>
        </div>
      </div>
    );
  }
}

export default SearchAutoComplete;

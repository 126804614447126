// Imports: Dependencies
import { combineReducers } from 'redux';
// Imports: Reducers
import HomeReducer from './home/reducer';
import LoginReducer from './login/reducer';
import ProductListingReducer from './productListing/reducer';
import redisCartReducer from './redisCart/reducer';
import CartReducer from './cart/reducer';
import AddressReducer from './address/reducer';
import OrderHistory from './orders/reducer';
import OffersReducer from './offers/reducer';
// Redux: Root Reducer
const rootReducer = combineReducers({
  home: HomeReducer,
  login: LoginReducer,
  productListing: ProductListingReducer,
  redisCart: redisCartReducer,
  address: AddressReducer,
  orderHistory: OrderHistory,
  offers: OffersReducer,
  cart: CartReducer
});
// Exports
export default rootReducer;

import './index.scss';

import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { getRightArrowImage } from '../../utils/imageChanges';

const BreadCrumb = props => {
  const { data, theme } = props;
  return (
    <ul className="breadcrumb u_list_style_none">
      {data.map((item, index) => {
        const { name, route } = item;
        return (
          <li key={index}>
            {
              data.length - 1 === index ?
                <div className="breadcrumb__list_active u_cursor_not_allowed">{name}</div>
                :
                <Link
                  className="breadcrumb__list"
                  to={route}
                >
                  {name}
                  {data.length - 1 > index ? (
                    <img
                      src={getRightArrowImage(theme)}
                      alt="right arrow"
                      className="breadcrumb__list-arrow"
                    />
                  ) : (
                    ''
                  )}
                </Link>
            }
           
          </li>
        );
      })}
    </ul>
  );
};

BreadCrumb.propTypes = {
  themeType: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      route: PropTypes.string
    })
  )
};

BreadCrumb.defaultProps = {
  themeType: '',
  data: []
};

export default BreadCrumb;

import './index.scss';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { NameValidation, EmailValidation } from '../../utils/validation';

class InputLabelFloat extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputFocus: ''
    };
    this.inputRef = React.createRef();
  }

  static getDerivedStateFromProps(nextProps) {
    const { id, currentFieldFocus, value, placeholder } = nextProps;
    if (id === currentFieldFocus) {
      return { inputFocus: 'active' };
    }
    if ((value || placeholder) && currentFieldFocus !== id) {
      return { inputFocus: 'hasvalue' };
    }
    if (!value) {
      return { inputFocus: '' };
    }
    return null;
  }

  onFocus = () => {
    this.inputRef.current.focus();
    this.setState({ inputFocus: 'active' });
    const { onFocus, id } = this.props;
    if (onFocus) onFocus(id);
  };

  onBlur = () => {
    if (this.inputRef.current.value) this.setState({ inputFocus: 'hasvalue' });
    else if (!this.inputRef.current.value) {
      this.setState({ inputFocus: '' });
    }
  };

  onChange = e => {
    const { onChange, isName, validationChange } = this.props;
    const { target } = e;
    const { id, value } = target;
    let validation = true;
    if (isName && value) validation = NameValidation(value);

    if (validation) {
      onChange(id, value, e);
      if (validationChange) {
        const validationType = this.validate(e.target.value);
        validationChange(e.target.id, validationType);
      }
    }
  };

  validate = value => {
    const { type } = this.props;
    let validation;
    switch (type) {
      case 'email':
        validation = EmailValidation(value);
        break;
      case 'text':
        validation = NameValidation(value);
        break;
      default:
        validation = false;
    }
    return validation;
  }

  render() {
    const { inputFocus } = this.state;
    const { id, type, label, validation, value, disabled, customClass, theme, readOnly, placeholder,max } = this.props;
    const validationClassName = validation && 'error';
    return (
      <div className="inputfloatlabel_container">
        <div className={`inputfloatlabel ${inputFocus} ${validationClassName} ${customClass} ${theme} ${readOnly}`}>
          <label htmlFor={id}>{label}</label>
          <input
            disabled={disabled}
            value={value}
            id={id}
            type={type}
            onFocus={this.onFocus}
            onBlur={this.onBlur}
            ref={this.inputRef}
            onChange={this.onChange}
            readOnly={readOnly}
            placeholder={placeholder}
            maxLength={max}
          />
        </div>
      </div>
    );
  }
}

InputLabelFloat.propTypes = {
  id: PropTypes.string,
  type: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  onFocus: PropTypes.func,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  customClass: PropTypes.string,
  maxLength:PropTypes.string
};

InputLabelFloat.defaultProps = {
  id: '',
  type: '',
  label: '',
  value: '',
  disabled: false,
  onFocus: () => { },
  onChange: () => { },
  customClass: '',
  maxLength:''
};

export default InputLabelFloat;

import './index.scss';

import React from 'react';

import { getLeftArrowImage, getSearchImage } from '../../utils/imageChanges';

const MobileProductListingHeader = props => {
  const { searchClick, goBackClick, headerTitle, theme } = props;
  return (
    <div className="header-title">
      <div className="header-title__left">
        <div
          className="header-title__arrow"
          role="presentation"
          onClick={() => goBackClick()}
        >
          <img src={getLeftArrowImage(theme)} alt="left arrow" />
        </div>
        <div className="header-title__name">{headerTitle}</div>
      </div>
      <div
        className="header-title__search"
        role="presentation"
        onClick={() => searchClick()}
      >
        <img src={getSearchImage(theme)} alt="search" />
      </div>
    </div>
  );
};

export default MobileProductListingHeader;

import './index.scss';

import React, { Component } from 'react';
import { withRouter } from 'react-router';

import AccountSubSecTitle from '../../components/accout-subsec-title';
import NotificationSettingsCard from '../../components/notifications-settings-card';
import Cancelpop from '../../components/cancel-popover';
import { LocalSessionKey } from '../../burger-king-lib/constants/app-constants';
import { LocalStorage } from '../../burger-king-lib/utils/storage.web';

class NotificationSettings extends Component {
  constructor() {
    super();
    this.state = {
      offerApply: false
    };
    this.toggleData = [
      {
        title: '  Important Message Alerts'
      },
      {
        title: 'Order Tracking'
      },
      {
        title: '  In-app Notifications',
        description: ' Recommendations and Offers For You'
      },
      {
        title: ' Push Notifications'
      },
      {
        title: 'Exclusive Offers',
        description: '  Happy Hours, Birthday, Limited Time Discounts'
      },
      {
        title: ' BK Wall'
      },
      {
        title: '  Cart and Payments'
      }
    ];
  }

  componentDidMount() {
    const { location } = this.props;
    if (location?.state?.length > 0) {
      const isLoggedIn = LocalStorage.get(LocalSessionKey.USER_DATA) || '';
      if (!Boolean(isLoggedIn)) {
        this.setState({
          offerApply: true
        });
      }
    }
  }
  getOfferData = () => {
    setTimeout(() => {
      this.setState({ offerApply: false });
      this.props.history.push('/');
    }, 3000);
    return (
      <>
        <div className="offerlisting_offer">
          <p className="offerlisting_offer--text">Alert</p>
          {/* <h3 className="offerlisting_offer--heading">
          Kindly Login to continue
        </h3> */}
          <p className="offerlisting_offer--text">Kindly Login to continue.</p>
          <p className="offerlisting_offer--notify"> Login and Re-try</p>
        </div>
      </>
    );
  };

  goBackOnClick = () => {
    const { history } = this.props;
    history.go(-1);
  };

  render() {
    const theme = window.sessionStorage.getItem('theme');
    const { goBackOnClick, toggleData } = this;
    const { offerApply } = this.state;
    return (
      <>
        {offerApply && (
          <Cancelpop
            content
            children={this.getOfferData()}
            theme={theme || 'delivery'}
          />
        )}

        <div className="notification-settings_wrapper">
          <AccountSubSecTitle
            title="Notification Settings"
            goBackOnClick={goBackOnClick}
            theme={theme}
          />
          <div className="notification-settings">
            <ul className="notification-settings__list">
              {toggleData.map(item => {
                return (
                  <NotificationSettingsCard theme={theme} toggleData={item} />
                );
              })}
            </ul>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(NotificationSettings);

import './index.scss';

import React from 'react';
import PropTypes from 'prop-types';


const TitleDescription = props => {
  const { title,customClass,description, theme } = props;
  return (
    <div className={`title-description ${theme} ${customClass}`}>
      <h2 className="title-description__title">{title}</h2>
      <h4 className="title-description__description">{description}</h4>
    </div>
  );
};

export default TitleDescription;

TitleDescription.propTypes = {
  title: PropTypes.string,
  description:PropTypes.string,
  theme: PropTypes.string,
};

TitleDescription.defaultProps = {
  title: '',
  description:'',
  theme: ''
};

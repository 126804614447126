import React from 'react';
import PropTypes from 'prop-types';
import './index.scss';

const Button = props => {
  const {
    className,
    content,
    type,
    outlined,
    size,
    theme,
    onClick,
    image,
    disabled
  } = props;

  return (
    <button
      type="button"
      disabled={disabled}
      className={`button ${theme} ${type} ${outlined &&
        'outlined'} ${size} ${className} `}
      onClick={onClick ? () => onClick() : ''}
    >
      <img src={image} alt={image} />
      <span className="button__content"> {content}</span>
    </button>
  );
};

Button.propTypes = {
  className: PropTypes.string,
  content: PropTypes.string,
  outlined: PropTypes.bool,
  size: PropTypes.string,
  type: PropTypes.string,
  theme: PropTypes.string
};

Button.defaultProps = {
  className: '',
  content: null,
  outlined: false,
  size: '',
  type: '',
  theme: ''
};
export default Button;

import './index.scss';

import React from 'react';
import PropTypes from 'prop-types';

const Upload = ({ children, accept, onChange, id }) => {
  return (
    <div className="choose_file">
      {children}
      <input
        id={id}
        name="img"
        type="file"
        accept={accept}
        onChange={onChange}
      />
    </div>
  );
};

Upload.propTypes = {
  accept: PropTypes.string
};

Upload.defaultProps = {
  accept: ''
};

export default Upload;

import './index.scss';

import React from 'react';
import PropTypes from 'prop-types';

import { getRightArrowImage, getUserEditImage,getUserTagImage } from '../../utils/imageChanges';

import UserImage from '../../../assets/images/user-name-tag-empty.svg';
import CrownImage from '../../../assets/images/offers/crown.png';

const AccountSideMenuProfile = props => {
  const { data, theme, onClick } = props;
  const { name, phone_no, profile_pic, level } = data;

  return (
    <div
      className="act-profile u_cursor_pointer"
      role="presentation"
      onClick={() => onClick()}
    >
      <div className="act-profile__user">
        {profile_pic ? (
          <>
            <img
              src={CrownImage}
              alt="profile"
              className="act-profile__crown_profile"
            />
            <img
              src={profile_pic}
              alt="profile"
              className="act-profile__user-image"
            />
            <div className="act-profile__user-details">
              <img
                src={getUserTagImage(theme)}
                alt="name"
                className="act-profile__user-name-image"
              />
              {/* <p className="act-profile__user-name">{level}</p> */}
            </div>
          </>
        ) : (
          <>
            <img
              src={CrownImage}
              alt="profile"
              className="act-profile__crown"
            />
            <img
              src={getUserEditImage(theme)}
              alt="profile"
              className="act-profile__image"
            />
            <div className="act-profile__user-details">
              <img
                src={getUserTagImage(theme)}
                alt="name"
                className="act-profile__user-name-image"
              />
              {/* <p className="act-profile__user-name">{level}</p> */}
            </div>
          </>
        )}
      </div>
      <div className="act-profile__details">
        <div className="act-profile__name">
          {name}
          <span className="act-profile__arrow">
            <img src={getRightArrowImage(theme)} alt="right" />
          </span>
        </div>
        <div className="act-profile__number">{phone_no}</div>
      </div>
    </div>
  );
};

AccountSideMenuProfile.propTypes = {
  data: PropTypes.shape({})
};

AccountSideMenuProfile.defaultProps = {
  data: {}
};

export default AccountSideMenuProfile;

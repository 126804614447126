import {
  // LocalStorage,
  SessionStorage
} from '../burger-king-lib/utils/storage.web';
import { LocalSessionKey } from '../burger-king-lib/constants/app-constants';

export function getOffersPayload(type, offer_type) {
  return {
    outlet_id:
      SessionStorage.get(LocalSessionKey.OUTLET_ID) &&
      SessionStorage.get(LocalSessionKey.OUTLET_ID).toString(),
    type,
    offer_type
  };
}

export function getTopDealsPayload() {
  return {
    outlet_id:
      SessionStorage.get(LocalSessionKey.OUTLET_ID) &&
      SessionStorage.get(LocalSessionKey.OUTLET_ID).toString()
  };
}

export function getLimitedDealsPayload(offer_type) {
  return {
    outlet_id:
      SessionStorage.get(LocalSessionKey.OUTLET_ID) &&
      SessionStorage.get(LocalSessionKey.OUTLET_ID).toString(),
    offer_type
  };
}

export function getCouponsPayload(data) {
  const { offer_id, offer_type, source, crowns_to_burn } = data;
  return {
    offer_id,
    points_to_burn: crowns_to_burn,
    outlet_id:
      SessionStorage.get(LocalSessionKey.OUTLET_ID) &&
      SessionStorage.get(LocalSessionKey.OUTLET_ID).toString(),
    offer_type,
    source
  };
}

export function getUserAvailablePointsPayload(phone_no) {
  return {
    phone_no
  };
}

export function getUserCouponsPayload(type) {
  return {
    outlet_id:
      SessionStorage.get(LocalSessionKey.OUTLET_ID) &&
      SessionStorage.get(LocalSessionKey.OUTLET_ID).toString(),
    type
  };
}

export function getCrownHistoryPayload(phone_no, start_date, end_date) {
  return {
    phone_no,
    start_date,
    end_date
  };
}

import './index.scss';
import React from 'react';

const RefundProcess = () => {
  const Dom = (
    <div className="payment-refund_wrap">
      <div className="payment-refund">
        <table className="payment-refund__content">
          <tr className="payment-refund__content-title">
            <th className="payment-refund__content-th">Process</th>
            <th className="payment-refund__content-th">Payment Method</th>
            <th className="payment-refund__content-th">Refund Source</th>
            <th className="payment-refund__content-th">TAT</th>
          </tr>
          <tr className="payment-refund__content-value">
            <td rowSpan={10} colSpan={1} className="payment-refund__content-td">
              Cancellation/Compensation/Payment Failure
            </td>
            <td className="payment-refund__content-th">Net Banking</td>
            <td className="payment-refund__content-th">Source</td>
            <td className="payment-refund__content-th">5-7 Business Days</td>
          </tr>
          <tr className="payment-refund__content-value">
            <td className="payment-refund__content-th">Debit/Credit Cards</td>
            <td className="payment-refund__content-th">Source</td>
            <td className="payment-refund__content-th">5-7 Business Days</td>
          </tr>
          <tr className="payment-refund__content-value">
            <td className="payment-refund__content-th">UPI</td>
            <td className="payment-refund__content-th">Source</td>
            <td className="payment-refund__content-th">5-7 Business Days</td>
          </tr>
          <tr className="payment-refund__content-value">
            <td className="payment-refund__content-th">
              Amazon Pay (CC/DC/NB)
            </td>
            <td className="payment-refund__content-th">Source</td>
            <td className="payment-refund__content-th">5-7 Business Days</td>
          </tr>
          <tr className="payment-refund__content-value">
            <td className="payment-refund__content-th">Phone Pe (CC/DC/NB)</td>
            <td className="payment-refund__content-th">Source</td>
            <td className="payment-refund__content-th">5-7 Business Days</td>
          </tr>
          <tr className="payment-refund__content-value">
            {/* <td className="payment-refund__content-th">Paytm</td> */}
            <td className="payment-refund__content-th">Wallet</td>
            <td className="payment-refund__content-th">Source</td>
            <td className="payment-refund__content-th">5-7 Business Days</td>
          </tr>
          <tr className="payment-refund__content-value">
            {/* <td className="payment-refund__content-th">Paytm</td> */}
            <td className="payment-refund__content-th">Sodexo</td>
            <td className="payment-refund__content-th">Source</td>
            <td className="payment-refund__content-th">5-7 Business Days</td>
          </tr>
          <tr className="payment-refund__content-value">
            {/* <td className="payment-refund__content-th">Paytm</td> */}
            <td className="payment-refund__content-th">Pay Later</td>
            <td className="payment-refund__content-th">Source</td>
            <td className="payment-refund__content-th">5-7 Business Days</td>
          </tr>
        </table>
      </div>
    </div>
  );
  return Dom;
};

export default RefundProcess;

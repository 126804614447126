import React, { useState, useEffect } from 'react';
import './index.scss';

const Timer = ({ seconds, onShow, cancelorder }) => {
  const [timeLeft, setTimeLeft] = useState(seconds);

  useEffect(() => {
    if (!timeLeft) {
      onShowData(true, timeLeft);
      return;
    }

    const intervalId = setInterval(() => {
      const time = timeLeft - 1;
      setTimeLeft(time);
      onShowData(false, time);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timeLeft]);

  const onShowData = (data, timeLeft) => {
    onShow(data, timeLeft);
  };

  return (
    <>
      {cancelorder ? (
        <div>
          {timeLeft >= 10 ? (
            <p className="timer-cancel ">{timeLeft}s</p>
          ) : (
            <p className="timer-cancel ">0{timeLeft}s</p>
          )}
        </div>
      ) : (
        <div>
          {timeLeft >= 10 ? (
            <p className="timer">Resend OTP in 00:{timeLeft}</p>
          ) : (
            <p className="timer">Resend OTP in 00:0{timeLeft}</p>
          )}
        </div>
      )}
    </>
  );
};
export default Timer;

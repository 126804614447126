import './index.scss';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import 'react-datepicker/dist/react-datepicker.css';

import CalendarDisabedIcon from '../../../assets/images/calendar-disabled.svg';

import { getCalendarImage } from '../../utils/imageChanges';
import { NumberValidation } from '../../utils/validation';
import InputLabelFloat from '../input-label-float';

class CustomDatePicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputFocus: ''
    }
  }

  componentDidMount() {
    const { value, currentFieldFocus, id } = this.props;
    if (currentFieldFocus === id) {
      // this.datepicker.input.focus();
    } else if (value && currentFieldFocus !== id)
      this.setState({ inputFocus: 'hasvalue' })
    else if (!value)
      this.setState({ inputFocus: '' })
  }

  componentDidUpdate(prevProps) {
    const { id, currentFieldFocus, value } = this.props;
    if (currentFieldFocus && (currentFieldFocus !== prevProps.currentFieldFocus) && (id === currentFieldFocus)) {
      this.setStateValue('active');
    }
    if (value && prevProps.value !== value && currentFieldFocus !== id) {
      this.setStateValue('hasvalue');
    }
    if (value && prevProps.value !== value && !value) {
      this.setStateValue('');
    }
  }

  setStateValue = value => {
    this.setState({ inputFocus: value })
  }

  onBlur = () => {
    const { value } = this.props;
    if (value)
      this.setState({ inputFocus: 'hasvalue' })
    else if (!value) {
      this.setState({ inputFocus: '' })
    }
  }

  datepickerLabelClick = () => {
    this.setState({ inputFocus: 'active' });
    // const { onFocus, id } = this.props;
    // onFocus(id)
    if (this.datepicker && this.datepicker.input) this.datepicker.input.focus();
  }

  datepickerIconClick = () => {
    this.setState({ inputFocus: 'active' });
    if (this.datepicker && this.datepicker.input) this.datepicker.input.focus();
  }

  onChange = (id, value) => {
    const { onChange, value: propValue } = this.props;

    if(NumberValidation(value.split('/').join(''))){
      
      if (value.length > propValue.length && (value.length === 2 || value.length === 5)) {
        value += '/';
      }
      if (value.length < propValue.length && (value.length === 3 || value.length === 6)) {
        value = value.slice(0,value.length -1 );
      }
      if(value.length !== 11) onChange(id, value)
    }

    if(!value) onChange(id, value)
    
  }

  onDatepickerRef = el => { if (el && el.input) { this.datepicker = el; el.input.readOnly = true; } }

  render() {
    const { inputFocus } = this.state;
    const { value, id, theme, onFocus, currentFieldFocus } = this.props;
    const dom = (
      <div className={`datepicker ${theme}`}>
        <InputLabelFloat 
          customClass="profile__input" 
          label="Date of Birth (dd/mm/yyyy)" 
          type='text'
          id={id} 
          value={value} 
          onChange={this.onChange} 
          onBlur={this.onBlur}
          onFocus={onFocus}
          placeholder='DD/MM/YYYY' 
          currentFieldFocus={currentFieldFocus}
          theme={theme}
        />
        <img
          onClick={() => this.datepickerIconClick()}
          role="presentation"
          className="datepicker__image"
          src={currentFieldFocus === id ? getCalendarImage(theme) : CalendarDisabedIcon}
          alt='calendar' />
      </div>
    )
    return dom;
  }
}

CustomDatePicker.propTypes = {
  id: PropTypes.string,
  onFocus: PropTypes.func,
  onChange: PropTypes.func,
};

CustomDatePicker.defaultProps = {
  id: '',
  onFocus: () => { },
  onChange: () => { },
};

export default CustomDatePicker;
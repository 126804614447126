import './index.scss';

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import PlacesAutocomplete from 'react-places-autocomplete';
import * as API from '../../burger-king-lib/utils/ajax';
import { PLACE_DETAILS } from '../../burger-king-lib/constants/api-constants';
// import Input from '../input';

import { getLocationInput } from '../../utils/imageChanges';

const LocationInputEditable = props => {
  const { value, theme, customClass, onLocationChange, handleSelect } = props;

  // useEffect(() => {
  //   const response = API.get(API_CONSTANTS.PLACE_DETAILS+)
  // },[])

  return (
    <div className={`${customClass} u_cursor_pointer`} role="presentation">
      <PlacesAutocomplete
        value={value}
        onChange={onLocationChange}
        onSelect={handleSelect}
      >
        {/* <Input value={value} onChange={onLocationChange}> */}
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div className="location-input-edit">
            <div className="location-input-edit__search-input_wrapper">
              <img src={getLocationInput(theme)} alt="location" />
              <input
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...getInputProps({
                  placeholder: 'Search for your location',
                  className:
                    'location-input-edit__search-input location-search-input'
                })}
              />
            </div>
            {suggestions && suggestions.length ? (
              <div className="location-input-edit__autocomplete_tri" />
            ) : (
              ''
            )}
            <div className="location-input-edit__autocomplete">
              {/* {loading && <div>Loading...</div>} */}
              <div className="location-input-edit__autocomplete__sug">
                {loading && <div>Loading...</div>}
                {suggestions.map(suggestion => {
                  const className = suggestion.active
                    ? 'location-input-edit__suggestion-item location-input-edit__suggestion-item--active'
                    : 'location-input-edit__suggestion-item';

                  return (
                    <div
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...getSuggestionItemProps(suggestion, {
                        className
                      })}
                    >
                      <div>
                        <div className="location-input-edit__suggestion-item__title">
                          {suggestion.formattedSuggestion &&
                            suggestion.formattedSuggestion.mainText}
                        </div>
                        {suggestion.formattedSuggestion &&
                          suggestion.formattedSuggestion.secondaryText}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        )}
        {/* </Input> */}
      </PlacesAutocomplete>
    </div>
  );
};

LocationInputEditable.propTypes = {
  value: PropTypes.string,
  theme: PropTypes.string
};

LocationInputEditable.defaultProps = {
  value: '',
  theme: ''
};

export default LocationInputEditable;

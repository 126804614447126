import './index.scss';
import React from 'react';

import PropTypes from 'prop-types';

import loader from '../../../assets/images/loader.gif';

const Loader = props => {
  const { fitContent, theme } = props;
  const dom = (
    <div className={`loader_wrapper ${fitContent} ${theme}`}>
      <div className={`loader ${fitContent} ${theme}`}>
        <img src={loader} alt="load-icon" />
      </div>
    </div>
  );
  return dom;
};

Loader.propTypes = {
  fitContent: PropTypes.string
};

Loader.defaultProps = {
  fitContent: ''
};

export default Loader;

import './index.scss';

import React, { Component } from 'react';
import { withRouter } from 'react-router';
import RefundProcess from '../../components/payment-cancel-refund';
import AccountSubSecTitle from '../../components/accout-subsec-title';
import Footer from '../../containers/footer';

class TermsAndConditions extends Component {
  goBackOnClick = () => {
    const { history } = this.props;
    history.go(-1);
  };

  render() {
    const { goBackOnClick } = this;
    const { isSeparatePage, theme } = this.props;
    return (
      <div
        className={`terms-pg_wrapper ${isSeparatePage ? 'terms-pg_full' : ''}`}
      >
        {isSeparatePage ? (
          ''
        ) : (
          <AccountSubSecTitle
            title="Terms & Conditions"
            goBackOnClick={goBackOnClick}
            theme={theme}
          />
        )}
        <div className="terms-pg">
          {isSeparatePage ? (
            <AccountSubSecTitle title="Terms & Conditions" />
          ) : (
            ''
          )}
          <div className="terms-pg__content">
            <p className="terms-pg__info">
              PLEASE READ THESE TERMS AND CONDITIONS OF USE AND ACCESS
              CAREFULLY. BY ACCESSING OR USING THE WEBSITE / MOBILE APPLICATION,
              YOU AGREE TO BE BOUND BY THE TERMS AND CONDITIONS DESCRIBED HEREIN
              AND ALL TERMS AND CONDITIONS INCORPORATED BY REFERENCE. IF YOU DO
              NOT AGREE TO ALL OF THESE TERMS AND CONDITIONS, DO NOT USE THE
              WEBSITE/ MOBILE APPLICATION.
            </p>
            <p className="terms-pg__info">
              Use of the Sites by the user (“You”, “Your” “User and “Customers”)
              is subject to the terms and conditions contained herein.
            </p>
            <p className="terms-pg__info">
              By visiting our Sites and/ or purchasing something from the
              Company, You engage in our services and agree to be bound by the
              following terms and conditions (“Terms of Use”/ “Terms”),
              including those additional terms and conditions and policies
              referenced herein and/or available by hyperlink These Terms of Use
              apply to your access to, and use of, all or part of this website
              www.burgerkingindia.in and/or mobile application (collectively,
              the “Sites”) of Restaurant Brands Asia Limited (herein after known
              as “the Company”, “RBAL”, “our”, “us” and “we”). These Terms of
              Use do not alter in any way the terms or conditions of any other
              agreement you may have with the Company for products, services or
              otherwise.
            </p>
            <p className="terms-pg__info">
              These policies are published in accordance with the provisions of
              Rule 3(1) of the Information Technology (Intermediaries
              Guidelines) Rules, 2011, and are an ‘electronic record’ as
              contemplated under section 2(t) of the Information Technology Act,
              2000 and the Rules thereunder. These Terms being an electronic
              record are generated by a computer system and do not require any
              physical or digital signatures.
            </p>
            <p className="terms-pg__info">
              While we make reasonable efforts to provide accurate and timely
              information about the Company on the Sites, you should not assume
              that the information is always up to date or that the Sites
              contain all the relevant information available about the Company.
            </p>
            <p className="terms-pg__info">
              The Company reserves the right to change, alter and/or modify
              these Terms of Use or any policy or guideline of the Sites
              including the Privacy Policy and Promotional T &amp; C, at any
              time and in its sole discretion. Any changes or modifications will
              be effective immediately upon posting the revisions to the Sites,
              and you waive any right you may have to receive specific notice of
              such changes or modifications. Your continued use of the Sites
              will confirm your acceptance of such changes or modifications;
              therefore, you should frequently review these Terms of Use and
              applicable policies to understand the terms and conditions that
              apply to your use of our Sites. If you do not agree to the amended
              terms, you must stop using the Sites.
            </p>
            <p className="terms-pg__info">
              The information contained in the Sites is for general purposes
              only. It should be noted that the contents, products and services
              available are provided on an “as is” and “as available” basis. We
              make no representations or warranties of any kind for any purpose,
              express or implied, about the completeness, accuracy, reliability
              or availability with respect to the website or the information,
              products, services or related graphics contained or depicted on
              the website. Any reliance you place on such information is,
              therefore, strictly at your own risk. RBAL does not warrant that
              any information obtained from or through the Sites is free from
              virus or other faults, defects or typographical error.
            </p>
            <p className="terms-pg__info">
              RBAL shall not be accountable for any losses incurred as a result
              of any failure of service, mistake, omission, interruption,
              defect, delay in operation of transmission, computer virus, or
              line failure. There is no intent for the Sites to be used in a
              court of law.
              <br />
              RBAL&#39;s Privacy Policy and Promotional T &amp; C is an
              integral component and is hereby incorporated by reference. This
              Privacy Policy explains in detail the data we gather, how we use
              it, and with whom we share it.
            </p>
            <p className="terms-pg__title u_text_transform_uppercase ">
              Eligibility
            </p>
            {/* <p className="terms-pg__info">
              You should have completed 18 years of age and be competent to
              enter into a contract under Indian contract law. If You are under
              18 years of age who is intending to avail the services of the
              Sites should do so through Your legal guardian in accordance with
              applicable law. 
            </p> */}
            <p className="terms-pg__info">
              The Company reserves the right to refuse access to use the
              services offered at the Application to new users or to terminate
              access granted to existing users at any time without according any
              reasons for doing so.
            </p>
            <p className="terms-pg__title u_text_transform_uppercase">
              ONLINE SERVICES
            </p>
            <p className="terms-pg__info">
              Accessibility of goods and services: <br /> You are responsible
              for your devices and accounts. You are responsible for any
              devices, software and services needed to use the online services.
              RBAL does not guarantee that the online services will fully
              function on any particular device or with any particular software.
              You are also responsible for any messaging and data charges, fees
              and taxes for your use of the online services, including when we
              communicate with you by text, email or other means that you
              choose. You may only use the online services with devices that you
              own or control and using only the authorized operating system. If
              you create an online services account, you are responsible for
              keeping the account secure and for all activity under the account.
              You can only use one online services account and must keep your
              account information accurate at all times. <br />
              The online services may be updated or discontinued at any time by
              RBAL. You agree that RBAL may at any time (with or without notice)
              update, modify, or terminate the online services (or your access
              to them), including by automatically updating (or removing
              functionality or access from) any Burger King&#39;s mobile
              application you have installed on your device.
            </p>
            <p className="terms-pg__info">
              How we handle your data and how we communicate with you?
              <br />
              Privacy Policy at RBAL. These terms include the RBAL producer of
              data collection, usage, and disclosure are all covered in detail
              in our Privacy Policy.
            </p>
            <p className="terms-pg__info">
              Methods of interaction between us:
              <br />
               You may subscribe to RBAL&#39;s email newsletter, text messages
              to the phone number you provide, or push notifications through the
              online services. When you sign up for any of these channels, you
              agree to receive marketing, transactional, and other messages from
              RBAL. In accordance with RBAL&#39;s Privacy Statement, we may use
              the information you have provided to us to contact you.
            </p>
            <p className="terms-pg__info">
              With regards to the goods offered by the web services:
              <br />
              There is no guarantee that the restaurant where you pick up your
              order will have the items you ordered in stock. You might not be
              able to find a particular product/ ingredients in the product at a
              certain restaurant due to unpredictable seasonal conditions and
              supply of products/ ingredients. Please note that the food and
              packaging images depicted on these online services are for
              illustrative purposes only and may differ from what you actually
              receive when ordering from a restaurant. Colours may look
              different on your screen than they actually are due to various
              factors.
            </p>
            <p className="terms-pg__info">
              Regarding the cost of your order:
              <br />
              RBAL sets its own prices and is has the right to levy any
              applicable taxes or charges at any time at its sole discretion.
              Restaurant menu/ online menu listing for different regions may
              have different prices. It&#39;s possible that some promotions and
              prices won&#39;t apply to your order.
            </p>
            <p className="terms-pg__info">
              Unsolicited Ideas:
              <br />
              According to our policy, RBAL does not review suggestions that
              were not specifically requested. Although we value your interest
              in RBAL, we are unable to evaluate suggestions submitted from
              outside our system at this time. You hereby release RBAL and
              employees from any and all claims arising out of their
              consideration, use, or development of any product, design,
              concept, or other materials similar to, or identical to, your
              submission, whether such consideration, use, or development occurs
              now or in the future.
            </p>
            <p className="terms-pg__title">
              Acceptable uses and restrictions for the online services:
            </p>
            <p className="terms-pg__info">
              With respect to the online services (which includes content), you
              may not, and may not allow third parties, to:
            </p>
            <ul type="a" className="terms-pg__list">
              <p className="terms-pg__info">
                <li>
                  Use the online services for any purpose that is unlawful or
                  prohibited by these terms.
                </li>
              </p>
              <p className="terms-pg__info">
                <li>
                  Delete or change any copyright, trademark, or other
                  proprietary notices.
                </li>
              </p>{' '}
              <p className="terms-pg__info">
                <li>
                  Attempt to obtain ownership or title to the online services,
                  including the content.
                </li>
              </p>{' '}
              <p className="terms-pg__info">
                <li>
                  Use, copy, distribute, republish, display, disclose, upload,
                  post, or transmit the online services in any commercial
                  manner.
                </li>
              </p>{' '}
              <p className="terms-pg__info">
                <li>
                  Rent, lease, sell, sub-license, loan, translate, merge, adapt,
                  assign or transfer the online services, or combine them with,
                  or incorporate them into, any other programs or services.
                </li>
              </p>{' '}
              <p className="terms-pg__info">
                <li>
                  Disassemble, decompile, reverse-engineer, copy in source or
                  object code format, or create derivative works based on the
                  online services.
                </li>
              </p>{' '}
              <p className="terms-pg__info">
                <li>
                  Transfer, provide, export or re-export the online services in
                  violations of an embargo, trade sanction, or other technology
                  control or export laws and regulations under applicable law;
                </li>
              </p>{' '}
              <p className="terms-pg__info">
                <li>
                  Use or launch any unauthorized technology or automated system
                  to access the online services or extract content from the
                  online services, including but not limited to spiders, robots,
                  screen scrapers, or offline readers.
                </li>
              </p>
              <p className="terms-pg__info">
                <li>
                  Attempt to disable, damage, overburden, impair or gain
                  unauthorized access to the online services, systems network or
                  any user accounts associated with the online services.
                </li>
              </p>
            </ul>
            <p className="terms-pg__title">
              Limitations on liability and disclaimers:
            </p>
            <p className="terms-pg__info u_text_transform_uppercase">
              NEITHER RBAL NOR ITS EMPLOYEES WILL BE LIABLE FOR ANY DIRECT OR
              INDIRECT LOST PROFITS OR LOST BUSINESS DAMAGE, SPECIAL, INDIRECT,
              CONSEQUENTIAL, EXEMPLARY OR INCIDENTAL DAMAGES, INCLUDING LOST
              DATA, PERSONAL INJURY, OR PROPERTY DAMAGE RELATED TO OR ARISING
              OUT OF THE ONLINE SERVICES. NOTHING IN THIS SECTION IS INTENDED TO
              LIMIT RBAL’S LIABILITY FOR DAMAGES TO THE EXTENT CAUSED BY BURGER
              KING’S OWN NEGLIGENCE, GROSS NEGLIGENCE OR INTENTIONAL OR UNLAWFUL
              MISCONDUCT. ADDITIONALLY, NOTHING IN THIS SECTION IS INTENDED TO
              LIMIT OR ALTER YOUR RIGHTS AS A CONSUMER THAT CANNOT BE LIMITED OR
              ALTERED UNDER APPLICABLE LAWS. RBAL RESERVES ALL LEGAL RIGHTS TO
              RECOVER DAMAGES OR OTHER COMPENSATION UNDER THESE TERMS OR AS
              ALLOWED BY LAW.
            </p>
            <p className="terms-pg__title">Third Party Services:</p>
            <p className="terms-pg__info">
              The online services may link to or allow you to use third-party
              websites, downloadable materials, content, social networks, or
              other digital services (together, “third party services”). These
              third parties may have their separate terms and conditions or
              privacy policies that you should review and understand before
              using them. RBAL does not endorse and is not associated with any
              of these third party services. RBAL AND ANY OF ITS EMPLOYEES HAVE
              NO RESPONSIBILITY FOR ANY LOSS OR DAMAGES ARISING FROM OR RELATED
              TO THESE THIRD PARTY SERVICES.
            </p>{' '}
            <p className="terms-pg__title">Click Stream Data:</p>
            <p className="terms-pg__info">
              RBAL may keep track of things like the number of hits, the length
              of visits, and the peak times of day for users to log in so they
              can better understand how the site is used. This data may be used
              by RBAL to determine how to better serve our users and evolve our
              online presence. <br /> Cookies:
              <br />
              For reference, &quot;cookies&quot; are bits of data that a
              website/ application stores on a user&#39;s computer. When you
              visit our Site, cookies help the Site remember information that
              will make your next visit easier. When you visit RBAL, you&#39;ll
              notice that we, like many other websites, use a standard
              technology called &quot;cookies&quot; to enhance your time spent
              browsing the Site. For instance, we keep anonymous, aggregate
              statistics on the total number of people who visit our website. In
              order to personalise your experience, we may use
              &quot;cookies&quot; to track the pages and products you&#39;ve
              viewed on our site and to identify you when you return. For
              instance, after completing a purchase, you may be given the option
              to add some customizations to your order so that it can be saved
              for future use. In such cases, we may link your individual data
              with the data stored in a cookie. Most web browsers will allow you
              to delete cookies from your computer, block all cookies, or get a
              warning before a cookie is stored. To find out how to do this in
              your particular browser, please consult its documentation or
              on-screen help. It&#39;s possible that some of our site&#39;s
              features won&#39;t work properly if you turn off cookies.
            </p>
            <p className="terms-pg__title u_text_transform_uppercase">
              COMPLIANCE REQUIREMENTS
            </p>
            <p className="terms-pg__info">
              Every user of the Sites shall comply with all the applicable laws,
              including but not limited to the, Information Technology Act,
              2000, Income Tax Act, 1961, and shall include any amendments,
              notifications under any acts and rules thereof under the aforesaid
              enactments applicable for using Sites or buying any product or
              service through Sites.
            </p>
            <p className="terms-pg__title u_text_transform_uppercase">
              USER ACCOUNT
            </p>
            <p className="terms-pg__info">
              You may be required to register and set up an account (“Account”)
              for using Sites by entering User Name, Mobile number and OTP
              received on your mobile number (&quot;Account Information&quot;).
              The Company as its sole discretion may ask for the verification of
              Your Account Information provided by You and hence, it is
              advisable to provide a valid information e.g. like mobile number
              etc. while creating an Account.
            </p>
            <p className="terms-pg__info">
              You can create one Account with one mobile number only and it is
              not advisable to transfer or interchange such Account to any
              unknown person. The list of information required to be provided by
              You, the manner of usage, protection and confidentiality of Your
              information and Account Information are more specifically dealt
              under the Website’s Privacy Policy and You are requested to read
              the Privacy Policy in detail before sharing Your information or
              creating an Account.
            </p>
            <p className="terms-pg__title u_text_transform_uppercase">
              SITE DISCLAIMER
            </p>
            <p className="terms-pg__info">
              The materials and information on the Sites may include technical
              inaccuracies or typographical errors. The materials, information
              and services on the Sites are provided “as is” without any
              conditions, warranties or other terms of any kind. Accordingly, to
              the maximum extent permitted by applicable law, Company disclaims
              all other warranties, express or implied, including, without
              limitation, implied warranties of merchantability, fitness for a
              particular purpose, title and non-infringement as to the Sites and
              the information, content and materials contained therein.
            </p>
            <p className="terms-pg__title u_text_transform_uppercase">
               PAYMENT POLICY
            </p>
            <p className="terms-pg__info">
              Payment is an integral part of an online ordering experience. All
              payments shall be collected through secured payment options.
            </p>
            {/* <p className="terms-pg__info">
              We accept all prepaid payment options such as all the major Credit
              Cards, Debit Cards, Net-Banking , Paytm wallets, Cash on delivery
              (only for Delivery) etc.
            </p> */}
            <p className="terms-pg__info">
              We accept all payment options such as Credit Cards, Debit Cards,
              Net-Banking, wallets, UPI, Sodexo, Cash, Buy Now Pay Later Option
              etc.
            </p>
            <p className="terms-pg__info">
              All payments for purchases made by You on the Sites shall only be
              in Indian Rupees. In case of a prepaid transaction, after payment
              for the order has been successfully received by the Company, You
              will be sent a notification by SMS on your registered mobile
              number provided to the Company confirming receipt of your order.
              The Company reserves the right to withdraw or block or limit Your
              purchases using Cash on Delivery at any time.
            </p>
            <p className="terms-pg__info">Credit and Debit Card</p>
            <p className="terms-pg__info">
              The Company accepts all Visa, Master Card, Maestro, American
              Express and Diners cards. You will be redirected to Your issuing
              bank&#39;s website, where You will have to follow the instruction
              to complete the payment.
            </p>
            <p className="terms-pg__info">Net Banking</p>
            <p className="terms-pg__info">
              You can make payment for an order by using Your Net Banking
              account. The Sites accepts payments for Your orders through your
              net banking account. You will be redirected to Your bank&#39;s
              website where You will be able to make payment using your payment
              process.
            </p>
            <p className="terms-pg__info">Cash on Delivery</p>
            <p className="terms-pg__info">
              {' '}
              Cash on Delivery may be available for certain areas and the
              Company solely reserves the right withdraw or block or limit Your
              purchases using Cash on Delivery at any time.
            </p>
            <p className="terms-pg__info">Issuing Bank Terms and Conditions</p>
            <p className="terms-pg__info">
              Users understand and acknowledge that all payments made by the
              Users using valid credit card/ Debit card/ Cash Card/ and other
              payment instruments or mechanism including net banking facility
              will be processed using a payment gateway or appropriate payment
              system infrastructure and the same is governed by a separate set
              of terms and conditions agreed between the User and their
              respective issuing Bank and payment instrument of the issuing
              company.
            </p>
            <p className="terms-pg__title">DELIVERY POLICY</p>
            <ol type="a" className="terms-pg__list">
              <p className="terms-pg__info">
                <li>
                  The acceptance of order shall be at the entire discretion of
                  the Company.
                </li>
              </p>
              <p className="terms-pg__info">
                <li>
                  It is clarified that delivery is not available at all
                  restaurants of Burger King. To check whether delivery is
                  available in your area, customers need to :{' '}
                </li>
              </p>
              <ol type="i" className="terms-pg__list">
                <p className="terms-pg__info">
                  <li>
                    {' '}
                    log on to website viz. http://www.burgerkingindia.in; and/or{' '}
                  </li>
                </p>
                <p className="terms-pg__info">
                  <li>
                    check the mobile site viz. https://www.burgerking.in/ ;
                    and/or{' '}
                  </li>
                </p>
                <p className="terms-pg__info">
                  <li>
                    {' '}
                    check the mobile applications viz. Burger King which is
                    available on Android, and Apple Store.
                  </li>
                </p>
              </ol>
              <p className="terms-pg__info">
                <li>
                  The Company reserves the right, in its sole discretion,
                  without limitations, conditions and/or qualifications, and
                  without need of prior notification, to limit, change, modify
                  or cancel any order, and/or to refuse any service to any
                  customer for any reason attributable to the unavailability of
                  the items ordered, or other circumstances and conditions
                  beyond the control of the Company which would render
                  impossible or delay the fulfillment of its obligations.
                </li>
              </p>
              <p className="terms-pg__info">
                <li>
                  The Company reserves the right to refuse or to accept or to
                  cancel any order from any customer beyond the delivery area or
                  otherwise.
                </li>
              </p>
              <p className="terms-pg__info">
                <li>Delivery orders are subject to: </li>
              </p>
              <ol type="i" className="terms-pg__list">
                <p className="terms-pg__info">
                  <li>
                    Customer addresses falling in the delivery area (area within
                    which Burger King delivery is available) of the nearest
                    restaurant. The delivery area is defined from time to time
                    by the Company at its discretion and is subject to change.{' '}
                  </li>
                </p>
                <p className="terms-pg__info">
                  <li>
                    {' '}
                    The delivery address being mapped to the nearest restaurant
                    that delivers in customer area.{' '}
                  </li>
                </p>
                <p className="terms-pg__info">
                  <li>Availability of products of the Company. </li>
                </p>
                <p className="terms-pg__info">
                  <li>
                    In case the delivery locality is not listed in the
                    restaurant map, delivery orders cannot be placed.
                  </li>
                </p>
              </ol>
              <p className="terms-pg__info">
                <li>
                  Once the order has been placed it cannot be changed or
                  cancelled by the customers for any reason whatsoever subject
                  to the cancellation policy.
                </li>
              </p>
              <p className="terms-pg__info">
                <li>
                  The price of the Company’s products is exclusive of below
                  charges. The customer is liable to pay delivery charges and
                  restaurant handling charges (on the order placed through the
                  Sites) as applicable in that particular city plus goods and
                  service tax and other taxes as may be applicable from time to
                  time.
                </li>
              </p>
              <p className="terms-pg__info">
                <li>
                  The Company’s personnel shall use its best efforts to meet the
                  scheduled delivery time, however, it does not guarantee these
                  delivery timelines in every case. The Company shall not have
                  any liability to Customer if the customer/ User&#39;s order
                  fails to meet in whole or in part such scheduled delivery
                  time.
                </li>
              </p>
              <p className="terms-pg__info">
                <li>
                  It is customer/ User’s responsibility to ensure that customer/
                  user, himself/herself or an appropriate person is available at
                  the delivery address and he/she must pay in full for the order
                  placed through the Sites. If customer/ Users or appropriate
                  person fails to be present for delivery, the Company is
                  entitled to charge the customer/ User in full the amount of
                  order and the cost of delivery at its sole discretion.
                </li>
              </p>
            </ol>
            <p className="terms-pg__info">
              RBAL holds all rights to reject the delivery of an order or to
              cancel the same owing to any foreseeable and/or unforeseeable
              reasons which might cause any hindrance in fulfilment of the same
              without any obligation to the customer from RBAL’s part.
            </p>
            <p className="terms-pg__title u_text_transform_uppercase">
              LATE NIGHT DELIVERY
            </p>
            <ul type="a" className="terms-pg__list">
              <p className="terms-pg__info">
                <li>
                  Late night delivery is applicable in the selected restaurants
                  of Burger King within the territory of India, subject to the
                  local laws and availability of stocks/ riders in the
                  restaurants.
                </li>
              </p>
              <p className="terms-pg__info">
                <li>
                  Delivery Charges, Restaurant Handling Charges and other
                  charges shall be applicable to all delivery orders.
                </li>
              </p>{' '}
              <p className="terms-pg__info">
                <li>
                  Delivery Charges, Restaurant Handling Charges and other
                  charges will be applicable on per invoice/bill in case of
                  multiple delivery orders.
                </li>
              </p>{' '}
              <p className="terms-pg__info">
                <li>
                  Late night orders can be placed through all Burger King
                  digital assets (Website/ BK App).
                </li>
              </p>{' '}
              <p className="terms-pg__info">
                <li>
                  Advance ordering is not allowed under Late night delivery
                  ordering.
                </li>
              </p>{' '}
              <p className="terms-pg__info">
                <li>
                  Takeaway is allowed. Dine-in is not allowed under Late night
                  delivery ordering.
                </li>
              </p>{' '}
              <p className="terms-pg__info">
                <li>
                  RBAL reserves the right to modify the Late night delivery
                  terms &amp; conditions (including charges) and the same will
                  be communicated to the customer on checkout page of the order.
                </li>
              </p>
            </ul>
            <p className="terms-pg__title u_text_transform_uppercase">
              IN-STORE TABLE ORDERING THROUGH QR CODE
            </p>
            <p className="terms-pg__info">
              {' '}
              Table ordering can be availed by the customers by scanning QR code
              which is present on tables. Every table has a unique QR code which
              redirects users to:
            </p>
            <ol type="1" className="terms-pg__list">
              <p className="terms-pg__info">
                <li>
                  App store to download the app, if user doesn’t have the app.
                </li>
              </p>
              <p className="terms-pg__info">
                <li>Burger King App if user has the app.</li>
              </p>
            </ol>
            <p className="terms-pg__info">
              QR code on tables are unique to Burger King and can only be used
              for in-store ordering. If QR code scan results to any other
              website or link redirection, guest should immediately stop the
              ordering process and contact the store staff.
            </p>
            <p className="terms-pg__info">Steps to avail table ordering:</p>
            <ol type="1" className="terms-pg__list">
              <p className="terms-pg__info">
                <li>
                  Guest are requested to be seated at any table of their choice.
                </li>
              </p>{' '}
              <p className="terms-pg__info">
                <li>
                  Guest needs to open the camera (android) or QR code scanner
                  (iOS).
                </li>
              </p>{' '}
              <p className="terms-pg__info">
                <li>Scan the code.</li>
              </p>{' '}
              <p className="terms-pg__info">
                <li>Redirection to app store Burger king app.</li>
              </p>{' '}
              <p className="terms-pg__info">
                <li>Download the app.</li>
              </p>{' '}
              <p className="terms-pg__info">
                <li>Browse the Menu.</li>
              </p>{' '}
              <p className="terms-pg__info">
                <li>Add your favourites to the cart.</li>
              </p>{' '}
              <p className="terms-pg__info">
                <li>Check out using multiple payment options.</li>
              </p>{' '}
              <p className="terms-pg__info">
                <li>Order places.</li>
              </p>{' '}
              <p className="terms-pg__info">
                <li>Hot food will be served to your table.</li>
              </p>
            </ol>
            <p className="terms-pg__title u_text_transform_uppercase">
              Customer Cancellation and Refund
            </p>
            <p className="terms-pg__title">Cancellation of Order</p>
            <p className="terms-pg__info">
              As a general rule You shall not be entitled to cancel Order once
              placed. You may choose to cancel Order only within one-minute (ie
              60 seconds) of the Order being placed.
            </p>
            <p className="terms-pg__title">Refund</p>
            <ol type="1" className="terms-pg__list">
              <p className="terms-pg__info">
                <li>
                  {' '}
                  You may be entitled to a refund for prepaid Orders. You shall
                  also be entitled to a refund of proportionate value in the
                  event packaging of an item in an Order or the complete Order,
                  is either tampered or damaged and You refuse to accept the
                  Order at the time of delivery for the said reason.{' '}
                </li>
              </p>
              <p className="terms-pg__info">
                <li>
                  {' '}
                  You may be entitled to a refund upto 100% of the Order value
                  if the Company fails to deliver the Order. Such incidences
                  will be assessed by the company on a case to case basis.{' '}
                </li>
              </p>
              <p className="terms-pg__info">
                <li>
                  {' '}
                  The Company decision on refunds shall be final and binding.{' '}
                </li>
              </p>
              <p className="terms-pg__info">
                <li>
                  {' '}
                  All refund amounts shall be credited to your account as may be
                  stipulated as per the payment mechanism of your choice, the
                  estimated timelines are detailed as below:{' '}
                </li>
              </p>
            </ol>
            <div className="terms-pg__table">
              <RefundProcess />
            </div>
            <p className="terms-pg__title u_text_transform_uppercase">
              INDEMNITY
            </p>
            <p className="terms-pg__info">
              You agree to indemnify and hold harmless the Company including
              affiliates, directors, officers, agents, shareholders and partners
              from and against all actions, proceedings, claims, losses, damages
              and costs (including attorney fees) and other expenses suffered or
              incurred due to or attributable to or arising out of (i) violation
              of any applicable law (ii) breach of any representations,
              warranties or covenants of the User (iii) breach of the policies,
              guidelines and conditions of the Company’s service or Additional
              Terms or these Terms or Privacy Policy (including any
              modifications or additional notifications or instructions); (iv)
              infringement of any third party rights. This Clause survives the
              termination of these Terms.
            </p>
            <p className="terms-pg__title">
              Restaurant Brands Asia Limited (RBAL) Trademarks and Copyrights{' '}
            </p>
            <p className="terms-pg__info">
              Unless otherwise indicated, the Sites and all content and other
              materials therein including without limitation to all trademarks,
              logos, and service marks displayed on the Sites are registered and
              unregistered Trademarks of BKC and/or third parties (including the
              Company) who have authorized their use (collectively the
              “Trademarks”). All trademarks, designs, text, graphics, pictures,
              information, data, software, sound files, other files and the
              selection and arrangement thereof (collectively, &quot;Site
              Materials&quot;) are the proprietary mark of the Company or its
              licensors or users and are protected by Indian and/ or
              international trademark and copyright laws. The IPR relating to
              RBAL, RBA, Restaurant Brands Asia Limited are owned by the
              Company.
            </p>
            <p className="terms-pg__info">
              You may not use, copy, reproduce, republish, upload, post,
              transmit, distribute, or modify these Trademarks in any way. The
              use of BKC’s Trademarks on any other website is strictly
              prohibited. All of the materials contained on the Sites are
              copyrighted except where explicitly noted otherwise. BKC will
              aggressively enforce its intellectual property rights to the
              fullest extent of the law, including the seeking of criminal
              prosecution. BKC and or the Company neither warrants nor
              represents that your use of materials displayed on the Sites will
              not infringe rights of third parties not owned by or affiliated
              with BKC.
            </p>
            <p className="terms-pg__info">
              Use of any Site Materials is at your own risk. Further, the Site
              Materials will be used only for personal, informational and
              ordering purposes. Such license is subject to the Terms of Use and
              does not include: (a) any resale or commercial use of the Sites or
              Site Materials; (b) the collection and use of any product
              listings, pictures or descriptions; (c) the distribution, public
              performance or public display of any Site Materials; (d) modifying
              or otherwise making any derivative uses of the Sites and the Site
              Materials, or any portion thereof; (e) use of any data mining,
              robots or similar data gathering or extraction methods; (f)
              downloading (other than the page caching) of any portion of the
              Sites, the Site Materials or any information contained therein,
              except as expressly permitted on the Sites; or (g) any use of the
              Sites or the Site Materials other than for its intended purpose.
              Any use of the Sites or Site Materials other than as specifically
              authorized herein, without the prior written permission of the
              Company, is strictly prohibited. Such unauthorized use may also
              violate applicable laws, including, without limitation, copyright
              and trademark laws and applicable communications regulations and
              statutes. Unless explicitly stated herein, nothing in these Terms
              of Use shall be construed as conferring in any manner, whether by
              implication, estoppel or otherwise, any title or ownership of, or
              exclusive use-rights to, any intellectual property or other right
              and any goodwill associated therewith.
            </p>
            <p className="terms-pg__title">Restrictions on Use</p>
            <p className="terms-pg__info">
              The Sites may include interactive areas in which you or other
              registered users may create, post, send or store messages,
              materials, data, information, text, music, sound, photos, video,
              graphics, applications, tags, code, links or other items or
              materials on the Sites ("User Content"). By using the Sites, you
              agree not to post, upload, transmit, distribute, store, create or
              otherwise publish through the Sites any of the following:
            </p>
            <ol type="a" className="terms-pg__list">
              <p className="terms-pg__info">
                <li>
                  Unless specifically requested by Company, any “sensitive”
                  personally identifiable information about yourself or another
                  person (including, but not limited to, information that
                  relates to health or medical conditions including physical,
                  physiological and mental health conditions, passwords, social
                  security numbers, biometric information, credit cards, debit
                  cards, other payment instruments, bank accounts or other
                  financial information, other information concerning trade
                  union membership, sex life, sexual orientation, political
                  opinions, criminal charges or convictions, religious beliefs,
                  racial or ethnic origin, or other sensitive matters);
                </li>
              </p>
              <p className="terms-pg__info">
                <li>
                  User Content that is unlawful, blasphemous, paedophilic,
                  racially or ethnically objectionable, libelous, defamatory,
                  obscene, pornographic, indecent, lewd, suggestive, harassing,
                  threatening, invasive of privacy or publicity rights, abusive,
                  inflammatory, fraudulent, otherwise objectionable or which
                  threatens our relationships with our employees, partners,
                  customers, suppliers or licensors, relates to or encourages
                  money laundering, gambling or otherwise unlawful in any
                  manner;
                </li>
              </p>
              <p className="terms-pg__info">
                <li>
                  User content that may infringe any patent, trademark, trade
                  secret, copyright or other intellectual or proprietary right
                  of any party. By posting any User Content, you represent and
                  warrant that you have the lawful right to distribute and
                  produce such User Content;
                </li>
              </p>
              <p className="terms-pg__info">
                <li>
                  {' '}
                  User Content that impersonates any person or entity or
                  otherwise misrepresents your identity or affiliation with
                  another person or entity;
                </li>
              </p>
              <p className="terms-pg__info">
                <li>
                  Spam, direct marketing communications or any unsolicited
                  advertising, promotional materials or other forms of
                  solicitation or commercial content;
                </li>
              </p>
              <p className="terms-pg__info">
                <li>
                  User Content that constitutes, encourages or provides
                  instructions for a criminal offense, violates the rights of
                  any party or that creates liability or violates any applicable
                  local, state, national or international law;
                </li>
              </p>
              <p className="terms-pg__info">
                <li>
                  Software viruses, spyware, Trojan horses, easter eggs or any
                  other destructive files, computer codes or programs designed
                  to interrupt destroy or limit the functionality of any
                  computer resource;
                </li>
              </p>
              <p className="terms-pg__info">
                <li>
                  User Content that, in the sole judgment of the Company, is
                  objectionable, restricts or inhibits any other person from
                  using or enjoying the Sites or which damages the image or
                  rights of the Company, other users or third parties;
                </li>
              </p>
              <p className="terms-pg__info">
                <li>
                  User Content that belongs to another person and to which the
                  user does not have any right;
                </li>
              </p>
              <p className="terms-pg__info">
                <li>User Content that harms minors in any way;</li>
              </p>
              <p className="terms-pg__info">
                <li>
                  User Content which deceives or misleads the addressee about
                  the origin of the messages or communicates any information
                  which is grossly offensive or menacing in nature; and
                </li>
              </p>
              <p className="terms-pg__info">
                <li>
                  User Content that threatens the unity, integrity, defence,
                  security or sovereignty of India, friendly relations with
                  foreign states, public order, causes incitement to the
                  commission of any cognizable offence or prevents investigation
                  of any offence or is insulting to any other nation.
                </li>
              </p>
            </ol>
            <p className="terms-pg__info">
              The Company does not control, take responsibility for or assume
              liability for any User Content posted, stored or uploaded by you
              or any third party, or for any loss or damage thereto, nor is the
              Company liable for any user conduct or any mistakes, defamation,
              slander, libel, omissions, falsehoods, obscenity, pornography or
              profanity you may encounter. The interactive areas are generally
              designed as open and public community areas for connecting and
              sharing with other people. When you participate in these areas,
              you understand that certain information and content you choose to
              post may be displayed publicly. You are solely responsible for
              your use of the Sites and agree to use the interactive areas at
              your own risk.
            </p>
            <p className="terms-pg__info">
              If you become aware of User Content that you believe violates
              these Terms of Use, you may report it by clicking on the "Report
              Abuse" or "Flag" links located just below each piece of User
              Content. Enforcement of these Terms of Use, however, is solely in
              our discretion and absence of enforcement in some instances does
              not constitute a waiver of our right to enforce the Terms of Use
              in other instances. In addition, these Terms of Use do not create
              any private right of action on the part of any third party or any
              reasonable expectation or promise that the Sites will not contain
              any content that is prohibited by these Terms of Use. Subject to
              applicable law, the Company has no obligation to screen, edit or
              monitor any of the User Content posted on the Sites, the Company
              reserves the right, and has absolute discretion, to remove, screen
              or edit any User Content on the Sites at any time and for any
              reason without notice. You are solely responsible for creating
              backup copies and replacing any User Content you post or store on
              the Sites at your sole cost and expense.
            </p>
            <p className="terms-pg__info">
              If you are viewing the Sites on a public computer or are otherwise
              using a computer to which multiple people have potential access,
              be sure to follow all relevant instructions to ensure you are
              sufficiently disconnected and logged off the Sites and the
              computer system you are using to prevent unauthorized access to or
              uploading of User Content.
            </p>
            <p className="terms-pg__title u_text_transform_uppercase">
              THIRD PARTY LINKS
            </p>
            <p className="terms-pg__info">
              You agree that we shall not be responsible or liable in any way
              for any loss or damages of any sort incurred as the result of any
              such dealings with any third parties.
            </p>
            <p className="terms-pg__title u_text_transform_uppercase">
              Severability
            </p>
            <p className="terms-pg__info">
              If any provision of these Terms of Use shall be deemed unlawful,
              void or for any reason unenforceable, then that provision shall be
              deemed severable from these Terms of Use and shall not affect the
              validity and enforceability of any remaining provisions.
            </p>
            <p className="terms-pg__title u_text_transform_uppercase">
              Contact Us
            </p>
            <p className="terms-pg__info">
              Please feel free to contact us with any comments, questions or
              suggestions you might have regarding the information described in
              the Sites.
            </p>
            <p className="terms-pg__info">You may contact us at:</p>
            <p className="terms-pg__info">Restaurant Brands Asia Limited.</p>
            <p className="terms-pg__info">
              Office no 1003 to 1007, B Wing, 10th Floor, Mittal Commercia,
            </p>
            <p className="terms-pg__info">
              Asan Pada Road, Chimatpada, Marol,{' '}
            </p>
            <p className="terms-pg__info">Andheri (East), Mumbai – 400059</p>
            <p className="terms-pg__info">
              Email Id :{' '}
              <a href="mailto:customercare@burgerking.in">
                customercare@burgerking.in
              </a>
            </p>
            <p className="terms-pg__title">GOVERNING LAW AND JURISDICTION</p>
            <p className="terms-pg__info">
              The terms and conditions of this agreement shall be governed by
              Indian law and the courts in Mumbai shall only have exclusive
              jurisdiction to resolve any disputes.
            </p>
          </div>
        </div>
        {isSeparatePage ? <Footer /> : ''}
      </div>
    );
  }
}

export default withRouter(TermsAndConditions);

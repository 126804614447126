import React from 'react';
import SocialButton from '../../components/social-login-button';

const SocialLoginContainer = props => {
  const { onFacebookLogin, responseGoogle } = props;
  const Dom = (
    <div>
      <SocialButton
        onFacebookLogin={onFacebookLogin}
        responseGoogle={responseGoogle}
      />
    </div>
  );
  return Dom;
};

export default SocialLoginContainer;

// Imports: Dependencies
import { all } from 'redux-saga/effects';
// Imports: Redux Sagas
import homeSaga from './home/sagas';
import productListSaga from './productListing/sagas';
import signupSaga from './login/sagas';
import redisCartSaga from './redisCart/sagas';
import cartSaga from './cart/sagas';
import addressSaga from './address/sagas';
import orderHistorySaga from './orders/sagas';
import offersSaga from './offers/sagas';

// Redux Saga: Root Saga
function* rootSaga() {
  yield all([
    ...homeSaga,
    ...signupSaga,
    ...productListSaga,
    ...redisCartSaga,
    ...addressSaga,
    ...orderHistorySaga,
    ...offersSaga,
    ...cartSaga
  ]);
}

export default rootSaga;

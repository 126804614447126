/* eslint-disable no-undef */
import './index.scss';
import '../../containers/offer-listing/index.scss';

import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';

import RecentOrderDetail from '../../containers/recent-order-detail';
import LoaderBackDrop from '../../components/loader-backdrop';

import OrderHistoryList from '../../components/order-history-list';
import AccountSubSecTitle from '../../components/accout-subsec-title';
import Loader from '../../components/loader-spin';
import FeedbackPage from '../new-feedback-page';
import RatingPage from '../new-rating-page';
import {
  feedbackApiV2,
  getOrderDetails,
  getOrderHistoryList
} from '../../burger-king-lib/redux/orders/actions';
import { ROUTES as _routes } from '../../constants/app-constants';
import RecentOrdersEmpty from '../../components/recent-orders-empty';
import { LocalSessionKey } from '../../burger-king-lib/constants/app-constants';
import Cancelpop from '../../components/cancel-popover';
import { LocalStorage } from '../../burger-king-lib/utils/storage.web';

class RecentOrders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allOrdersData: [],
      pastOrdersList: [],
      activeContent: 'listing',
      activeTitle: 'Recent Orders',
      showLoader: true,
      showBackDrop: false,
      orderDetailData: {},
      showFeedback: false,
      showRating: false,
      order: {},
      wholeData: [],
      page: 1,
      hasMore: true,
      offerApply: false,
      fromRating: false
    };
  }

  componentDidMount() {
    const { location } = this.props;
    // console.log(location, ' location');
    if (location.search.length || location?.state === 'isFromDeeplink') {
      const isLoggedIn = LocalStorage.get(LocalSessionKey.USER_DATA) || '';
      if (!Boolean(isLoggedIn)) {
        this.setState({
          offerApply: true
        });
      }
    }
    const { checkStatusType } = this;
    checkStatusType(location);
  }

  componentDidUpdate(prevProps) {
    const { orderHistoryData } = this.props;
    const { fromRating } = this.state;
    if (
      orderHistoryData &&
      orderHistoryData.orderHistoryList &&
      orderHistoryData.orderHistoryList !==
        prevProps.orderHistoryData.orderHistoryList
    ) {
      if (orderHistoryData.orderHistoryList.content) {
        this.setOrderListItem(orderHistoryData.orderHistoryList.content);
      }
      if (
        orderHistoryData.orderHistoryList.message &&
        orderHistoryData.orderHistoryList.error !== 0
      )
        this.hideLoader();
    }
    if (
      orderHistoryData &&
      orderHistoryData.orderDetails &&
      orderHistoryData.orderDetails !== prevProps.orderHistoryData.orderDetails
    ) {
      if (orderHistoryData.orderDetails.content && !fromRating) {
        this.setOrderDetailData(orderHistoryData.orderDetails.content);
      } else if (orderHistoryData.orderDetails.content && fromRating) {
        this.openRating(orderHistoryData.orderDetails.content);
      }
      if (
        orderHistoryData.orderDetails.message &&
        orderHistoryData.orderDetails.error !== 0
      ) {
        alert('Something wen wrong try again');
        this.hideLoader();
      }
    }
  }

  checkStatusType = location => {
    if (location && location.state) {
      if (location.state && location.state.id) {
        const payload = {
          order_id: `${location.state.id}`
        };
        this.getOrderDetailData(payload);
      }
    } else {
      const { orderList } = this.state;
      if (!(orderList && orderList.length)) {
        const { getOrderHistoryData } = this;
        getOrderHistoryData();
      } else {
        this.setOrderListPreviousData();
      }
    }
  };

  hideLoader = () => {
    this.setState({ showLoader: false });
  };

  getOfferData = () => {
    setTimeout(() => {
      this.setState({ offerApply: false });
      this.props.history.push('/');
    }, 3000);
    return (
      <div className="offerlisting_offer">
        <p className="offerlisting_offer--text">Alert</p>
        <p className="offerlisting_offer--text">Kindly Login to continue.</p>
        <p className="offerlisting_offer--notify"> Login and Re-try</p>
      </div>
    );
  };

  getOrderHistoryData = payload => {
    const { getOrderHistoryData } = this.props;
    const payloadd = !payload ? { offset: 10, page: 1 } : payload;
    const meta = 'recent-orders';
    getOrderHistoryData(payloadd, meta);
  };

  getOrderHistoryData1 = () => {
    const { history } = this.props;
    history.push('/account/recent-orders');
  };

  setOrderListItem = data => {
    if (data.length === 0) {
      this.setState({ hasMore: false });
    }
    if (data && data.length) {
      // console.log(data, ' orderdata');
      const { allOrdersData } = this.state;
      this.setState({ allOrdersData: allOrdersData.concat(data) });
    }
    this.hideLoader();
  };

  setOrderListPreviousData = () => {
    this.setState({ activeContent: 'listing', activeTitle: 'Recent Orders' });
  };

  getOrderDetailData = id => {
    const { getOrderDetailsData } = this.props;
    getOrderDetailsData(id);
  };

  setOrderDetailData = data => {
    this.callMe(data);
  };

  callMe = data => {
    // console.log(data, ' RRR TTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTT');
    // const { innerWidth } = window;
    // if (innerWidth > 1023) {
    //   //  activeContent: 'detail',
    //   // orderDetailData: data[0],
    //   // activeTitle: `ORDER #${data?.[0]?.order_id}`,
    //   // showLoader: false
    //   const { history } = this.props;
    //   const path = {
    //     pathname: _routes.FEEDBACK,
    //     state: { id: data?.[0]?.order_id, order: data[0] }
    //   };
    //   history.push(path);
    // } else {
    this.setState({
      activeContent: 'detail',
      orderDetailData: data[0],
      activeTitle: `ORDER #${data?.[0]?.order_id}`,
      showLoader: false
    });
    // }
  };

  goBackOnClick = () => {
    const { history } = this.props;
    history.go(-1);
  };

  orderDetailClick = data => {
    const { history } = this.props;
    const path = {
      pathname: _routes.RECENT_ORDERS,
      state: { id: data.id }
    };
    history.push(path);
  };

  onGoinOrderClick = data => {
    const { history } = this.props;
    const path = {
      pathname: _routes.TRACK_ORDER,
      state: { data }
    };
    history.push(path);
  };

  orderNowClick = () => {
    const { history } = this.props;
    history.push(_routes.HOME);
  };

  fetchData = () => {
    const { page, hasMore } = this.state;
    if (!hasMore) {
      return;
    }
    const pageNum = page + 1;
    const payload = { offset: 10, page: pageNum };
    this.getOrderHistoryData(payload);
    this.setState({ page: pageNum });
  };

  openRating = order => {
    this.setState({
      showRating: true,
      orderID: order?.[0]?.id,
      order,
      showBackDrop: false
    });
  };

  onFeedbackClick = (id, order) => {
    // const { innerWidth } = window;
    // if (innerWidth > 1023) {
    ///onFeedBackClick
    this.setState({ fromRating: true, orderID: id, showBackDrop: true });
    const payload = {
      order_id: `${id}`
    };
    this.getOrderDetailData(payload);
    // } else {
    //   const payload = {
    //     order_id: `${id}`
    //   };
    //   this.getOrderDetailData(payload);
    //   // const { history } = this.props;
    //   // const path = {
    //   //   pathname: _routes.FEEDBACK,
    //   //   state: { id, order }
    //   // };
    //   // history.push(path);
    // }
  };

  getFeedbackApiV2 = (data, rating) => {
    const { postFeedbackApiV2 } = this.props;
    const payload = {
      order_id: data[0]?.id,
      type: data[0]?.cart_added_for,
      overall_rating: rating
    };
    const meta = {
      key: 'home'
    };
    postFeedbackApiV2(payload, meta);
  };

  onCloseModalHandler = () => {
    const { getOrderHistoryData } = this;
    this.setState({ showFeedback: false, showRating: true });
    getOrderHistoryData();
  };

  renderListing = () => {
    const {
      showLoader,
      allOrdersData,
      // pastOrdersList,
      hasMore
    } = this.state;
    const {
      orderDetailClick,
      onGoinOrderClick,
      orderNowClick,
      onFeedbackClick
    } = this;
    const { theme, orderHistoryData } = this.props;
    let dom = '';
    if (showLoader) {
      dom = <Loader theme={theme} />;
    } else {
      dom = (
        <>
          <InfiniteScroll
            dataLength={allOrdersData.length || 0}
            next={this.fetchData}
            hasMore={hasMore}
            height={850}
            endMessage={
              <h2
                className="recent-order__sub-title"
                style={{ textAlign: 'center', fontSize: '2.4rem' }}
              >
                You have seen all the orders
              </h2>
            }
          >
            <div className="recent-order__listing">
              {allOrdersData && allOrdersData.length ? (
                <>
                  <ul>
                    {allOrdersData.map((data, index) => {
                      return (
                        <OrderHistoryList
                          data={data}
                          key={index.toString()}
                          onClick={orderDetailClick}
                          theme={theme}
                          onFeedBackClick={this.onFeedbackClick}
                        />
                      );
                    })}
                  </ul>
                </>
              ) : (
                ''
              )}
              {!allOrdersData.length ? (
                <RecentOrdersEmpty
                  theme={theme}
                  orderNowClick={orderNowClick}
                />
              ) : (
                ''
              )}
            </div>
          </InfiniteScroll>
        </>
      );
    }
    return dom;
  };

  renderContent = () => {
    const { activeContent, orderDetailData } = this.state;
    const { theme } = this.props;
    let dom;
    switch (activeContent) {
      case 'listing':
        dom = this.renderListing();
        break;

      case 'detail':
        dom = <RecentOrderDetail data={orderDetailData} theme={theme} />;
        break;

      default:
        dom = '';
    }

    return dom;
  };

  constCall = () => {
    this.setState({ showFeedback: false, showRating: true });
  };

  closeRating = () => {
    this.setState({ showRating: false });
    this.getOrderHistoryData();
  };

  render() {
    const { goBackOnClick } = this;
    const {
      activeTitle,
      showFeedback,
      showRating,
      orderID,
      order,
      offerApply,
      showBackDrop
    } = this.state;
    const { theme } = this.props;

    return (
      <div className="recent-order_wrapper">
        {showBackDrop ? <LoaderBackDrop theme={theme} /> : ''}
        {offerApply && (
          <Cancelpop content children={this.getOfferData()} theme={theme} />
        )}
        {showFeedback && (
          <FeedbackPage
            onCloseModalHandler={this.onCloseModalHandler}
            theme={theme}
            orderID={orderID}
            data={order}
            constCall={this.constCall}
          />
        )}
        {showRating && (
          <RatingPage
            onCloseModalHandler={this.onCloseModalHandler}
            theme={theme}
            from={'Recent Orders'}
            getFeedbackApiV2={this.getFeedbackApiV2}
            orderID={orderID}
            data={order}
            closeRating={this.closeRating}
            getOrderHistoryData={this.getOrderHistoryData1}
          />
        )}
        <AccountSubSecTitle
          title={activeTitle}
          goBackOnClick={goBackOnClick}
          showDesGoBack={activeTitle !== 'Recent Orders'}
          theme={theme}
        />

        <div className="recent-order">{this.renderContent()}</div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    orderHistoryData: state.orderHistory,
    cartData: state.cart
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getOrderHistoryData: (payloadData, meta) =>
      dispatch(getOrderHistoryList(payloadData, meta)),
    postFeedbackApiV2: (payload, meta) =>
      dispatch(feedbackApiV2(payload, meta)),
    getOrderDetailsData: (payloadData, meta) =>
      dispatch(getOrderDetails(payloadData, meta))
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RecentOrders)
);

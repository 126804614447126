import './index.scss';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import CloseSymbolImage from '../../../assets/images/close-black-symbol.svg';

let initialX = null;
let initialY = null;
let restrictClose = false;

class Modal extends Component {
  componentDidMount() {
    document.body.style.overflow = 'hidden';
    // const myElement =
    //   document && document.getElementsByClassName('modal_wrapper')[0];
    // if (myElement && window.innerWidth < 768) {
    //   myElement.addEventListener('touchstart', this.handleTouchStart, false);
    //   myElement.addEventListener('touchmove', this.handleTouchMove, false);
    // }
  }

  componentWillUnmount() {
    // const myElement =
    //   document && document.getElementsByClassName('modal_wrapper')[0];
    // if (myElement && window.innerWidth < 768) {
    //   myElement.removeEventListener('touchstart', this.handleTouchStart, false);
    //   myElement.removeEventListener('touchmove', this.handleTouchMove, false);
    // }
    document.body.style.overflow = 'auto';
  }

  handleTouchStart = e => {
    initialX = e.touches[0].clientX;
    initialY = e.touches[0].clientY;
    if (e.target.getAttribute('class') === 'modal') restrictClose = true;
    else restrictClose = false;
  };

  handleTouchMove = e => {
    if (initialX === null) return;
    if (initialY === null) return;

    const currentX = e.touches[0].clientX;
    const currentY = e.touches[0].clientY;

    const diffX = initialX - currentX;
    const diffY = initialY - currentY;

    if (!(Math.abs(diffX) > Math.abs(diffY))) {
      if (!(diffY > 0) && restrictClose) {
        this.onCloseSwipeHandler();
      }
    }
    initialX = null;
    initialY = null;
    e.preventDefault();
  };

  onCloseSwipeHandler = () => {
    const { onCloseHandler } = this.props;
    onCloseHandler();
  };

  modalClick = e => {
    if (
      e &&
      e.target &&
      e.target.getAttribute('class') &&
      e.target.getAttribute('class').indexOf('modal ') > -1 &&
      window.innerWidth < 1023
    ) {
      this.onCloseSwipeHandler();
    }
  };

  onCloseHandler = e => {
    const { onCloseHandler } = this.props;
    onCloseHandler();
    e.stopPropagation();
  };

  render() {
    const {
      children,
      footer,
      repeatCustomization,
      isMobileFullLayout,
      theme,
      noBorderRadius,
      centerPopUp,
      contentCustomClass,
      modalCustomClass,
      customClass,
      hideClose,
      closeCustomClass,
      onCloseRepeatHandler,
      contentDataCustomClass,
      fullWidth,
      width
    } = this.props;
    const { onCloseHandler } = this;
    return (
      <div
        className={`modal_wrapper ${customClass} ${theme}`}
        role="presentation"
        onClick={e => this.modalClick(e)}
      >
        <div className={`modal ${modalCustomClass}`}>
          {!hideClose ? (
            <div className="modal__close_wrapper">
              <div
                className={`modal__close ${closeCustomClass}`}
                onClick={e => onCloseHandler(e)}
                role="presentation"
              >
                <img
                  src={CloseSymbolImage}
                  alt="close"
                  className="modal__close-img"
                />
              </div>
            </div>
          ) : (
            ''
          )}

          <div
            className={`modal__content_wrapper  
            ${centerPopUp ? 'modal__content_wrapper-center' : ''}
            ${noBorderRadius ? 'modal__content_wrapper_no-bor-rad' : ''} 
            ${isMobileFullLayout ? 'modal__content_wrapper_full-layout' : ''}
            ${contentCustomClass || ''}`}
            style={{
              maxHeight: fullWidth && '100%',
              height: fullWidth && '100%',
              width: !width ? '100% !important' : `${width}% !important`
            }}
          >
            <div className={`modal__content ${contentDataCustomClass}`}>
              {children}
            </div>
          </div>

          <div className="modal__footer">
            {repeatCustomization && (
              <div className="modal__repeat">
                <div className="modal__repeat_con">
                  <div className="modal__close_wrapper">
                    <div
                      className="modal__close"
                      onClick={e => onCloseRepeatHandler(e)}
                      role="presentation"
                    >
                      <img
                        src={CloseSymbolImage}
                        alt="close"
                        className="modal__close-img"
                      />
                    </div>
                  </div>
                  {repeatCustomization}
                </div>
              </div>
            )}
            {footer && footer}
          </div>
        </div>
      </div>
    );
  }
}

Modal.propTypes = {
  onCloseHandler: PropTypes.func,
  modalCustomClass: PropTypes.string,
  contentCustomClass: PropTypes.string
};

Modal.defaultProps = {
  onCloseHandler: () => {},
  modalCustomClass: '',
  contentCustomClass: ''
};

export default Modal;

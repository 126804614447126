import './index.scss';
import React, { PureComponent } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { getNutrition } from '../../burger-king-lib/redux/home/actions';
import AppleStoreImage from '../../../assets/images/app-store.png';
import newFooterLogo from '../../../assets/images/BK_LOGO_PRIMARY_®_ST_MAYO_EGG_WHITE_RGB.PNG';
import GooglePlayStoreImage from '../../../assets/images/google-play-store.png';
import FacebookLogo from '../../../assets/images/facebook.svg';
import InstagramLogo from '../../../assets/images/instagram.svg';
import TwitterLogo from '../../../assets/images/twitter.svg';
import YoutubeLogo from '../../../assets/images/youtube.svg';

import { ROUTES as _routes } from '../../constants/app-constants';
// import { ADD_NEW_FEEDBACK_API } from '../../burger-king-lib/constants/api-constants';

class Footer extends PureComponent {
  constructor() {
    super();
    this.state = {
      nutritionUrl: '',
      // showLoader: true,
      showPopUp: false,
      show: false,
      firstName: '',
      source: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      orderId: '',
      dis: '',
      loadingProgress: false,
      // fileName: 'No File Choosen',
      // fileType: '',
      formData: null,
      order_type: null,
      source: null,
      selectedOutlet: null
    };
  }

  componentDidMount = () => {
    const token =
      window && window.localStorage && window.localStorage.getItem('TOKEN');
    const Refreshtoken =
      window &&
      window.localStorage &&
      window.localStorage.getItem('REFRESH_TOKEN');
    if (token && Refreshtoken) {
      const url =
        window && window.sessionStorage && window.sessionStorage.getItem('URL');
      if (typeof url != 'string') this.getNutritionDataApi();
      else {
        this.setState({ nutritionUrl: url });
      }
    }
  };

  componentDidUpdate(prevProps) {
    const { nutritionData } = this.props;
    if (
      nutritionData &&
      nutritionData.nutrition &&
      nutritionData.nutrition !== prevProps.nutritionData.nutrition
    ) {
      if (
        nutritionData &&
        nutritionData.nutrition &&
        nutritionData.nutrition.content &&
        nutritionData.nutrition.content.file_url
      ) {
        this.setNutritionData(nutritionData.nutrition.content.file_url);
      }
      if (nutritionData.nutrition.error === 1) this.setNutritionData('');
    }
  }

  getNutritionDataApi = () => {
    const { getNutritionData } = this.props;
    getNutritionData();
  };

  setNutritionData = url => {
    window.sessionStorage.setItem('URL', url);
    this.setState({ nutritionUrl: url });
  };

  imgOnClickHandler = () => {
    const { history, onLogoClick } = this.props;
    const { location } = history;
    const { pathname } = location;
    if (pathname !== '/') {
      history.push(_routes.HOME);
    } else {
      onLogoClick();
    }
  };

  featureAlert = () => {
    alert('Hey! Feature coming soon. stay tuned.');
  };

  creatingFuture = () => {
    const { history } = this.props;
    history.push(_routes.CREATING_BRIGHTER_FUTURES);
  };

  trustTaste = () => {
    const { history } = this.props;
    history.push(_routes.TRUST_TASTE);
  };

  farmToFork = () => {
    const { history } = this.props;
    history.push(_routes.FARM_TO_FORK);
  };

  covid19 = () => {
    const { history } = this.props;
    history.push(_routes.COVID_19);
  };

  freshTaste = () => {
    const { history } = this.props;
    history.push(_routes.FRESH_TASTE);
  };

  Investors = () => {
    const { history } = this.props;
    history.push(_routes.INVESTORS);
  };

  termsClick = () => {
    const { history } = this.props;
    history.push(_routes.TERMS);
  };

  privacyClick = () => {
    const { history } = this.props;
    history.push(_routes.PRIVACY_POLICY_LINK);
  };

  pprivacyClick = () => {
    const { history } = this.props;
    history.push(_routes.PROMOTIONAL_PRIVACY_POLICY_LINK);
  };

  helpSupportClick = () => {
    const { history } = this.props;
    history.push(_routes.HELP_SUPPORT_LINK);
  };

  aboutBKClick = () => {
    const { history } = this.props;
    history.push(_routes.BK_WALL);
  };

  onFranchiseClick = () => {
    const { history } = this.props;
    history.push(_routes.FRANCHISE);
  };

  // validateData = data => {
  //   const firstName = data.firstName;
  //   const lastName = data.lastName;
  //   const phoneNumber = data.phoneNumber;
  //   const email = data.email;
  //   const dis = data.dis;
  //   const source = data.source;
  //   const orderType = data.order_type;
  //   const orderId = data.orderId;
  //   const store = data.store;
  //   // Validate first name
  //   if (firstName === '') {
  //     alert('Please enter your first name.');
  //     return false;
  //   }
  //   // Validate last name
  //   if (lastName === '') {
  //     alert('Please enter your last name.');
  //     return false;
  //   }
  //   // Validate Indian phone number
  //   const phoneNumberRegex = /^[6-9]\d{9}$/; // phone number pattern
  //   if (!phoneNumberRegex.test(phoneNumber)) {
  //     alert('Please enter a valid phone number.');
  //     return false;
  //   }
  //   // Validate email
  //   const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Email pattern
  //   if (!emailRegex.test(email)) {
  //     alert('Please enter a valid email address.');
  //     return false;
  //   }
  //   const reg = /^[A-Za-z0-9]{1,15}$/;
  //   if (orderId === '') {
  //     alert('Please enter order id');
  //     return false;
  //   } else if (!reg.test(orderId)) {
  //     alert('Please enter a valid order-id');
  //     return false;
  //   }
  //   if (dis === '') {
  //     alert('Please enter details.');
  //     return false;
  //   }
  //   if (!store) {
  //     alert('Please Select Store name');
  //     return false;
  //   }
  //   if (!orderType) {
  //     alert('Please Select order type.');
  //     return false;
  //   }
  //   // orderType;
  //   if (!source && orderType.label === 'Delivery') {
  //     alert('Please select source.');
  //     return false;
  //   }

  //   // if (!data.formData) {
  //   //   alert('Please upload any related file');
  //   //   return false;
  //   // }
  //   // All validations passed
  //   return true;
  // };

  onCustomercareClick = () => {
    // this.setState({
    //   loadingProgress: true
    // });
    // const { getOutletData } = this.props;
    // getOutletData();
    const { history } = this.props;
    history.push(_routes.FEEDBACK);

    // const { history } = this.props;
    // history.push(_routes.CUSTOMER_CARE);
  };
  // CareersClick = () => {
  //   const { history } = this.props;
  //   history.push(_routes.CAREERS);
  // };

  // contactUsClick = () => {
  //   // this.featureAlert();
  // }

  render() {
    const {
      creatingFuture,
      trustTaste,
      covid19,
      freshTaste,
      Investors,
      termsClick,
      privacyClick,
      pprivacyClick,
      helpSupportClick,
      aboutBKClick,
      onFranchiseClick,
      onCustomercareClick
    } = this;
    const { nutritionUrl, showPopUp, loadingProgress } = this.state;
    return (
      <>
        <center>
          <ul className="footer__copyright1">
            <li>
              <b>Disclaimer :</b> All Images used are illustrative and strictly
              for representational purposes only
            </li>
          </ul>
        </center>
        <div className="footer_wrapper footer_zoom">
          <div className="footer">
            <div className="footer__top">
              <div className="footer__section">
                <div className="footer__section-title">
                  BK<sup>&reg; </sup>info
                </div>
                <ul className="footer__list u_cursor_pointer">
                  <li
                    className="footer__item"
                    onClick={aboutBKClick}
                    role="presentation"
                  >
                    {' '}
                    About BK<sup>&reg; </sup>{' '}
                  </li>
                  <li
                    className="footer__item"
                    onClick={freshTaste}
                    role="presentation"
                  >
                    Fresh Taste{' '}
                  </li>
                  <li
                    className="footer__item"
                    onClick={Investors}
                    role="presentation"
                  >
                    Investor Relations
                  </li>
                </ul>
              </div>

              <div className="footer__section">
                <div className="footer__section-title">Contact</div>
                <ul className="footer__list u_cursor_pointer">
                  <li
                    className="footer__item"
                    onClick={helpSupportClick}
                    role="presentation"
                  >
                    {' '}
                    FAQ's & Support{' '}
                  </li>
                  {/* <li className="footer__item" onClick={contactUsClick} role="presentation"> Write to us </li> */}
                  <li className="footer__item"> Write to us : </li>
                  <li>
                    <a
                      className="footer__item-key u_text_decoration"
                      href="mailto:careers@burgerking.in"
                      rel="noopener noreferrer"
                      // onClick={this.CareersClick}
                    >
                      Careers
                    </a>
                  </li>
                  {/* <li>
                  <a
                    className="footer__item-key u_text_decoration"
                    href="mailto:vendorhelpdesk@burgerking.in"
                    rel="noopener noreferrer"
                  >
                    {' '}
                    Vendors{' '}
                  </a>
                </li> */}
                  <li>
                    {/* <a
                      className="footer__item-key u_text_decoration"
                      href="mailto:customercare@burgerking.in"
                      rel="noopener noreferrer"
                    > */}
                    <p
                      className="footer__item-key u_text_decoration"
                      onClick={onCustomercareClick}
                    >
                      Customer Care
                      {/* </a> */}
                    </p>
                  </li>
                  <li>
                    <a
                      className="footer__item-key u_text_decoration"
                      href="mailto:scmsupport@burgerking.in"
                      rel="noopener noreferrer"
                    >
                      {' '}
                      Supply Chain Queries{' '}
                    </a>
                  </li>
                  <li>
                    <a
                      className="footer__item-key u_text_decoration"
                      href="mailto:investor@burgerking.in"
                      rel="noopener noreferrer"
                    >
                      {' '}
                      Investor Relations{' '}
                    </a>
                  </li>
                  {/* <li>
                    <a
                      className="footer__item-key u_text_decoration"
                      href="mailto:corporatecommunications@burgerking.in"
                      rel="noopener noreferrer"
                    >
                      {' '}
                      Corporate Communications{' '}
                    </a>
                  </li> */}
                  <li>
                    <p
                      className="footer__item-key u_text_decoration"
                      onClick={onFranchiseClick}
                    >
                      {' '}
                      Franchising{' '}
                    </p>
                  </li>
                </ul>
              </div>

              <div className="footer__section">
                <div className="footer__section-title">
                  BK<sup>&reg; </sup>Cares
                </div>
                <ul className="footer__list u_cursor_pointer">
                  <li>
                    {' '}
                    <a
                      href={nutritionUrl}
                      rel="noopener noreferrer"
                      target="_blank"
                      className="u_text_decoration footer__item"
                    >
                      Nutrition Information{' '}
                    </a>
                  </li>
                  <li
                    className="footer__item"
                    onClick={creatingFuture}
                    role="presentation"
                  >
                    {' '}
                    Creating Brighter Futures
                  </li>
                  <li
                    className="footer__item"
                    onClick={trustTaste}
                    role="presentation"
                  >
                    {' '}
                    Trust & Taste{' '}
                  </li>
                  {/* <li className="footer__item" onClick={farmToFork} role="presentation"> Farm to Fork </li> */}
                  <li
                    className="footer__item"
                    onClick={covid19}
                    role="presentation"
                  >
                    {' '}
                    COVID-19 Safety{' '}
                  </li>
                </ul>
              </div>

              <div className="footer__section">
                <div className="footer__section-title">Legal</div>
                <ul className="footer__list u_cursor_pointer">
                  <li
                    className="footer__item"
                    onClick={termsClick}
                    role="presentation"
                  >
                    {' '}
                    Terms & Conditions{' '}
                  </li>
                  <li
                    className="footer__item"
                    onClick={privacyClick}
                    role="presentation"
                  >
                    {' '}
                    Privacy Policy{' '}
                  </li>
                  <li
                    className="footer__item"
                    onClick={pprivacyClick}
                    role="presentation"
                  >
                    {' '}
                    Promotional T & C{' '}
                  </li>
                </ul>
              </div>

              <div className="footer__section">
                <div className="footer__section-title">Download our app</div>
                <ul className="footer__list u_display_flex">
                  <li className="footer__list-item u_cursor_pointer">
                    <a
                      href="https://apps.apple.com/us/app/burger-king-india/id1516627543"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={AppleStoreImage}
                        alt="Apple store"
                        width="128"
                        height="38"
                        onClick={this.imgOnClickHandler}
                        role="presentation"
                      />
                    </a>
                  </li>
                  <li className="u_cursor_pointer">
                    <a
                      href="https://play.google.com/store/apps/details?id=in.burgerking.android"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {' '}
                      <img
                        src={GooglePlayStoreImage}
                        alt="play store"
                        width="128"
                        height="38"
                        onClick={this.imgOnClickHandler}
                        role="presentation"
                      />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <hr className="footer__divider" />
            <div className="footer__bottom">
              <div className="footer__logo u_cursor_pointer">
                <img
                  src={newFooterLogo}
                  alt="footer logo"
                  width="50"
                  height="50"
                  onClick={this.imgOnClickHandler}
                  role="presentation"
                />
              </div>
              <p className="footer__copyright">
                TM & © {new Date().getFullYear()} BURGER KING COMPANY LLC . All
                Rights Reserved.
              </p>
              <ul className="footer__socialmedia u_cursor_pointer">
                <li className="u_mar_2rem_lt">
                  <a
                    href="https://www.facebook.com/burgerkingindia/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={FacebookLogo}
                      alt="facebook"
                      onClick={this.imgOnClickHandler}
                      role="presentation"
                    />
                  </a>
                </li>
                <li className="u_mar_2rem_lt">
                  <a
                    href="https://www.instagram.com/burgerkingindia/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={InstagramLogo}
                      alt="instagram"
                      onClick={this.imgOnClickHandler}
                      role="presentation"
                    />
                  </a>
                </li>
                <li className="u_mar_2rem_lt">
                  <a
                    href="https://twitter.com/burgerkingindia"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={TwitterLogo}
                      alt="twitter"
                      onClick={this.imgOnClickHandler}
                      role="presentation"
                    />
                  </a>
                </li>
                <li className="u_mar_2rem_lt">
                  <a
                    href="https://www.youtube.com/user/BurgerKingIndia"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={YoutubeLogo}
                      alt="youtube"
                      onClick={this.imgOnClickHandler}
                      role="presentation"
                    />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    nutritionData: state.home
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getNutritionData: payloadData => dispatch(getNutrition(payloadData))
    // getOutletData: payloadData => dispatch(getOutletData(payloadData))
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Footer));

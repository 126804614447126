export const GET_HOME_API_LIST = 'GET_HOME_API_LIST';
export const GET_HOME_API_LIST_SUCCESS = 'GET_HOME_API_LIST_SUCCESS';
export const GET_HOME_API_LIST_ERROR = 'GET_HOME_API_LIST_ERROR';

export const GET_MENU_LIST = 'GET_MENU_LIST';
export const GET_MENU_LIST_SUCCESS = 'GET_MENU_LIST_SUCCESS';
export const GET_MENU_LIST_ERROR = 'GET_MENU_LIST_ERROR';

//
export const RESET_FREEBIE = 'RESET_FREEBIE';

//GET_LEGAL;
export const GET_LEGAL = 'GET_LEGAL';
export const GET_LEGAL_SUCCESS = 'GET_LEGAL_SUCCESS';
export const GET_LEGAL_ERROR = 'GET_LEGAL_ERROR';

export const GET_SIGNUP = 'GET_SIGNUP';
export const GET_SIGNUP_SUCCESS = 'GET_SIGNUP_SUCCESS';
export const GET_SIGNUP_ERROR = 'GET_SIGNUP_ERROR';

export const GET_VERIFY_OTP = 'GET_VERIFY_OTP';
export const GET_VERIFY_OTP_SUCCESS = 'GET_VERIFY_OTP_SUCCESS';
export const GET_VERIFY_OTP_ERROR = 'GET_VERIFY_OTP_ERROR';

export const ADD_ADDRESS = 'ADD_ADDRESS';
export const ADD_ADDRESS_SUCCESS = 'ADD_ADDRESS_SUCCESS';
export const ADD_ADDRESS_ERROR = 'ADD_ADDRESS_ERROR';

export const GET_PRODUCT_LIST = 'GET_PRODUCT_LIST';
export const GET_PRODUCT_LIST_SUCCESS = 'GET_PRODUCT_LIST_SUCCESS';
export const GET_PRODUCT_LIST_ERROR = 'GET_PRODUCT_LIST_ERROR';

export const GET_PRODUCT_LIST2 = 'GET_PRODUCT_LIST2';
export const GET_PRODUCT_LIST2_SUCCESS = 'GET_PRODUCT_LIST2_SUCCESS';
export const GET_PRODUCT_LIST2_ERROR = 'GET_PRODUCT_LIST2_ERROR';

export const GET_SEARCH_PRODUCT_LIST = 'GET_SEARCH_PRODUCT_LIST';
export const GET_SEARCH_PRODUCT_LIST_SUCCESS =
  'GET_SEARCH_PRODUCT_LIST_SUCCESS';
export const GET_SEARCH_PRODUCT_LIST_ERROR = 'GET_SEARCH_PRODUCT_LIST_ERROR';

export const ADD_TO_REDIS_CART = 'ADD_TO_REDIS_CART';
export const ADD_TO_REDIS_CART_SUCCESS = 'ADD_TO_REDIS_CART_SUCCESS';
export const ADD_TO_REDIS_CART_ERROR = 'ADD_TO_REDIS_CART_ERROR';

export const OFFER_ADD_TO_CART = 'OFFER_ADD_TO_CART';
export const OFFER_ADD_TO_CART_SUCCESS = 'OFFER_ADD_TO_CART_SUCCESS';
export const OFFER_ADD_TO_CART_ERROR = 'OFFER_ADD_TO_CART_ERROR';

export const SKD_OFFER_ADD_TO_CART = 'SKD_OFFER_ADD_TO_CART';
export const SKD_OFFER_ADD_TO_CART_SUCCESS = 'SKD_OFFER_ADD_TO_CART_SUCCESS';
export const SKD_OFFER_ADD_TO_CART_ERROR = 'SKD_OFFER_ADD_TO_CART_ERROR';

export const GET_CART_ITEM = 'GET_CART_ITEM';
export const GET_CART_ITEM_SUCCESS = 'GET_CART_ITEM_SUCCESS';
export const GET_CART_ITEM_ERROR = 'GET_CART_ITEM_ERROR';

// export const GET_REDIS_CART_ITEM = 'GET_REDIS_CART_ITEM';
// export const GET_REDIS_CART_ITEM_SUCCESS = 'GET_REDIS_CART_ITEM_SUCCESS';
// export const GET_REDIS_CART_ITEM_ERROR = 'GET_REDIS_CART_ITEM_ERROR';

export const CART_GET_CART_ITEM = 'CART_GET_CART_ITEM';
export const CART_GET_CART_ITEM_SUCCESS = 'CART_GET_CART_ITEM_SUCCESS';
export const CART_GET_CART_ITEM_ERROR = 'CART_GET_CART_ITEM_ERROR';

export const ADD_REMOVE_CART_ITEM = 'ADD_REMOVE_CART_ITEM';
export const ADD_REMOVE_CART_ITEM_SUCCESS = 'ADD_REMOVE_CART_ITEM_SUCCESS';
export const ADD_REMOVE_CART_ITEM_ERROR = 'ADD_REMOVE_CART_ITEM_ERROR';

//new added controllers
export const GET_CONTROLLERS = 'GET_CONTROLLERS';
export const GET_CONTROLLERS_SUCCESS = 'GET_CONTROLLERS_SUCCESS';
export const GET_CONTROLLERS_ERROR = 'GET_CONTROLLERS_ERROR';

export const GET_ADDRESS_LIST = 'GET_ADDRESS_LIST';
export const GET_ADDRESS_LIST_SUCCESS = 'GET_ADDRESS_LIST_SUCCESS';
export const GET_ADDRESS_LIST_ERROR = 'GET_ADDRESS_LIST_ERROR';

export const GET_UPDATE_USER = 'GET_UPDATE_USER';
export const GET_UPDATE_USER_SUCCESS = 'GET_UPDATE_USER_SUCCESS';
export const GET_UPDATE_USER_ERROR = 'GET_UPDATE_USER_ERROR';

export const GET_CLEAR_CART = 'GET_CLEAR_CART';
export const GET_CLEAR_CART_SUCCESS = 'GET_CLEAR_CART_SUCCESS';
export const GET_CLEAR_CART_ERROR = 'GET_CLEAR_CART_ERROR';

export const GET_PRODUCT_FROM_CART = 'GET_PRODUCT_FROM_CART';
export const GET_PRODUCT_FROM_CART_SUCCESS = 'GET_PRODUCT_FROM_CART_SUCCESS';
export const GET_PRODUCT_FROM_CART_ERROR = 'GET_PRODUCT_FROM_CART_ERROR';

export const CHECKOUT_CART = 'CHECKOUT_CART';
export const CHECKOUT_CART_SUCCESS = 'CHECKOUT_CART_SUCCESS';
export const CHECKOUT_CART_ERROR = 'CHECKOUT_CART_ERROR';

export const CHECKOUT_CART_APP = 'CHECKOUT_CART_APP';
export const CHECKOUT_CART_APP_SUCCESS = 'CHECKOUT_CART_APP_SUCCESS';
export const CHECKOUT_CART_APP_ERROR = 'CHECKOUT_CART_APP_ERROR';

export const ORDER_HISTORY = 'ORDER_HISTORY';
export const ORDER_HISTORY_SUCCESS = 'ORDER_HISTORY_SUCCESS';
export const ORDER_HISTORY_ERROR = 'ORDER_HISTORY_ERROR';
// new
export const ORDER_DETAILS = 'ORDER_DETAILS';
export const ORDER_DETAILS_SUCCESS = 'ORDER_DETAILS_SUCCESS';
export const ORDER_DETAILS_ERROR = 'ORDER_DETAILS_ERROR';
// newly added
export const FEEDBACK_STATUS = 'FEEDBACK_STATUS';
export const FEEDBACK_STATUS_SUCCESS = 'FEEDBACK_STATUS_SUCCESS';
export const FEEDBACK_STATUS_ERROR = 'FEEDBACK_STATUS_ERROR';
// newly added
export const TICKET_STATUS = 'TICKET_STATUS';
export const TICKET_STATUS_SUCCESS = 'TICKET_STATUS_SUCCESS';
export const TICKET_STATUS_ERROR = 'TICKET_STATUS_ERROR';
// newly added
export const IS_VIEWED_UPDATE_API = 'IS_VIEWED_UPDATE_API';
export const IS_VIEWED_UPDATE_API_SUCCESS = 'IS_VIEWED_UPDATE_API_SUCCESS';
export const IS_VIEWED_UPDATE_API_ERROR = 'IS_VIEWED_UPDATE_API_ERROR';
// newly added
export const FEEDBACK_API_V2 = 'FEEDBACK_API_V2';
export const FEEDBACK_API_V2_SUCCESS = 'FEEDBACK_API_V2_SUCCESS';
export const FEEDBACK_API_V2_ERROR = 'FEEDBACK_API_V2_ERROR';
// newly added
export const UPDATE_FEEDBACK_API = 'UPDATE_FEEDBACK_API';
export const UPDATE_FEEDBACK_API_SUCCESS = 'UPDATE_FEEDBACK_API_SUCCESS';
export const UPDATE_FEEDBACK_API_ERROR = 'UPDATE_FEEDBACK_API_ERROR';

export const DELETE_ADDRESS = 'DELETE_ADDRESS';
export const DELETE_ADDRESS_SUCCESS = 'DELETE_ADDRESS_SUCCESS';
export const DELETE_ADDRESS_ERROR = 'DELETE_ADDRESS_ERROR';

export const GET_SEARCH_SUGGESTIONS = 'GET_SEARCH_SUGGESTIONS';
export const GET_SEARCH_SUGGESTIONS_SUCCESS = 'GET_SEARCH_SUGGESTIONS_SUCCESS';
export const GET_SEARCH_SUGGESTIONS_ERROR = 'GET_SEARCH_SUGGESTIONS_ERROR';

export const GET_OUTLET_LIST = 'GET_OUTLET_LIST';
export const GET_OUTLET_LIST_SUCCESS = 'GET_OUTLET_LIST_SUCCESS';
export const GET_OUTLET_LIST_ERROR = 'GET_OUTLET_LIST_ERROR';

export const GET_OUTLET_CHANGE = 'GET_OUTLET_CHANGE';
export const GET_OUTLET_CHANGE_SUCCESS = 'GET_OUTLET_CHANGE_SUCCESS';
export const GET_OUTLET_CHANGE_ERROR = 'GET_OUTLET_CHANGE_ERROR';

export const GET_EDIT_USER = 'GET_EDIT_USER';
export const GET_EDIT_USER_SUCCESS = 'GET_EDIT_USER_SUCCESS';
export const GET_EDIT_USER_ERROR = 'GET_EDIT_USER_ERROR';

export const GET_USER_DETAIL = 'GET_USER_DETAIL';
export const GET_USER_DETAIL_SUCCESS = 'GET_USER_DETAIL_SUCCESS';
export const GET_USER_DETAIL_ERROR = 'GET_USER_DETAIL_ERROR';

export const UPLOAD_PROFILE_PIC = 'UPLOAD_PROFILE_PIC';
export const UPLOAD_PROFILE_PIC_SUCCESS = 'UPLOAD_PROFILE_PIC_SUCCESS';
export const UPLOAD_PROFILE_PIC_ERROR = 'UPLOAD_PROFILE_PIC_ERROR';

export const GET_ADDONS_LIST = 'GET_ADDONS_LIST';
export const GET_ADDONS_LIST_SUCCESS = 'GET_ADDONS_LIST_SUCCESS';
export const GET_ADDONS_LIST_ERROR = 'GET_ADDONS_LIST_ERROR';

// export const GET_PRODUCT_ADDON_DETAIL = 'GET_PRODUCT_ADDON_DETAIL';
// export const GET_PRODUCT_ADDON_DETAIL_SUCCESS =
//   'GET_PRODUCT_ADDON_DETAIL_SUCCESS';
// export const GET_PRODUCT_ADDON_DETAIL_ERROR = 'GET_PRODUCT_ADDON_DETAIL_ERROR';

export const GET_MENU_CATEGORY = 'GET_MENU_CATEGORY';
export const GET_MENU_CATEGORY_SUCCESS = 'GET_MENU_CATEGORY_SUCCESS';
export const GET_MENU_CATEGORY_ERROR = 'GET_MENU_CATEGORY_ERROR';

export const GET_OFFERS = 'GET_OFFERS';
export const GET_OFFERS_SUCCESS = 'GET_OFFERS_SUCCESS';
export const GET_OFFERS_ERROR = 'GET_OFFERS_ERROR';

export const GET_USER_AVAILABLE_POINTS = 'GET_USER_AVAILABLE_POINTS';
export const GET_USER_AVAILABLE_POINTS_SUCCESS =
  'GET_USER_AVAILABLE_POINTS_SUCCESS';
export const GET_USER_AVAILABLE_POINTS_ERROR =
  'GET_USER_AVAILABLE_POINTS_ERROR';

export const GET_COUPONS = 'GET_COUPONS';
export const GET_COUPONS_SUCCESS = 'GET_COUPONS_SUCCESS';
export const GET_COUPONS_ERROR = 'GET_COUPONS_ERROR';

export const GET_USER_COUPONS = 'GET_USER_COUPONS';
export const GET_USER_COUPONS_SUCCESS = 'GET_USER_COUPONS_SUCCESS';
export const GET_USER_COUPONS_ERROR = 'GET_USER_COUPONS_ERROR';

export const GET_PAYMENT_STATUS = 'GET_PAYMENT_STATUS';
export const GET_PAYMENT_STATUS_SUCCESS = 'GET_PAYMENT_STATUS_SUCCESS';
export const GET_PAYMENT_STATUS_ERROR = 'GET_PAYMENT_STATUS_ERROR';

export const GET_APP_PAYMENT_STATUS = 'GET_APP_PAYMENT_STATUS';
export const GET_APP_PAYMENT_STATUS_SUCCESS = 'GET_APP_PAYMENT_STATUS_SUCCESS';
export const GET_APP_PAYMENT_STATUS_ERROR = 'GET_APP_PAYMENT_STATUS_ERROR';

export const GET_APPLY_COUPON = 'GET_APPLY_COUPON';
export const GET_APPLY_COUPON_SUCCESS = 'GET_APPLY_COUPON_SUCCESS';
export const GET_APPLY_COUPON_ERROR = 'GET_APPLY_COUPON_ERROR';

export const GET_REMOVE_COUPON = 'GET_REMOVE_COUPON';
export const GET_REMOVE_COUPON_SUCCESS = 'GET_REMOVE_COUPON_SUCCESS';
export const GET_REMOVE_COUPON_ERROR = 'GET_REMOVE_COUPON_ERROR';

export const GET_FREE_CROWN = 'GET_FREE_CROWN';
export const GET_FREE_CROWN_SUCCESS = 'GET_FREE_CROWN_SUCCESS';
export const GET_FREE_CROWN_ERROR = 'GET_FREE_CROWN_ERROR';

export const GET_ALL_ADDONS_LOADER = 'GET_ALL_ADDONS_LOADER';

export const GET_ALL_ADDONS = 'GET_ALL_ADDONS';
export const GET_ALL_ADDONS_SUCCESS = 'GET_ALL_ADDONS_SUCCESS';
export const GET_ALL_ADDONS_ERROR = 'GET_ALL_ADDONS_ERROR';

export const GET_VERSION_CONTROL = 'GET_VERSION_CONTROL';
export const GET_VERSION_CONTROL_SUCCESS = 'GET_VERSION_CONTROL_SUCCESS';
export const GET_VERSION_CONTROL_ERROR = 'GET_VERSION_CONTROL_ERROR';

export const GET_LIMITED_DEALS = 'GET_LIMITED_DEALS';
export const GET_LIMITED_DEALS_SUCCESS = 'GET_LIMITED_DEALS_SUCCESS';
export const GET_LIMITED_DEALS_ERROR = 'GET_LIMITED_DEALS_ERROR';

export const GET_TOP_DEALS = 'GET_TOP_DEALS';
export const GET_TOP_DEALS_SUCCESS = 'GET_TOP_DEALS_SUCCESS';
export const GET_TOP_DEALS_ERROR = 'GET_TOP_DEALS_ERROR';

export const GET_DAILY_REWARDS = 'GET_DAILY_REWARDS';
export const GET_DAILY_REWARDS_SUCCESS = 'GET_DAILY_REWARDS_SUCCESS';
export const GET_DAILY_REWARDS_ERROR = 'GET_DAILY_REWARDS_ERROR';

export const GET_TOKEN = 'GET_TOKEN';
export const GET_TOKEN_SUCCESS = 'GET_TOKEN_SUCCESS';
export const GET_TOKEN_ERROR = 'GET_TOKEN_ERROR';

export const GET_CROWN_HISTORY = 'GET_CROWN_HISTORY';
export const GET_CROWN_HISTORY_SUCCESS = 'GET_CROWN_HISTORY_SUCCESS';
export const GET_CROWN_HISTORY_ERROR = 'GET_CROWN_HISTORY_ERROR';

export const GET_CHARITY_PRODUCT = 'GET_CHARITY_PRODUCT';
export const GET_GET_CHARITY_PRODUCT_SUCCESS =
  'GET_GET_CHARITY_PRODUCT_SUCCESS';
export const GET_GET_CHARITY_PRODUCT_ERROR = 'GET_GET_CHARITY_PRODUCT_ERROR';

export const CART_ADD_TO_CART = 'CART_ADD_TO_CART';
export const CART_ADD_TO_CART_SUCCESS = 'CART_ADD_TO_CART_SUCCESS';
export const CART_ADD_TO_CART_ERROR = 'CART_ADD_TO_CART_ERROR';

export const PDP_ADD_TO_CART = 'PDP_ADD_TO_CART';
export const PDP_ADD_TO_CART_SUCCESS = 'PDP_ADD_TO_CART_SUCCESS';
export const PDP_ADD_TO_CART_ERROR = 'PDP_ADD_TO_CART_ERROR';

export const PLP_ADD_TO_CART = 'PLP_ADD_TO_CART';
export const PLP_ADD_TO_CART_SUCCESS = 'PLP_ADD_TO_CART_SUCCESS';
export const PLP_ADD_TO_CART_ERROR = 'PLP_ADD_TO_CART_ERROR';

export const GET_FEEDBACK = 'GET_FEEDBACK';
export const GET_FEEDBACK_SUCCESS = 'GET_FEEDBACK_SUCCESS';
export const GET_FEEDBACK_ERROR = 'GET_FEEDBACK_ERROR';

export const RESEND_OFFER_OTP = 'RESEND_OFFER_OTP';
export const RESEND_OFFER_OTP_SUCCESS = 'RESEND_OFFER_OTP_SUCCESS';
export const RESEND_OFFER_OTP_ERROR = 'RESEND_OFFER_OTP_ERROR';

export const VERIFY_OFFER_OTP = 'VERIFY_OFFER_OTP';
export const VERIFY_OFFER_OTP_SUCCESS = 'VERIFY_OFFER_OTP_SUCCESS';
export const VERIFY_OFFER_OTP_ERROR = 'VERIFY_OFFER_OTP_ERROR';

export const GET_CATEGORY = 'GET_CATEGORY';
export const GET_CATEGORY_SUCCESS = 'GET_CATEGORY_SUCCESS';
export const GET_CATEGORY_ERROR = 'GET_CATEGORY_ERROR';

// Newly added types for investortreedata
export const GET_INVESTORTREEDATA = 'GET_INVESTORTREEDATA';
export const GET_INVESTORTREEDATA_SUCCESS = 'GET_INVESTORTREEDATA_SUCCESS';
export const GET_INVESTORTREEDATA_ERROR = 'GET_INVESTORTREEDATA_ERROR';

// // Newly added types for investor tree category
// export const UPDATE_CHARITY = 'UPDATE_CHARITY';
export const GET_OUTLET_CHANGE1 = 'GET_OUTLET_CHANGE1';
// export const GET_INVESTOR_CATEGORY_SUCCESS = 'GET_INVESTOR_CATEGORY_SUCCESS';
// export const GET_INVESTOR_CATEGORY_ERROR = 'GET_INVESTOR_CATEGORY_ERROR';

export const GET_SUBCATEGORY = 'GET_SUBCATEGORY';
export const GET_SUBCATEGORY_SUCCESS = 'GET_SUBCATEGORY_SUCCESS';
export const GET_SUBCATEGORY_ERROR = 'GET_SUBCATEGORY_ERROR';

export const GET_BKWALL_DATA = 'GET_BKWALL_DATA';
export const GET_BKWALL_DATA_SUCCESS = 'GET_BKWALL_DATA_SUCCESS';
export const GET_BKWALL_DATA_ERROR = 'GET_BKWALL_DATA_ERROR';

export const GET_NUTRITIONAL = 'GET_NUTRITIONAL';
export const GET_NUTRITIONAL_SUCCESS = 'GET_NUTRITIONAL_SUCCESS';
export const GET_NUTRITIONAL_ERROR = 'GET_NUTRITIONAL_ERROR';

export const GET_OUTLETDATA = 'GET_OUTLETDATA';
export const GET_OUTLETDATA_SUCCESS = 'GET_OUTLETDATA_SUCCESS';
export const GET_OUTLETDATA_ERROR = 'GET_OUTLETDATA_ERROR';

export const SIGNUP_WITH_EMAIL = 'SIGNUP_WITH_EMAIL';
export const SIGNUP_WITH_EMAIL_SUCCESS = 'SIGNUP_WITH_EMAIL_SUCCESS';
export const SIGNUP_WITH_EMAIL_ERROR = 'SIGNUP_WITH_EMAIL_ERROR';

export const GENERATE_PHONE_OTP = 'GENERATE_PHONE_OTP';
export const GENERATE_PHONE_OTP_SUCCESS = 'GENERATE_PHONE_OTP_SUCCESS';
export const GENERATE_PHONE_OTP_ERROR = 'GENERATE_PHONE_OTP_ERROR';

export const CANCEL_ORDER = 'CANCEL_ORDER';
export const CANCEL_ORDER_SUCCESS = 'CANCEL_ORDER_SUCCESS';
export const CANCEL_ORDER_ERROR = 'CANCEL_ORDER_ERROR';

export const GET_CART_OFFERS = 'GET_CART_OFFERS';
export const GET_CART_OFFERS_SUCCESS = 'GET_CART_OFFERS_SUCCESS';
export const GET_CART_OFFERS_ERROR = 'GET_CART_OFFERS_ERROR';

// GET_REDIS_CART
export const GET_REDIS_CART = 'GET_REDIS_CART';
export const GET_REDIS_CART_SUCCESS = 'GET_REDIS_CART_SUCCESS';
export const GET_REDIS_CART_ERROR = 'GET_REDIS_CART_ERROR';

// POST_REDIS_CART_SUCCESS
export const POST_REDIS_CART = 'POST_REDIS_CART';
export const POST_REDIS_CART_SUCCESS = 'POST_REDIS_CART_SUCCESS';
export const POST_REDIS_CART_ERROR = 'POST_REDIS_CART_ERROR';
// rata
export const ALTER_REDIS_CART = 'ALTER_REDIS_CART';
export const ALTER_REDIS_CART_SUCCESS = 'ALTER_REDIS_CART_SUCCESS';
export const ALTER_REDIS_CART_ERROR = 'ALTER_REDIS_CART_ERROR';
// APPLY_REDIS_COUPON
export const APPLY_REDIS_COUPON = 'APPLY_REDIS_COUPON';
export const APPLY_REDIS_COUPON_SUCCESS = 'APPLY_REDIS_COUPON_SUCCESS';
export const APPLY_REDIS_COUPON_ERROR = 'APPLY_REDIS_COUPON_ERROR';
// APPLY_REDIS_COUPON
export const REMOVE_REDIS_COUPON = 'REMOVE_REDIS_COUPON';
export const REMOVE_REDIS_COUPON_SUCCESS = 'REMOVE_REDIS_COUPON_SUCCESS';
export const REMOVE_REDIS_COUPON_ERROR = 'REMOVE_REDIS_COUPON_ERROR';
// CLEAR_REDIS_CART
export const CLEAR_REDIS_CART = 'CLEAR_REDIS_CART';
export const CLEAR_REDIS_CART_SUCCESS = 'CLEAR_REDIS_CART_SUCCESS';
export const CLEAR_REDIS_CART_ERROR = 'CLEAR_REDIS_CART_ERROR';
// COMBINE_REDIS_CART
export const COMBINE_REDIS_CART = 'COMBINE_REDIS_CART';
export const COMBINE_REDIS_CART_SUCCESS = 'COMBINE_REDIS_CART_SUCCESS';
export const COMBINE_REDIS_CART_ERROR = 'COMBINE_REDIS_CART_ERROR';
//EMPLOYEE_VERIFY_COUPON
export const EMPLOYEE_VERIFY_COUPON = 'EMPLOYEE_VERIFY_COUPON';
export const EMPLOYEE_VERIFY_COUPON_SUCCESS = 'EMPLOYEE_VERIFY_COUPON_SUCCESS';
export const EMPLOYEE_VERIFY_COUPON_ERROR = 'EMPLOYEE_VERIFY_COUPON_ERROR';

// REQUESTING ACCOUNT DELETE
export const REQUESTING_ACCOUNT_DELETE = 'REQUESTING_ACCOUNT_DELETE';
export const REQUESTING_ACCOUNT_DELETE_SUCCESS =
  'REQUESTING_ACCOUNT_DELETE_SUCCESS';
export const REQUESTING_ACCOUNT_DELETE_ERROR =
  'REQUESTING_ACCOUNT_DELETE_ERROR';

//ORGANISATION LIST
export const GET_ORGANIZATION_LIST = 'GET_ORGANISATION_LIST';
export const GET_ORGANIZATION_LIST_SUCCESS = 'GET_ORGANISATION_LIST_SUCCESS';
export const GET_ORGANIZATION_LIST_ERROR = 'GET_ORGANISATION_LIST_ERROR';

//onboarding
export const CREATE_CLUB_OFFER_ONBOARDING = 'CREATE_CLUB_OFFER_ONBOARDING';
export const CREATE_CLUB_OFFER_ONBOARDING_SUCCESS =
  'CREATE_CLUB_OFFER_ONBOARDING_SUCCESS';
export const CREATE_CLUB_OFFER_ONBOARDING_ERROR =
  'CREATE_CLUB_OFFER_ONBOARDING_ERROR';

//JUSPAY PAYMENTS METHOD
export const JUSPAY_PAYMENT_METHOD_STATUS = 'JUSPAY_PAYMENT_METHOD_STATUS';
export const JUSPAY_PAYMENT_METHOD_STATUS_SUCCESS =
  'JUSPAY_PAYMENT_METHOD_STATUS_SUCCESS';
export const JUSPAY_PAYMENT_METHOD_STATUS_ERROR =
  'JUSPAY_PAYMENT_METHOD_STATUS_ERROR';

import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import moment from 'moment';
import { getCrownHistory } from '../../burger-king-lib/redux/offers/actions';
import {
  LocalStorage,
} from '../../burger-king-lib/utils/storage.web';
import { LocalSessionKey } from '../../burger-king-lib/constants/app-constants';
import Loader from '../../components/loader-spin';
import { getCrownHistoryPayload } from '../../payload-handling/offers';
import crownImage from '../../../assets/images/crown-history/Top-crown.png';
import TitleDescription from '../../components/title-description';
import HistoryTable from '../../components/crown-history-table';
import AccountSubSecTitle from '../../components/accout-subsec-title';
import './index.scss';
import Cancelpop from '../../components/cancel-popover';

class CrownHistory extends Component {
  constructor() {
    super();
    this.state = {
      isMobileView: this.isMobileView(),
      activeTitle: 'BK Crown Balance',
      filterList: {
        id: 'filterList',
        title: { value: 'Last month', id: 1 },
        values: [
          { value: 'Last month', id: 1 },
          { value: 'Last 3 month', id: 3 },
          { value: 'Last 6 month', id: 6 },
          { value: 'Last 12 month', id: 12 }
        ]
      },
      crownHistoryData: [],
      showLoader: true,
      offerApply: false
    };
  }

  componentDidMount = () => {
    const { location } = this.props;
    if (location.search.length) {
      const isLoggedIn = LocalStorage.get(LocalSessionKey.USER_DATA) || '';
      if (!Boolean(isLoggedIn)) {
        this.setState({
          offerApply: true
        });
      }
    }
    this.getCrownHistoryApi();
  };

  componentDidUpdate(prevProps) {
    const { offersData } = this.props;
    if (
      offersData &&
      offersData.crownHistory &&
      offersData.crownHistory !== prevProps.offersData.crownHistory
    ) {
      if (offersData.crownHistory.message) {
        this.hideLoader();
      }
      if (offersData.crownHistory.content) {
        this.setCrownHistoryData(offersData.crownHistory.content);
      }
      if (offersData.crownHistory.error === 1) this.setCrownHistoryData([]);
    }
  }

  getOfferData = () => {
    setTimeout(() => {
      this.setState({ offerApply: false });
      this.props.history.push('/');
    }, 2000);
    return (
      <div className="offerlisting_offer">
        <p className="offerlisting_offer--text">Alert</p>
        {/* <h3 className="offerlisting_offer--heading">
          Kindly Login to continue
        </h3> */}
        <p className="offerlisting_offer--text">Kindly Login to continue.</p>
        <p className="offerlisting_offer--notify"> Login and Re-try</p>
      </div>
    );
  };

  setCrownHistoryData = data => {
    this.setState({ crownHistoryData: data });
  };

  hideLoader = () => {
    this.setState({ showLoader: false });
  };

  goBackOnClick = () => {
    const { history } = this.props;
    history.go(-1);
  };

  getCrownHistoryApi = start_date => {
    const { getCrownHistoryApiData } = this.props;
    const phone_no = `${LocalStorage.get(LocalSessionKey.USER_DATA)}`;
    let startdate = '';
    if (!start_date) {
      startdate = moment()
        .subtract(1, 'month')
        .format('DD MMM YYYY');
    } else {
      startdate = start_date;
    }
    const end_date = moment().format('DD MMM YYYY');
    const payload = getCrownHistoryPayload(phone_no, startdate, end_date);
    getCrownHistoryApiData(payload);
  };

  isMobileView = () => {
    if (window.innerWidth > 1023) {
      return false;
    }
    return true;
  };

  onDropdownSelect = (id, value) => {
    const { filterList } = this.state;
    if (filterList.id === id) {
      filterList.title = value;
    }
    this.setState({ filterList });
    const startdate = moment()
      .subtract(value.id, 'months')
      .format('DD MMM YYYY');
    this.getCrownHistoryApi(startdate);
  };

  renderContent = () => {
    const { showLoader, crownHistoryData, filterList } = this.state;
    const { offersData } = this.props;
    // console.log({ offersData });
    const { getUserAvailablePoints } = offersData;
    // console.log({ getUserAvailablePoints });
    // const { content } = getUserAvailablePoints;
    // console.log(this.props, 'ppoop   sss ', this.state);
    // const earnedCrowns = SessionStorage.get('TotalCrowns');
    // const { content.available_points:earned } = getUserAvailablePoints;
    const earned = getUserAvailablePoints?.content?.available_points;
    const { theme } = this.props;
    let dom = '';
    if (showLoader) {
      dom = <Loader fitContent="full" theme={theme} />;
    } else {
      dom = (
        <>
          <div className="crown-history__bg">
            <div className={`crown-history__banner ${theme}`}>
              <div className="crown-history__crown">
                <div className="crown-history__crown-image">
                  <img
                    src={crownImage}
                    alt="crown"
                    className="crown-history__crown-img"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="crown-history__content">
            <TitleDescription
              title={`${earned} BK CROWNS`}
              description=""
              customClass="crown-history__content-title"
              theme={theme}
            />
            <div className="crown-history__table">
              <HistoryTable
                title="History"
                content={filterList}
                customClass="crown-history__table-dropdown"
                theme={theme}
                data={crownHistoryData}
                onSelectedDropdown={this.onDropdownSelect}
              />
            </div>
          </div>
        </>
      );
    }
    return dom;
  };

  render() {
    const { theme } = this.props;
    const { activeTitle, isMobileView, offerApply } = this.state;
    const { goBackOnClick } = this;
    return (
      <>
        {offerApply && (
          <Cancelpop content children={this.getOfferData()} theme={theme} />
        )}
        <div className={`crown-history ${theme}`}>
          {isMobileView && (
            <AccountSubSecTitle
              title={activeTitle}
              goBackOnClick={goBackOnClick}
              showDesGoBack={activeTitle !== 'BK Crown Balance'}
              theme={theme}
            />
          )}
          <div className="crown-history">{this.renderContent()}</div>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    offersData: state.offers
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getCrownHistoryApiData: payloadData =>
      dispatch(getCrownHistory(payloadData))
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CrownHistory)
);

import './index.scss';
import HelpCall from '../help-call';
import React, { Component } from 'react';

import Button from '../button';
import { getTopArrowImage } from '../../utils/imageChanges';

class HelpCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isExpand: false
    };
  }

  helpClick = () => {
    const { isExpand } = this.state;
    this.setState({ isExpand: !isExpand });
  };

  render() {
    const {
      data,
      theme,
      userData,
      onTermsClick,
      onClickAccountDelete,
      deleteAccountresponse
    } = this.props;
    const { isExpand } = this.state;
    return (
      <>
        <div
          className={`help-card ${isExpand ? 'help-card__isexpand' : ''}`}
          onClick={this.helpClick}
          role="presentation"
        >
          <div className="help-card__details">
            <p className="help-card__title">{data.title}</p>
            <img
              src={getTopArrowImage(theme)}
              alt="top arrow"
              className={`help-card__arrow ${
                isExpand ? 'help-card__arrow-isexpand' : ''
              }`}
            />
          </div>
        </div>
        {isExpand ? (
          <div className="help-card__content">
            <p
              className="help-card__text"
              dangerouslySetInnerHTML={{ __html: data?.description }}
            ></p>
            {data.subDescription && (
              <>
                {data.subDescription ===
                'For Cancellation and Refund refer to Terms & Conditions  - Cancellation and Refund section' ? (
                  <span className="help-card__text">
                    For <b>Cancellation and Refund</b> refer to Terms &
                    Conditions - Cancellation and Refund section
                  </span>
                ) : (
                  <span className="help-card__text">{data.subDescription}</span>
                )}
                {data.link && (
                  <span className="help-card__link" onClick={onTermsClick}>
                    - {data.link}
                  </span>
                )}
              </>
            )}
            {data?.id === 30 && (
              <div>
                <Button
                  className={'request-button'}
                  onClick={onClickAccountDelete}
                  content={'Request for Account Deletion'}
                />
                <p className={'request-response'}>
                  {deleteAccountresponse?.content
                    ? deleteAccountresponse?.content
                    : deleteAccountresponse?.message}
                </p>
              </div>
            )}
            {/* <div className="help-card__call"><HelpCall theme={theme}/></div> */}
          </div>
        ) : (
          ''
        )}
      </>
    );
  }
}

export default HelpCard;

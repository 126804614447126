import './index.scss';

import React from 'react';
import PropTypes from 'prop-types';

import Button from '../button';

import Modal from '../../containers/modal';

const Logout = props => {
  const {theme,onYesClick,onCancelClick} = props;
  return (
    <Modal centerPopUp modalCustomClass="log-out-modal-width" hideClose>
      <div className="log-out">
        <div className="log-out__content">
          <p className="log-out__title">Leaving so soon?</p>
          <p className="log-out__description">
          Are you sure you want to log out of Burger King?
          </p>
          <div className="log-out__button">
            <div
              className="log-out__cancel-btn"
              onClick={onYesClick}
              role="presentation"
            >
              <Button content="Yes" size="is-small" outlined theme={theme} />
            </div>
            <div
              className="log-out__confirm-btn"
              onClick={onCancelClick}
              role="presentation"
            >
              <Button content="cancel" size="is-small" theme={theme} />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

Logout.propTypes = {
  theme: PropTypes.string
};

Logout.defaultProps = {
  theme: ''
};


export default Logout;
import * as ActionTypes from '../actionTypes';

const initialState = {
  postCart: {},
  combineCart: {},
  outletData: {},
  clearRedisCart: {},
  applyRedisCoupon: {},
  loading: false
};

export default function redisCartReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.GET_REDIS_CART: {
      return {
        ...state,
        Payload: action.payload,
        action: action.type,
        Error: null,
        loading: true
      };
    }

    case ActionTypes.GET_REDIS_CART_SUCCESS: {
      return {
        ...state,
        Payload: {},
        action: action.type,
        postCart: action.value,
        Error: null,
        loading: false
      };
    }

    case ActionTypes.GET_REDIS_CART_ERROR: {
      return {
        ...state,
        Payload: action.payload,
        action: action.type,
        postCart: {},
        Error: action.error,
        loading: false
      };
    }

    case ActionTypes.POST_REDIS_CART: {
      return {
        ...state,
        Payload: action.payload,
        action: action.type,
        postCart: {},
        Error: null,
        meta: action.meta,
        loading: true
      };
    }

    case ActionTypes.POST_REDIS_CART_SUCCESS: {
      if (
        !action.value.alert ||
        (typeof action.value.alert === 'object' &&
          Object.keys(action.value.alert).length === 0)
      ) {
        window.localStorage.removeItem('popupShown');
      }
      return {
        ...state,
        Payload: {},
        action: action.type,
        postCart: action.value,
        Error: null,
        meta: action.meta,
        loading: false,
        seletedProduct: action.seletedProduct
      };
    }

    case ActionTypes.POST_REDIS_CART_ERROR: {
      return {
        ...state,
        Payload: action.payload,
        action: action.type,
        postCart: {},
        Error: action.error,
        meta: action.meta,
        loading: false
      };
    }

    case ActionTypes.ALTER_REDIS_CART: {
      return {
        ...state,
        Payload: action.payload,
        action: action.type,
        postCart: {},
        Error: null,
        meta: action.meta,
        loading: true
      };
    }

    case ActionTypes.ALTER_REDIS_CART_SUCCESS: {
      if (
        !action.value.alert ||
        (typeof action.value.alert === 'object' &&
          Object.keys(action.value.alert).length === 0)
      ) {
        window.localStorage.removeItem('popupShown');
      }
      return {
        ...state,
        Payload: {},
        action: action.type,
        postCart: action.value,
        Error: null,
        meta: action.meta,
        loading: false,
        seletedProduct: action.seletedProduct
      };
    }

    case ActionTypes.ALTER_REDIS_CART_ERROR: {
      return {
        ...state,
        Payload: action.payload,
        action: action.type,
        postCart: {},
        Error: action.error,
        meta: action.meta,
        loading: false
      };
    }

    case ActionTypes.COMBINE_REDIS_CART: {
      return {
        ...state,
        Payload: action.payload,
        action: action.type,
        combineCart: {},
        Error: null,
        meta: action.meta,
        loading: true
      };
    }

    case ActionTypes.COMBINE_REDIS_CART_SUCCESS: {
      return {
        ...state,
        Payload: {},
        action: action.type,
        combineCart: action.value,
        Error: null,
        meta: action.meta,
        loading: false
      };
    }

    case ActionTypes.COMBINE_REDIS_CART_ERROR: {
      return {
        ...state,
        Payload: action.payload,
        action: action.type,
        combineCart: {},
        Error: action.error,
        meta: action.meta,
        loading: false
      };
    }

    case ActionTypes.CLEAR_REDIS_CART: {
      return {
        Payload: action.payload,
        action: action.type,
        clearRedisCart: {},
        Error: null,
        meta: action.meta,
        loading: true
      };
    }

    case ActionTypes.CLEAR_REDIS_CART_SUCCESS: {
      return {
        ...state,
        Payload: {},
        action: action.type,
        clearRedisCart: action.value,
        Error: null,
        meta: action.meta,
        loading: false
      };
    }

    case ActionTypes.CLEAR_REDIS_CART_ERROR: {
      return {
        ...state,
        Payload: action.payload,
        action: action.type,
        clearRedisCart: {},
        Error: action.error,
        meta: action.meta,
        loading: false
      };
    }

    case ActionTypes.REMOVE_REDIS_COUPON: {
      return {
        Payload: action.payload,
        action: action.type,
        postCart: {},
        Error: null,
        meta: action.meta,
        loading: true
      };
    }

    case ActionTypes.REMOVE_REDIS_COUPON_SUCCESS: {
      return {
        ...state,
        Payload: {},
        action: action.type,
        postCart: action.value,
        Error: null,
        meta: action.meta,
        loading: false
      };
    }

    case ActionTypes.REMOVE_REDIS_COUPON_ERROR: {
      return {
        ...state,
        Payload: action.payload,
        action: action.type,
        postCart: {},
        Error: action.error,
        meta: action.meta,
        loading: false
      };
    }
    // case 'ADD_CART': {
    //   return {
    //     ...state,
    //     postCart: action.payload
    //   };
    // }
    // case ActionTypes.EMPLOYEE_VERIFY_COUPON: {
    //   return {
    //     Payload: action.payload,
    //     action: action.type,
    //     postCart: {},
    //     Error: null,
    //     meta: action.meta,
    //     loading: true
    //   };
    // }

    // case ActionTypes.EMPLOYEE_VERIFY_COUPON_SUCCESS: {
    //   return {
    //     ...state,
    //     Payload: {},
    //     action: action.type,
    //     postCart: action.value,
    //     Error: null,
    //     meta: action.meta,
    //     loading: false
    //   };
    // }

    // case ActionTypes.EMPLOYEE_VERIFY_COUPON_ERROR: {
    //   return {
    //     ...state,
    //     Payload: action.payload,
    //     action: action.type,
    //     postCart: {},
    //     Error: action.error,
    //     meta: action.meta,
    //     loading: false
    //   };
    // }
    // console.log(state, ' GLOBAL STATE :::::::::::::::::::::: S');

    case ActionTypes.APPLY_REDIS_COUPON: {
      return {
        Payload: action.payload,
        action: action.type,
        postCart: {},
        Error: null,
        meta: action.meta,
        loading: true
      };
    }

    case ActionTypes.APPLY_REDIS_COUPON_SUCCESS: {
      return {
        ...state,
        Payload: {},
        action: action.type,
        postCart: action.value,
        Error: null,
        meta: action.meta,
        loading: false
      };
    }

    case ActionTypes.APPLY_REDIS_COUPON_ERROR: {
      // console.log(action, ' FROM CASE 247 payload ', state);
      return {
        ...state,
        Payload: action.payload,
        action: action.type,
        postCart: {},
        Error: action.error,
        meta: action.meta,
        loading: false
      };
    }

    case ActionTypes.RESET_FREEBIE: {
      return {
        ...state,
        isFreebieApplied: true
      };
    }

    default:
      return {
        ...state
      };
  }
}

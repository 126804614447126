import './index.scss';

import React, { Component } from 'react';

import DownArrowIcon from '../../../assets/images/down-arrow-brown.svg';
import DownArrowOrange from '../../../assets/images/down-arrow-orange.svg';


class HistoryDropDown extends Component {
  constructor() {
    super();
    this.state = {
      showDropdown: false,
    }
  }

  showDropdownClick = () => {
    const { showDropdown } = this.state;
    this.setState({ showDropdown: !showDropdown });
  }

  onSelect = (id, value) => {
    const { onSelectedDropdown } = this.props;
    this.showDropdownClick();
    onSelectedDropdown(id, value);
  }

  render() {
    const { showDropdown } = this.state;
    const { showDropdownClick, onSelect } = this;
    const { content,customClass,theme } = this.props;
    const { title, values, id } = content;
    return (
      <div className={`history-dropdown ${theme}`}>
        <div className={`history-dropdown_input  ${customClass}  ${showDropdown ? 'history-dropdown__content-active' : ''}`} onClick={showDropdownClick} >
          <div className= "history-dropdown__title">{title.value}</div>
          <div>
            <img src={theme==='takeaway'?DownArrowIcon:DownArrowOrange} alt="icon" role="presentation" className='history-dropdown__img' />
          </div>
        </div>
        {showDropdown ?
          <div className={`history-dropdown__content-wrapper ${showDropdown ? 'history-dropdown__content-active' : ''}`}>
            {values && values.map(item => {
              return <div className="history-dropdown__content" onClick={() => onSelect(id, item)} role="presentation" id={id} value={item.value}> {item.value}</div>
            })}
          </div>
          : ''}
      </div>
    )
  }
}

export default HistoryDropDown;
import './index.scss';
import React, { Component } from 'react';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import Feedback from '../../containers/new-feedback-form';
import Modal from '../../containers/modal';

class FeedbackPage extends Component {
  renderContent = () => {
    const {
      theme,
      orderID,
      data,
      constCall,
      fromTickets,
      updateFeedbackApi,
      wholeOrder,
      closeRating
    } = this.props;
    const dom = (
      <Feedback
        onCloseHandler={this.onCloseModalHandler}
        theme={theme}
        closeRating={closeRating}
        orderID={orderID}
        data={data}
        wholeOrder={wholeOrder}
        updateFeedbackApi={updateFeedbackApi}
        constCall={constCall}
        fromTickets={fromTickets}
      />
    );
    return dom;
  };

  onCloseModalHandler = () => {
    const { onCloseModalHandler } = this.props;
    onCloseModalHandler();
  };

  render() {
    const { theme } = this.props;
    return (
      <Modal
        isMobileFullLayout
        onCloseHandler={this.onCloseModalHandler}
        hideClose
      >
        <div className={`feedback ${theme}`}>{this.renderContent()}</div>
      </Modal>
    );
  }
}

FeedbackPage.propTypes = {
  theme: PropTypes.string
};

FeedbackPage.defaultProps = {
  theme: ''
};

export default withRouter(FeedbackPage);

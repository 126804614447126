import { put, call, fork, takeLatest, takeEvery } from 'redux-saga/effects';
import {
  GET_PRODUCT_LIST,
  GET_PRODUCT_LIST2,
  GET_PRODUCT_FROM_CART,
  GET_SEARCH_SUGGESTIONS,
  GET_MENU_CATEGORY,
  GET_ALL_ADDONS,
  GET_SEARCH_PRODUCT_LIST
} from '../actionTypes';
import {
  PRODUCT_LIST_API,
  PRODUCT_LIST_API2,
  GET_PRODUCT_FROM_CART_API,
  GET_SEARCH_SUGGESTIONS_API,
  GET_MENU_CATEGORY_API,
  GET_ALL_ADDONS_API
} from '../../constants/api-constants';

import * as API from '../../utils/ajax';
import * as Actions from './actions';

function* getProductListAsync(action) {
  const { meta } = action;
  try {
    const response = yield call(API.post, PRODUCT_LIST_API, action.payload);
    yield put(Actions.getProductListSuccess(response, action));
  } catch (error) {
    yield put(Actions.getProductListError(error, action));
  }
}

function* getProductList2Async(action) {
  const { payload } = action;
  const { category_id, screen_type, section_type, type } = payload;

  try {
    const response = yield call(
      API.get,
      `${PRODUCT_LIST_API2}?category_id=${category_id}&screen_type=${screen_type}&section_type=${section_type}&type=${type}`,
      action.payload
    );
    yield put(Actions.getProductListSuccess(response, action));
  } catch (error) {
    yield put(Actions.getProductListError(error, action));
  }
}

function* getSearchProductListAsync(action) {
  const { meta } = action;
  try {
    const response = yield call(API.post, PRODUCT_LIST_API, action.payload);
    yield put(Actions.getSearchProductListSuccess(response, action));
  } catch (error) {
    yield put(Actions.getSearchProductListError(error, action));
  }
}

function* getProductFromCartAsync(action) {
  try {
    const response = yield call(
      API.post,
      GET_PRODUCT_FROM_CART_API,
      action.payload
    );
    yield put(Actions.getProductFromCartSuccess(response, action));
  } catch (error) {
    yield put(Actions.getProductFromCartError(error, action));
  }
}

function* getSearchSuggestionsAsync(action) {
  try {
    const response = yield call(
      API.post,
      GET_SEARCH_SUGGESTIONS_API,
      action.payload
    );
    yield put(Actions.getSearchSuggestionsSuccess(response, action));
  } catch (error) {
    yield put(Actions.getSearchSuggestionsError(error, action));
  }
}

function* getProductAddonDetailAsync(action) {
  try {
    const response = yield call(
      API.post,
      GET_MENU_CATEGORY_API,
      action.payload
    );
    yield put(Actions.getProductAddonDetailSuccess(response, action));
  } catch (error) {
    yield put(Actions.getProductAddonDetailError(error, action));
  }
}

function* getAllAddonsAsync(action) {
  try {
    const response = yield call(API.post, GET_ALL_ADDONS_API, action.payload);
    yield put(Actions.getAllAddonsSuccess(response, action));
  } catch (error) {
    yield put(Actions.getAllAddonsError(error, action));
  }
}

export function* watchProductListSaga() {
  yield takeLatest(GET_PRODUCT_LIST, getProductListAsync);
  yield takeLatest(GET_PRODUCT_LIST2, getProductList2Async);
  yield takeLatest(GET_SEARCH_PRODUCT_LIST, getSearchProductListAsync);
  yield takeLatest(GET_PRODUCT_FROM_CART, getProductFromCartAsync);
  yield takeLatest(GET_SEARCH_SUGGESTIONS, getSearchSuggestionsAsync);
  yield takeLatest(GET_MENU_CATEGORY, getProductAddonDetailAsync);
  yield takeLatest(GET_ALL_ADDONS, getAllAddonsAsync);
}

const productListSaga = [fork(watchProductListSaga)];
export default productListSaga;

import {
  ORDER_HISTORY,
  ORDER_HISTORY_SUCCESS,
  ORDER_HISTORY_ERROR,
  FEEDBACK_STATUS,
  FEEDBACK_STATUS_ERROR,
  FEEDBACK_STATUS_SUCCESS,
  TICKET_STATUS,
  TICKET_STATUS_ERROR,
  TICKET_STATUS_SUCCESS,
  IS_VIEWED_UPDATE_API,
  IS_VIEWED_UPDATE_API_SUCCESS,
  IS_VIEWED_UPDATE_API_ERROR,
  FEEDBACK_API_V2,
  FEEDBACK_API_V2_ERROR,
  FEEDBACK_API_V2_SUCCESS,
  UPDATE_FEEDBACK_API,
  UPDATE_FEEDBACK_API_ERROR,
  UPDATE_FEEDBACK_API_SUCCESS,
  ORDER_DETAILS,
  ORDER_DETAILS_SUCCESS,
  ORDER_DETAILS_ERROR
} from '../actionTypes';

const initialState = {
  orderHistoryList: {},
  orderDetails: {}
};

export default function OrderHistoryReducer(state = initialState, action) {
  switch (action.type) {
    case ORDER_HISTORY: {
      return {
        ...state,
        Payload: action.payload,
        action: action.type,
        orderHistoryList: {},
        Error: null,
        meta: action.meta
      };
    }

    case ORDER_HISTORY_SUCCESS: {
      return {
        ...state,
        Payload: {},
        action: action.type,
        orderHistoryList: action.value,
        Error: null,
        meta: action.meta
      };
    }

    case ORDER_HISTORY_ERROR: {
      return {
        ...state,
        Payload: action.payload,
        action: action.type,
        orderHistoryList: {},
        Error: action.error,
        meta: action.meta
      };
    }
    case ORDER_DETAILS: {
      return {
        ...state,
        Payload: action.payload,
        action: action.type,
        orderDetails: {},
        Error: null,
        meta: action.meta
      };
    }

    case ORDER_DETAILS_SUCCESS: {
      return {
        ...state,
        Payload: {},
        action: action.type,
        orderDetails: action.value,
        Error: null,
        meta: action.meta
      };
    }

    case ORDER_DETAILS_ERROR: {
      return {
        ...state,
        Payload: action.payload,
        action: action.type,
        orderDetails: {},
        Error: action.error,
        meta: action.meta
      };
    }
    case FEEDBACK_STATUS: {
      return {
        ...state,
        Payload: action.payload,
        action: action.type,
        Error: null,
        meta: action.meta
      };
    }
    case FEEDBACK_STATUS_SUCCESS: {
      return {
        ...state,
        Payload: {},
        action: action.type,
        feedbackStatusList: action.value,
        Error: null,
        meta: action.meta
      };
    }
    case FEEDBACK_STATUS_ERROR: {
      return {
        ...state,
        Payload: action.payload,
        action: action.type,
        Error: action.error,
        meta: action.meta
      };
    }
    case TICKET_STATUS: {
      return {
        ...state,
        Payload: action.payload,
        action: action.type,
        Error: null,
        meta: action.meta
      };
    }
    case TICKET_STATUS_SUCCESS: {
      return {
        ...state,
        Payload: {},
        action: action.type,
        ticketStatus: action.value,
        Error: null,
        meta: action.meta
      };
    }
    case TICKET_STATUS_ERROR: {
      return {
        ...state,
        Payload: action.payload,
        action: action.type,
        Error: action.error,
        meta: action.meta
      };
    }

    case IS_VIEWED_UPDATE_API: {
      return {
        ...state,
        Payload: action.payload,
        action: action.type,
        Error: null,
        meta: action.meta
      };
    }
    case IS_VIEWED_UPDATE_API_SUCCESS: {
      return {
        ...state,
        Payload: {},
        action: action.type,
        isViewedApi: action.value,
        Error: null,
        meta: action.meta
      };
    }
    case IS_VIEWED_UPDATE_API_ERROR: {
      return {
        ...state,
        Payload: action.payload,
        action: action.type,
        Error: action.error,
        meta: action.meta
      };
    }

    case FEEDBACK_API_V2: {
      return {
        ...state,
        Payload: action.payload,
        action: action.type,
        Error: null,
        meta: action.meta
      };
    }
    case FEEDBACK_API_V2_SUCCESS: {
      return {
        ...state,
        Payload: {},
        action: action.type,
        feedbackApiV2: action.value,
        Error: null,
        meta: action.meta
      };
    }
    case FEEDBACK_API_V2_ERROR: {
      return {
        ...state,
        Payload: action.payload,
        action: action.type,
        Error: action.error,
        meta: action.meta
      };
    }

    case UPDATE_FEEDBACK_API: {
      return {
        ...state,
        Payload: action.payload,
        action: action.type,
        Error: null,
        meta: action.meta
      };
    }
    case UPDATE_FEEDBACK_API_SUCCESS: {
      return {
        ...state,
        Payload: {},
        action: action.type,
        updateFeedback: action.value,
        Error: null,
        meta: action.meta
      };
    }
    case UPDATE_FEEDBACK_API_ERROR: {
      return {
        ...state,
        Payload: action.payload,
        action: action.type,
        Error: action.error,
        meta: action.meta
      };
    }
    default: {
      return { ...state };
    }
  }
}

/* eslint-disable no-undef */
import './index.scss';

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import BreadCrumb from '../../components/breadcrumb';
import AccountSideMenu from '../../containers/account-sidemenu';
import RecentOrders from '../recent-orders';
import SavedAddressess from '../saved-addressess';
import Notifications from '../notifications';
import Footer from '../../containers/footer';
import Profile from '../profile';
import CrownHistory from '../crown-history';
import { ROUTES as _routes } from '../../constants/app-constants';
import Modal from '../../containers/modal';
import OtpProfile from '../../containers/otpProfile';
import NotificationSettings from '../notification-settings';

import Settings from '../settings';
import TermsAndConditions from '../terms-and-condition';
import PrivacyPolicy from '../privacy-policy';
import HelpSupportPage from '../help-support';

import { getUserAvailablePoints } from '../../burger-king-lib/redux/offers/actions';

import { getUserAvailablePointsPayload } from '../../payload-handling/offers';

// import { SessionStorage } from '../../burger-king-lib/utils/storage';
import { LocalSessionKey } from '../../burger-king-lib/constants/app-constants';
import { LocalStorage } from '../../burger-king-lib/utils/storage.web';
// import Cancelpop from '../../components/cancel-popover';

import { getUserDetail } from '../../burger-king-lib/redux/login/actions';
import PromotionalPrivacyPolicy from '../promotional-terms';

class Account extends Component {
  constructor(props) {
    super(props);
    const { innerWidth } = window;
    this.state = {
      breadCrumbData: [
        { name: 'Home', route: '/' },
        { name: 'Account', route: _routes.ACCOUNT },
        { name: 'Recent Orders' }
      ],
      showContent: innerWidth > 1023 || false,
      activePage: '',
      showEditProfile: false,
      userAvailablePoints: 0,
      showSettingsList: false
    };
  }

  componentDidMount() {
    const { location } = this.props;
    const { getUserAvailablePointsData, getUserDetailData } = this;
    const { pathname } = location;
    this.setInitialProgress(pathname);
    getUserAvailablePointsData();
    let isLoggedIn = LocalStorage.get(LocalSessionKey.USER_DATA) || '';
    if (!!Boolean(isLoggedIn)) {
      getUserDetailData();
    }
    if (location?.state === 'isFromDeeplink') {
      if (!!Boolean(isLoggedIn)) {
        this.callState();
      }
    }
  }

  componentDidUpdate(prevProps) {
    const { location, offersData } = this.props;
    // if (prevProps.location !== location) {
    //   const { pathname } = location;
    //   this.setInitialProgress(pathname);
    // }

    if (
      offersData &&
      offersData !== prevProps.offersData &&
      offersData.getUserAvailablePoints !==
        prevProps.offersData.getUserAvailablePoints
    ) {
      if (
        offersData.getUserAvailablePoints &&
        offersData.getUserAvailablePoints.error === 0
      ) {
        if (offersData.getUserAvailablePoints.content) {
          this.setuserAvailablePoints(
            offersData.getUserAvailablePoints.content
          );
        }
      }
    }
  }
  callState = () => {
    this.profileOnClickHandler();
  };

  setInitialProgress = path => {
    const { innerWidth } = window;
    let pathSplit = path.split(`${_routes.ACCOUNT}/`);
    if (pathSplit && pathSplit.length) {
      if (pathSplit[1]) {
        pathSplit = pathSplit[1];
        const breadcrumbActive = pathSplit.replace('-', ' ');
        this.setState({
          showContent: true,
          breadCrumbData: [
            { name: 'Home', route: '/' },
            { name: 'Account', route: _routes.ACCOUNT },
            { name: breadcrumbActive }
          ]
        });
      } else if (
        pathSplit[0] === _routes.ACCOUNT ||
        pathSplit[0] === _routes.HOME
      )
        this.setState({
          showContent: innerWidth > 1023 || false,
          breadCrumbData: [
            { name: 'Home', route: '/' },
            { name: 'Account', route: _routes.ACCOUNT }
          ]
        });
    } else {
      this.setState({ showContent: false });
    }
    this.setState({ activePage: pathSplit });
  };

  getUserAvailablePointsData = () => {
    const { getUserAvailablePointsData } = this.props;
    const phone_no = LocalStorage.get(LocalSessionKey.USER_DATA);
    if (phone_no) {
      const payload = getUserAvailablePointsPayload(phone_no);
      getUserAvailablePointsData(payload);
    }
  };

  getUserDetailData = () => {
    const { getUserDetailData } = this.props;
    getUserDetailData();
  };

  setuserAvailablePoints = data => {
    const { available_points } = data;
    this.setState({ userAvailablePoints: available_points });
  };

  hideSideBarMobile = () => {
    this.setState({ showContent: false });
  };

  menuOnClick = key => {
    const { innerWidth } = window;
    if (innerWidth <= 1023) {
      const { hideHamburgerMenu } = this.props;
      hideHamburgerMenu();
    } else {
      this.setState({ showContent: true, activePage: key });
    }
  };

  profileOnClickHandler = () => {
    const { innerWidth } = window;
    if (innerWidth > 1023) this.setState({ showEditProfile: true });
    else {
      const { hideHamburgerMenu } = this.props;
      hideHamburgerMenu();
      const { history } = this.props;
      history.push(_routes.EDIT_PROFILE);
    }
  };

  hideProfileModal = () => {
    this.setState({ showEditProfile: false });
  };

  editUserSuccessHandler = () => {
    const { hideProfileModal } = this;
    hideProfileModal();
    // this.setState({ showOtpModal: true })
  };

  hideOtpModalHandler = () => {
    this.setState({ showOtpModal: false });
  };

  onVerifyOtpClick = () => {
    const { hideOtpModalHandler } = this;
    hideOtpModalHandler();
  };

  renderRightContent = () => {
    const { activePage } = this.state;
    const { theme } = this.props;
    const { editUserSuccessHandler, hideSideBarMobile } = this;
    let dom = '';
    switch (activePage) {
      case 'recent-orders':
        dom = <RecentOrders theme={theme} />;
        break;
      case 'saved-addressess':
        dom = <SavedAddressess theme={theme} />;
        break;
      case 'notifications':
        dom = <Notifications theme={theme} />;
        break;
      case 'profile':
        dom = (
          <Profile
            editUserSuccessHandler={editUserSuccessHandler}
            theme={theme}
          />
        );
        break;
      case 'notification-settings':
        dom = (
          <NotificationSettings
            theme={theme}
            hideSideBarMobile={hideSideBarMobile}
          />
        );
        break;
      case 'settings':
        dom = (
          <Settings
            theme={theme}
            handleShowSettingsList={() => {
              this.handleSettingsChange(true);
            }}
          />
        );
        break;
      case 'terms-conditions':
        dom = <TermsAndConditions theme={theme} />;
        break;
      case 'privacy-policy':
        dom = <PrivacyPolicy theme={theme} />;
        break;
      case 'promotional-terms':
        dom = <PromotionalPrivacyPolicy theme={theme} />;
        break;
      case 'crown-history':
        dom = <CrownHistory theme={theme} />;
        break;
      case 'help-support':
        dom = <HelpSupportPage theme={theme} />;
        break;
      default:
        dom = '';
    }

    return dom;
  };

  handleSettingsChange = value => {
    this.setState({
      showSettingsList: value
    });
  };

  render() {
    const {
      breadCrumbData,
      showContent,
      showEditProfile,
      showOtpModal,
      userAvailablePoints,
      showSettingsList
    } = this.state;
    const {
      hideSideBarMobile,
      menuOnClick,
      hideProfileModal,
      profileOnClickHandler,
      hideOtpModalHandler,
      onVerifyOtpClick,
      editUserSuccessHandler,
      handleSettingsChange
    } = this;
    const { theme, loginData, hideHamburgerMenu } = this.props;

    return (
      <div className="account_wrapper">
        <div className="account">
          <BreadCrumb data={breadCrumbData} theme={theme} />
          <div className="account__content">
            {!showContent || window?.innerWidth > 1023 ? (
              <div className="account__left-content">
                <AccountSideMenu
                  userData={loginData}
                  profileOnClick={profileOnClickHandler}
                  menuOnClick={menuOnClick}
                  hideSideBar={hideSideBarMobile}
                  hideHamburgerMenu={hideHamburgerMenu}
                  theme={theme}
                  userAvailablePoints={userAvailablePoints}
                  showSettingsList={showSettingsList}
                  handleShowSettingsList={handleSettingsChange}
                />
              </div>
            ) : (
              ''
            )}
            {showContent ? (
              <div className="account__right-content">
                {this.renderRightContent()}
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
        {showEditProfile ? (
          <Profile
            theme={theme}
            editUserSuccessHandler={editUserSuccessHandler}
            onCloseHandler={hideProfileModal}
          />
        ) : (
          ''
        )}

        {showOtpModal ? (
          <Modal onCloseHandler={hideOtpModalHandler}>
            <OtpProfile onVerifyOtpClick={onVerifyOtpClick} />
          </Modal>
        ) : (
          ''
        )}
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    loginData: state.login,
    offersData: state.offers
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getUserAvailablePointsData: payloadData =>
      dispatch(getUserAvailablePoints(payloadData)),
    getUserDetailData: payloadData => dispatch(getUserDetail(payloadData))
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Account)
);

import './index.scss';

import React from 'react';
import { getLeftArrowImage } from '../../utils/imageChanges';

const AccountSubSecTitle = props => {
  const {
    title,
    goBackOnClick,
    showDesGoBack,
    theme,
    rightContent,
    rightContentClick,
    hideBackArrow,
    displayCenterTitle
  } = props;
  const { innerWidth } = window;

  if (innerWidth > 1023) {
    const baseClassName = 'act-sub-sec__detail';
    const modifiedClassName = displayCenterTitle
      ? `${baseClassName}l`
      : baseClassName;
    return (
      <div className={modifiedClassName}>
        <div className="act-sub-sec__content">
          {showDesGoBack ? (
            <img
              className="u_cursor_pointer act-sub-sec__arrow"
              src={getLeftArrowImage(theme)}
              alt="left"
              role="presentation"
              onClick={() => goBackOnClick()}
            />
          ) : (
            ''
          )}
          <p className="act-sub-sec__title">{title}</p>
        </div>
        {rightContent ? (
          <div
            className={`act-sub-sec__right ${theme} u_cursor_pointer`}
            onClick={() => rightContentClick()}
            role="presentation"
          >
            {rightContent}
          </div>
        ) : (
          ''
        )}
      </div>
    );
  }

  return (
    <div className="act-sub-sec__mob">
      <div className="act-sub-sec__left">
        {!hideBackArrow ? (
          <img
            src={getLeftArrowImage(theme)}
            alt="left"
            role="presentation"
            onClick={() => goBackOnClick()}
          />
        ) : (
          ''
        )}
        <div className="act-sub-sec__title">{title}</div>
      </div>
      {rightContent ? (
        <div
          className={`act-sub-sec__right ${theme} u_cursor_pointer`}
          onClick={() => rightContentClick()}
          role="presentation"
        >
          {rightContent}
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default AccountSubSecTitle;

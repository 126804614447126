import './index.scss';

import React from 'react';

import Upload from '../upload';

// import CameraIcon from '../../../assets/images/camera-icon.svg';
import GalleryIcon from '../../../assets/images/gallery-icon.svg';
import RemoveIcon from '../../../assets/images/remove-icon.svg';

const ProfilePhotoEdit = props => {
  const {
    selectFromGalleryOnChange,
    removeProfilePicHandler,
    profilePic
  } = props;
  // const { innerWidth } = window;

  return (
    <ul className="edit-profile u_list_style_none">
      {/* <li className="edit-profile__list edit-profile__options u_cursor_pointer">
        <div><img src={CameraIcon} alt="icon" /></div>
        <p className="edit-profile__text"> Take New Photo </p>
      </li>  */}
      <li className="edit-profile__list">
        <Upload onChange={selectFromGalleryOnChange} accept={'.png,.jpg,.jpeg'}>
          <div className="edit-profile__options u_cursor_pointer">
            <div>
              <img src={GalleryIcon} alt="icon" />
            </div>
            <p className="edit-profile__text">Select from Gallery</p>
          </div>
        </Upload>
      </li>
      {profilePic ? (
        <li
          className="edit-profile__list edit-profile__options u_cursor_pointer"
          role="presentation"
          onClick={() => removeProfilePicHandler()}
        >
          <div>
            <img src={RemoveIcon} alt="icon" />
          </div>
          <p className="edit-profile__text"> Remove Photo </p>
        </li>
      ) : (
        ''
      )}
    </ul>
  );
};

export default ProfilePhotoEdit;

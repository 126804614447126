import React from 'react';
import './index.scss';

class ToolTip extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      displayData: {}
    };
  }

  componentDidMount() {
    const el = document.body;
    el.addEventListener('click', this.eventListenerFn, false);
  }

  componentWillUnmount() {
    const el = document.body;
    el.removeEventListener('click', this.eventListenerFn, false);
  }

  eventListenerFn = event => {
    if (
      !(
        event.target.getAttribute('class') &&
        event.target.getAttribute('class').indexOf('tooltip') > -1
      )
    ) {
      const { id } = this.props;
      this.hideToolTip(id);
    }
  };

  showToolTip = id => {
    this.setState({
      displayData: {
        [id]: true
      }
    });
  };

  hideToolTip = id => {
    this.setState({
      displayData: {
        [id]: false
      }
    });
  };

  mouseClick = id => {
    const { displayData } = this.state;
    const isOpen = displayData[id];
    this.setState({
      displayData: {
        [id]: !isOpen
      }
    });
  };

  render() {
    const { position, children, content, color, id, name } = this.props;
    const { displayData } = this.state;
    return (
      <div className="tooltip">
        {window.innerWidth > 1023 ? (
          <div
            className="tooltip__child"
            onMouseEnter={() => this.showToolTip(id)}
            onMouseLeave={() => this.hideToolTip(id)}
          >
            {children}
          </div>
        ) : (
          <div
            className="tooltip__child"
            role="presentation"
            onClick={() => this.mouseClick(id)}
          >
            {children}
          </div>
        )}
        {displayData[id] && (
          <div
            className={`${
              name ? 'tooltip__image12' : 'tooltip__image'
            } ${position} ${color}`}
          >
            {content}
          </div>
        )}
      </div>
    );
  }
}

export default ToolTip;

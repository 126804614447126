/* eslint-disable camelcase */
import { put, call, fork, takeLatest, all } from 'redux-saga/effects';
import {
  GET_HOME_API_LIST,
  GET_VERSION_CONTROL,
  GET_TOKEN,
  GET_CATEGORY,
  GET_SUBCATEGORY,
  GET_NUTRITIONAL,
  GET_INVESTORTREEDATA,
  GET_OUTLETDATA
} from '../actionTypes';
import {
  HOME_API_LIST,
  VERSION_CONTROL_API,
  CREATE_TOKEN_API,
  INVESTOR_RELATIONS_SUBCATEGORY_API,
  GET_NUTRITIONAL_INFO,
  GET_OUTLETS,
  INVESTORTREELIST
} from '../../constants/api-constants';

import * as API from '../../utils/ajax';
import * as Actions from './actions';

function* getTokenAsync(action) {
  try {
    const response = yield call(API.get, CREATE_TOKEN_API, action.payload);
    yield put(Actions.getTokenSuccess(response, action));
  } catch (error) {
    yield put(Actions.getTokenError(error, action));
  }
}

function* getVersionControlAsync(action) {
  try {
    const response = yield call(API.get, VERSION_CONTROL_API, action.payload);
    yield put(Actions.getVersionControlSuccess(response, action));
  } catch (error) {
    yield put(Actions.getVersionControlError(error, action));
  }
}

function* getHomeApiListAsync(action) {
  // if (action.payload.lat == 0 && action.payload.long == 0) {
  //   // {"lat":34.5576844,"lng":76.1269217}
  //   action.payload.lat = 34.5576844;
  //   action.payload.long = 76.1269217;
  // }
  try {
    const response = yield call(API.post, HOME_API_LIST, action.payload);
    yield put(Actions.getHomeApiListSuccess(response, action));
    try {
      const requestArray =
        response &&
        response.content &&
        response.content.home.length > 0 &&
        response.content.home.map(i => {
          if (i.backend_data.url && i.backend_data.postdata) {
            return i.backend_data;
          }
        });
      // yield put(Actions.getRedisCartItem({}));
      if (
        response?.content?.cart_data &&
        Object.keys(response?.content?.cart_data).length > 0
      ) {
        const cartData = response?.content?.cart_data;
        yield put(Actions.getRedisCartItemSuccess(cartData));
      }
      yield put(Actions.getMenuList());
      const getHomeOverAllResponse = yield all(
        requestArray?.map(item => call(API.post, item?.url, item?.postdata))
      );
      yield put(Actions.getMenuListSuccess({ getHomeOverAllResponse }));
    } catch (error) {
      yield put(Actions.getMenuListError(error, action));
    }
  } catch (error) {
    yield put(Actions.getHomeApiListError(error, action));
  }
}

function* getSubCategoryAsync(action) {
  try {
    const response = yield call(
      API.post,
      INVESTOR_RELATIONS_SUBCATEGORY_API,
      action.payload
    );
    yield put(Actions.getSubCategorySuccess(response, action));
  } catch (error) {
    yield put(Actions.getSubCategoryError(error, action));
  }
}

function* getCategoryAsync(action) {
  try {
    const response = yield call(API.get, INVESTORTREELIST, action.payload);
    yield put(Actions.getCategorySuccess(response, action));
  } catch (error) {
    yield put(Actions.getCategoryError(error, action));
  }
}

function* getInvestorTreeAsync(action) {
  try {
    const response = yield call(API.get, INVESTORTREELIST, action.payload);
    yield put(Actions.getInvestorTreeSuccess(response, action));
  } catch (error) {
    yield put(Actions.getInvestorTreeError(error, action));
  }
}

function* getNutritionalAsync(action) {
  try {
    const response = yield call(API.get, GET_NUTRITIONAL_INFO, action.payload);
    yield put(Actions.getNutritionSuccess(response, action));
  } catch (error) {
    yield put(Actions.getNutritionError(error, action));
  }
}

function* getOutletAsync(action) {
  try {
    const response = yield call(API.get, GET_OUTLETS, action.payload);
    yield put(Actions.getOutletDataSuccess(response, action));
  } catch (error) {
    yield put(Actions.getOutletDataError(error, action));
  }
}

export function* watchHomeSaga() {
  yield takeLatest(GET_HOME_API_LIST, getHomeApiListAsync);
  yield takeLatest(GET_VERSION_CONTROL, getVersionControlAsync);
  yield takeLatest(GET_TOKEN, getTokenAsync);
  yield takeLatest(GET_CATEGORY, getCategoryAsync);
  yield takeLatest(GET_INVESTORTREEDATA, getInvestorTreeAsync);
  yield takeLatest(GET_SUBCATEGORY, getSubCategoryAsync);
  yield takeLatest(GET_NUTRITIONAL, getNutritionalAsync);
  yield takeLatest(GET_OUTLETDATA, getOutletAsync);
}
const homeSaga = [fork(watchHomeSaga)];
export default homeSaga;

import React from 'react';
import './index.scss';
import { LocalSessionKey } from '../../burger-king-lib/constants/app-constants';
import { LocalStorage } from '../../burger-king-lib/utils/storage.web';
import Restaurant from '../../../assets/images/marker-brown1.png';
import Delivery from '../../../assets/images/marker.png';
import SearchAutoComplete from '../search-autocomplete';
// import {Styles} from '../../mock-data/googlemap';

class GoogleMapSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mapIsReady: {},
      latlng: LocalStorage.get(LocalSessionKey.LAT_LNG)
        ? LocalStorage.get(LocalSessionKey.LAT_LNG)
        : { lat: 0, lng: 0 },
      mapOptions: {
        center: LocalStorage.get(LocalSessionKey.LAT_LNG),
        zoom: 18,
        disableDefaultUI: true,
        draggable: true,
        mapTypeId: 'roadmap'
        // styles:Styles
      }
    };
  }

  componentDidMount = () => {
    const { theme } = this.props;
    let image = Delivery;
    if (theme === 'takeaway') {
      image = Restaurant;
    }
    this.isMapLoad();
  };

  componentDidUpdate() {
    const { latlng, mapOptions } = this.state;
    const { center, onCenterChange } = this.props;
    if (center.lat !== '' || center.lng !== '') {
      this.isMapLoads(center);
      onCenterChange(false);
    }
    if (latlng.lat !== center.lat) {
      if (latlng.lat !== '' || latlng.lng !== '') {
        if (mapOptions.center !== latlng) {
          mapOptions.center = latlng;
          this.setState({ mapOptions });
        }
      }
    }
  }

  isMapLoad = () => {
    const { mapOptions } = this.state;
    const { theme } = this.props;
    let image = Delivery;
    if (theme === 'takeaway') {
      image = Restaurant;
    }
    if (mapOptions.center !== { lat: '', lng: '' }) {
      const { google } = window;
      const map = new google.maps.Map(
        document.getElementById('map'),
        mapOptions
      );
      const marker = new google.maps.Marker({
        map,
        icon: image,
        draggable: false,
        position: map.getCenter()
      });
      const position = marker.getPosition();
      if (typeof position !== 'undefined') {
        map.setCenter(marker.getPosition());
      }

      map.addListener('center_changed', () => {
        marker.setPosition(map.getCenter());
        const NewMapCenter = map.getCenter();
        map.addListener(
          'drag',
          this.oncenter(NewMapCenter.lat(), NewMapCenter.lng())
        );
      });
    }
  };

  oncenter = (lat, lng) => {
    const { onMarkerPosition } = this.props;
    const { mapOptions } = this.state;
    const json = { lat, lng };
    mapOptions.center = json;
    this.setState({ mapOptions });
    onMarkerPosition(json);
  };

  onLoad = (value, json) => {
    if (value === true) {
      this.isMapLoads(json);
    }
  };

  isMapLoads = json => {
    const { mapOptions } = this.state;
    const { theme } = this.props;
    let image = Delivery;
    if (theme === 'takeaway') {
      image = Restaurant;
    }
    mapOptions.center = json;
    const { google } = window;
    const map = new google.maps.Map(document.getElementById('map'), mapOptions);
    const marker = new google.maps.Marker({
      map,
      icon: image,
      draggable: false,
      position: map.getCenter()
    });
    const position = marker.getPosition();
    if (typeof position !== 'undefined') {
      map.setCenter(marker.getPosition());
    }

    map.addListener('center_changed', () => {
      marker.setPosition(map.getCenter());
      const NewMapCenter = map.getCenter();
      map.addListener(
        'drag',
        this.oncenter(NewMapCenter.lat(), NewMapCenter.lng())
      );
    });
  };

  render() {
    const {
      isMobileView,
      inputValue,
      onSearchChange,
      setInputValue,
      setLatLng,
      onClearClick,
      theme,
      getCurrentLocation,
      noLocationFound
    } = this.props;
    return (
      <>
        <div className="google-maps">
          <div id="map" className="map" />
        </div>
        <div className="map-search">
          <SearchAutoComplete
            isMobileView={isMobileView}
            inputValue={inputValue}
            onSearchChange={onSearchChange}
            setInputValue={setInputValue}
            setLatLng={setLatLng}
            onClearClick={onClearClick}
            theme={theme}
            noLocationFound={noLocationFound}
            onloadMap={this.onLoad}
          />
        </div>
      </>
    );
  }
}

export default GoogleMapSearch;

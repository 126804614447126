import { put, call, fork, takeLatest, takeEvery } from 'redux-saga/effects';
import * as API from '../../utils/ajax';
import * as Actions from './actions';
import * as ActionTypes from '../actionTypes';
import {
  ADD_TO_REDIS_CART_API,
  ALTER_REDIS_CART_API,
  APPLY_REDIS_COUPON_API,
  CLEAR_REDIS_CART_API,
  COMBINE_REDIS_CART_API,
  EMPLOYEE_VERIFY_OFFER_OTP_API,
  REMOVE_REDIS__COUPON_API,
  RESEND_OFFER_OTP_API
} from '../../constants/api-constants';

function* postRedisCartInfo(action) {
  try {
    const response = yield call(
      API.post,
      ADD_TO_REDIS_CART_API,
      action.payload
    );
    yield put(Actions.postRedisCartSuccess(response, action));
  } catch (error) {
    yield put(Actions.postRedisCartError(error, action));
  }
}

function* alterRedisCartInfo(action) {
  try {
    const response = yield call(API.post, ALTER_REDIS_CART_API, action.payload);
    yield put(Actions.alterRedisCartSuccess(response, action));
  } catch (error) {
    yield put(Actions.alterRedisCartError(error, action));
  }
}

function* combineRedisCartInfo(action) {
  try {
    const response = yield call(
      API.post,
      COMBINE_REDIS_CART_API,
      action.payload
    );
    yield put(Actions.combineRedisCartSuccess(response, action));
  } catch (error) {
    yield put(Actions.combineRedisCartError(error, action));
  }
}

function* clearRedisCartInfo(action) {
  try {
    const response = yield call(API.get, CLEAR_REDIS_CART_API, action.payload);
    yield put(Actions.clearRedisCartSuccess(response, action));
  } catch (error) {
    yield put(Actions.clearRedisCartError(error, action));
  }
}

function* getRedisCartInfo(action) {
  try {
    const response = yield call(API.get, ADD_TO_REDIS_CART_API, action.payload);
    yield put(Actions.getRedisCartSuccess(response, action));
  } catch (error) {
    yield put(Actions.getRedisCartError(error, action));
  }
}

function* applyRedisCouponInfo(action) {
  try {
    const response = yield call(
      API.post,
      APPLY_REDIS_COUPON_API,
      action.payload
    );
    yield put(Actions.applyRedisCouponSuccess(response, action));
    action.payload.successCallBack(response);
  } catch (error) {
    yield put(Actions.applyRedisCouponError(error, action));
  }
}

function* removeRedisCouponInfo(action) {
  try {
    const response = yield call(
      API.post,
      REMOVE_REDIS__COUPON_API,
      action.payload
    );
    yield put(Actions.removeRedisCouponSuccess(response, action));
  } catch (error) {
    yield put(Actions.removeRedisCouponError(error, action));
  }
}

function* employeeVerifyCouponInfo(action) {
  try {
    const response = yield call(
      API.post,
      EMPLOYEE_VERIFY_OFFER_OTP_API,
      action.payload
    );
    yield put(Actions.employeeVerifyCouponSuccess(response, action));
  } catch (error) {
    yield put(Actions.employeeVerifyCouponError(error, action));
  }
}

function* resendOfferOtpAsync(action) {
  try {
    const response = yield call(API.post, RESEND_OFFER_OTP_API, action.payload);
    yield put(Actions.resendOfferOtpSuccess(response, action));
  } catch (error) {
    yield put(Actions.resendOfferOtpError(error, action));
  }
}

export function* watchRedisCartSaga() {
  yield takeLatest(ActionTypes.POST_REDIS_CART, postRedisCartInfo);
  yield takeLatest(ActionTypes.ALTER_REDIS_CART, alterRedisCartInfo);
  yield takeLatest(ActionTypes.COMBINE_REDIS_CART, combineRedisCartInfo);
  yield takeLatest(ActionTypes.CLEAR_REDIS_CART, clearRedisCartInfo);
  yield takeLatest(ActionTypes.APPLY_REDIS_COUPON, applyRedisCouponInfo);
  yield takeLatest(ActionTypes.REMOVE_REDIS_COUPON, removeRedisCouponInfo);
  yield takeLatest(ActionTypes.GET_REDIS_CART, getRedisCartInfo);
  yield takeLatest(
    ActionTypes.EMPLOYEE_VERIFY_COUPON,
    employeeVerifyCouponInfo
  );
  yield takeLatest(ActionTypes.RESEND_OFFER_OTP, resendOfferOtpAsync);
}

const redisCartSaga = [fork(watchRedisCartSaga)];
export default redisCartSaga;

import * as ActionTypes from '../actionTypes';

export const getAddressList = (payloadData, meta) => ({
  type: ActionTypes.GET_ADDRESS_LIST,
  payload: payloadData,
  meta
});

export const getAddressListSuccess = (value, action) => ({
  type: ActionTypes.GET_ADDRESS_LIST_SUCCESS,
  value,
  meta: action.meta
});

export const getAddressListError = (error, action) => ({
  type: ActionTypes.GET_ADDRESS_LIST_ERROR,
  error,
  payload: action.payload,
  meta: action.meta
});

export const addAddress = (payloadData, meta) => ({
  type: ActionTypes.ADD_ADDRESS,
  payload: payloadData,
  meta
});

export const addAddressSuccess = (value, action) => ({
  type: ActionTypes.ADD_ADDRESS_SUCCESS,
  value,
  meta: action.meta
});

export const addAddressError = (error, action) => ({
  type: ActionTypes.ADD_ADDRESS_ERROR,
  error,
  payload: action.payload,
  meta: action.meta
});

export const getDeleteAddress = (payloadData, meta) => ({
  type: ActionTypes.DELETE_ADDRESS,
  payload: payloadData,
  meta
});

export const getDeleteAddressSuccess = (value, action) => ({
  type: ActionTypes.DELETE_ADDRESS_SUCCESS,
  value,
  meta: action.meta
});

export const getDeleteAddressError = (error, action) => ({
  type: ActionTypes.DELETE_ADDRESS_ERROR,
  error,
  payload: action.payload,
  meta: action.meta
});

export const getOutlet = (payloadData, meta) => ({
  type: ActionTypes.GET_OUTLET_LIST,
  payload: payloadData,
  meta
});

export const getOutletSuccess = (value, action) => ({
  type: ActionTypes.GET_OUTLET_LIST_SUCCESS,
  value,
  meta: action.meta
});

export const getOutletError = (error, action) => ({
  type: ActionTypes.GET_OUTLET_LIST_ERROR,
  error,
  payload: action.payload,
  meta: action.meta
});

import './index.scss';

import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import MobileHomeHeader from './mobile-header-home';
import MobileProductListingHeader from './mobile-plp-title';
import MobileHeaderWithoutSearch from './mobile-header-without-search';

import { ROUTES as _routes } from '../../constants/app-constants';

class MobileHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      path: this.initialLocation()
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.location.pathname !== prevState.path) {
      return { path: nextProps.location.pathname };
    }
    return null;
  }

  initialLocation = () => {
    const { location } = this.props;
    return location.pathname;
  };

  searchClick = () => {
    const { history } = this.props;
    history.push(_routes.SEARCH);
    // alert('Hey! Feature coming soon. stay tuned.');
  };

  goBackClick = () => {
    const { history } = this.props;
    if (history.location.pathname === _routes.CART) {
      history.push(_routes.HOME);
    } else {
      history.go(-1);
    }
  };

  hamburgerClick = () => {
    // const isLoggedIn = LocalStorage.get(LocalSessionKey.USER_DATA);
    // if (isLoggedIn && isLoggedIn.phone_no) {
    //   const { history } = this.props;
    //   history.push(_routes.ACCOUNT);
    // }else {
    const { loginOnClick } = this.props;
    loginOnClick();
    // }
  };

  headerName = value => {
    let title;
    switch (value) {
      case '/cart':
        title = 'your order';
        break;
      case '/product-listing':
      case '/m-product-listing':
        title = 'Our Menu';
        break;
      case '/store-locator':
        title = 'Find a bk';
        break;
      default:
        break;
    }
    return title;
  };

  renderHeaderDom = () => {
    const { hamburgerClick } = this;
    const {
      theme,
      onChange,
      value,
      onClickLocation,
      showDineinTheatre
    } = this.props;
    const { path } = this.state;
    let dom;
    switch (path) {
      case '/':
        dom = (
          <MobileHomeHeader
            onChange={onChange}
            showDineinTheatre={showDineinTheatre}
            value={value && value.location}
            theme={theme}
            searchClick={this.searchClick}
            onClickLocation={onClickLocation}
            hamburgerClick={hamburgerClick}
          />
        );
        break;
      case '/feedback':
        dom = (
          <MobileHomeHeader
            onChange={onChange}
            value={value && value.location}
            theme={theme}
            searchClick={this.searchClick}
            onClickLocation={onClickLocation}
            hamburgerClick={hamburgerClick}
          />
        );
        break;
      case '/product-listing':
      case '/m-product-listing':
        dom = (
          <MobileProductListingHeader
            theme={theme}
            searchClick={this.searchClick}
            goBackClick={this.goBackClick}
            headerTitle={this.headerName(path)}
          />
        );
        break;
      case '/cart':
      case '/store-locator':
      case '/video-player':
        dom = (
          <MobileHeaderWithoutSearch
            theme={theme}
            goBackClick={this.goBackClick}
            headerTitle={this.headerName(path)}
          />
        );
        break;
      default:
        break;
    }
    return dom;
  };

  render() {
    return <div className="mobile-header">{this.renderHeaderDom()}</div>;
  }
}

export default withRouter(MobileHeader);

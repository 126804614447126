import './index.scss';

import React from 'react';

import LoginSuccessImage from '../../../assets/images/bkLogo.png';

const LoginSuccess = () => {
  return (
    <div className="login-success">
      <img
        src={LoginSuccessImage}
        alt="success"
        className="login-success__image"
      />
      <p className="login-success__title">Woo the hoo</p>
      <p className="login-success__description">
        Welcome to BK Royalty & Loyalty
      </p>
    </div>
  );
};

export default LoginSuccess;

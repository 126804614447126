import './index.scss';

import React from 'react';

import AddressCard from '../../components/address-card';
import Loader from '../../components/loader-spin';

import { getPlusImage } from '../../utils/imageChanges';

const SavedAddressessList = props => {
  const {
    addressList,
    isLoggedIn,
    showLoader,
    savedAddressClickHndler,
    deleteAddressClick,
    editAddressClick,
    addAddressClickHandler,
    theme
  } = props;
  const dom = [];

  if (isLoggedIn && window.innerWidth < 1023) {
    dom.push(
      <>
        <div
          className="svd-adrs-list__add u_cursor_pointer"
          role="presentation"
          onClick={() => addAddressClickHandler()}
        >
          <div className="svd-adrs-list__image">
            <img src={getPlusImage(theme)} alt="plus sign" />
            <span className="svd-adrs-list__add-title">Add Address</span>
          </div>
        </div>
        <div className="svd-adrs-list__text">Saved Addresses</div>
      </>
    );
  }

  if (!showLoader) {
    if (addressList && addressList.length)
      dom.push(
        <AddressCard
          addressList={addressList}
          onClick={savedAddressClickHndler}
          deleteAddressHandler={deleteAddressClick}
          editAddressHanlder={editAddressClick}
          theme={theme}
        />
      );
    else
      dom.push(
        <div className="svd-adrs-list__no-item">No address found!!!</div>
      );
  } else {
    dom.push(<Loader theme={theme} />);
  }

  if (isLoggedIn && window.innerWidth > 1023) {
    dom.push(
      <div
        className="svd-adrs-list__add u_cursor_pointer"
        role="presentation"
        onClick={() => addAddressClickHandler()}
      >
        <div className="svd-adrs-list__image">
          <img src={getPlusImage(theme)} alt="plus sign" />
          <span className="svd-adrs-list__add-title">Add Address</span>
        </div>
      </div>
    );
  }

  return dom;
};

export default SavedAddressessList;

import * as ActionTypes from '../actionTypes';

// GET_REDIS_CART
export const getRedisCart = payload => ({
  type: ActionTypes.GET_REDIS_CART,
  payload: payload
});

export const getRedisCartSuccess = value => ({
  type: ActionTypes.GET_REDIS_CART_SUCCESS,
  value
});

export const getRedisCartError = error => ({
  type: ActionTypes.GET_REDIS_CART_ERROR,
  error
});

// // POST_REDIS_CART
export const postRedisCart = (payload, meta, seletedProduct) => ({
  type: ActionTypes.POST_REDIS_CART,
  payload,
  meta,
  seletedProduct
});

export const postRedisCartSuccess = (value, action) => ({
  type: ActionTypes.POST_REDIS_CART_SUCCESS,
  value,
  meta: action.meta,
  seletedProduct: action.seletedProduct
});

export const postRedisCartError = (error, action) => ({
  type: ActionTypes.POST_REDIS_CART_ERROR,
  error,
  meta: action.meta
});

// ALTER_REDIS_CART
export const alterRedisCart = (payload, meta, seletedProduct) => ({
  type: ActionTypes.ALTER_REDIS_CART,
  payload: payload,
  meta: meta,
  seletedProduct
});

export const alterRedisCartSuccess = (value, action) => ({
  type: ActionTypes.ALTER_REDIS_CART_SUCCESS,
  value,
  meta: action.meta,
  seletedProduct: action.seletedProduct
});

export const alterRedisCartError = (error, action) => ({
  type: ActionTypes.ALTER_REDIS_CART_ERROR,
  error,
  meta: action.meta
});

// COMBINE_REDIS_CART
export const combineRedisCart = (payload, meta) => ({
  type: ActionTypes.COMBINE_REDIS_CART,
  payload: payload,
  meta: meta
});

export const combineRedisCartSuccess = (value, action) => ({
  type: ActionTypes.COMBINE_REDIS_CART_SUCCESS,
  value,
  meta: action.meta
});

export const combineRedisCartError = (error, action) => ({
  type: ActionTypes.COMBINE_REDIS_CART_ERROR,
  error,
  meta: action.meta
});

// CLEAR_REDIS_CART
export const clearRedisCart = (payload, meta) => ({
  type: ActionTypes.CLEAR_REDIS_CART,
  payload: payload,
  meta: meta
});

export const clearRedisCartSuccess = (value, action) => ({
  type: ActionTypes.CLEAR_REDIS_CART_SUCCESS,
  value,
  meta: action.meta
});

export const clearRedisCartError = (error, action) => ({
  type: ActionTypes.CLEAR_REDIS_CART_ERROR,
  error,
  meta: action.meta
});

// APPLY_REDIS_COUPON
export const applyRedisCoupon = (payload, meta) => ({
  type: ActionTypes.APPLY_REDIS_COUPON,
  payload: payload,
  meta: meta
});

export const resetFreebie = payload => ({
  type: ActionTypes.RESET_FREEBIE,
  payload: payload
});

export const applyRedisCouponSuccess = (value, action) => ({
  type: ActionTypes.APPLY_REDIS_COUPON_SUCCESS,
  value,
  meta: action.meta
});

export const applyRedisCouponError = (error, action) => ({
  type: ActionTypes.APPLY_REDIS_COUPON_ERROR,
  error,
  meta: action.meta
});

// REMOVE_REDIS_COUPON
export const removeRedisCoupon = (payload, meta) => ({
  type: ActionTypes.REMOVE_REDIS_COUPON,
  payload: payload,
  meta: meta
});

export const removeRedisCouponSuccess = (value, action) => ({
  type: ActionTypes.REMOVE_REDIS_COUPON_SUCCESS,
  value,
  meta: action.meta
});

export const removeRedisCouponError = (error, action) => ({
  type: ActionTypes.REMOVE_REDIS_COUPON_ERROR,
  error,
  meta: action.meta
});

// EMPLOYEE_REDIS_COUPON
export const employeeVerifyCoupon = (payload, meta) => ({
  type: ActionTypes.EMPLOYEE_VERIFY_COUPON,
  payload: payload,
  meta: meta
});

export const employeeVerifyCouponSuccess = (value, action) => ({
  type: ActionTypes.EMPLOYEE_VERIFY_COUPON_SUCCESS,
  value,
  meta: action.meta
});

export const employeeVerifyCouponError = (error, action) => ({
  type: ActionTypes.EMPLOYEE_VERIFY_COUPON_ERROR,
  error,
  meta: action.meta
});

export const resendOfferOtp = (payloadData, meta) => ({
  type: ActionTypes.RESEND_OFFER_OTP,
  payload: payloadData,
  meta
});

export const resendOfferOtpSuccess = (value, action) => ({
  type: ActionTypes.RESEND_OFFER_OTP_SUCCESS,
  value,
  meta: action.meta
});

export const resendOfferOtpError = (error, action) => ({
  type: ActionTypes.RESEND_OFFER_OTP_ERROR,
  error,
  payload: action.payload,
  meta: action.meta
});
// export const getOutletChange1 = (payloadData, meta) => ({
//   type: ActionTypes.GET_OUTLET_CHANGE1,
//   payload: payloadData,
//   meta
// });

// export const getOutletChange1Success = (value, action) => ({
//   type: ActionTypes.GET_OUTLET_CHANGE1_SUCCESS,
//   value,
//   meta: action.meta
// });

// export const getOutletChange1Error = (error, action) => ({
//   type: ActionTypes.GET_OUTLET_CHANGE1_ERROR,
//   error,
//   payload: action.payload,
//   meta: action.meta
// });

import * as ActionTypes from '../actionTypes';

export const pdpAddToCart = (payloadData, meta) => ({
  type: ActionTypes.PDP_ADD_TO_CART,
  payload: payloadData,
  meta
});

export const pdpAddToCartSuccess = (value, action) => ({
  type: ActionTypes.PDP_ADD_TO_CART_SUCCESS,
  value,
  meta: action.meta
});

export const pdpAddToCartError = (error, action) => ({
  type: ActionTypes.PDP_ADD_TO_CART_ERROR,
  error,
  payload: action.payload,
  meta: action.meta
});

export const plpAddToCart = (payloadData, meta) => ({
  type: ActionTypes.PLP_ADD_TO_CART,
  payload: payloadData,
  meta
});

export const plpAddToCartSuccess = (value, action) => ({
  type: ActionTypes.PLP_ADD_TO_CART_SUCCESS,
  value,
  meta: action.meta
});

export const plpAddToCartError = (error, action) => ({
  type: ActionTypes.PLP_ADD_TO_CART_ERROR,
  error,
  payload: action.payload,
  meta: action.meta
});

export const offerAddToCart = (payloadData, meta) => ({
  type: ActionTypes.OFFER_ADD_TO_CART,
  payload: payloadData,
  meta
});

export const offerAddToCartSuccess = (value, action) => ({
  type: ActionTypes.OFFER_ADD_TO_CART_SUCCESS,
  value,
  meta: action.meta
});

export const offerAddToCartError = (error, action) => ({
  type: ActionTypes.OFFER_ADD_TO_CART_ERROR,
  error,
  payload: action.payload,
  meta: action.meta
});

export const skdOfferAddToCart = (payloadData, meta) => ({
  type: ActionTypes.SKD_OFFER_ADD_TO_CART,
  payload: payloadData,
  meta
});

export const skdOfferAddToCartSuccess = (value, action) => ({
  type: ActionTypes.SKD_OFFER_ADD_TO_CART_SUCCESS,
  value,
  meta: action.meta
});

export const skdOfferAddToCartError = (error, action) => ({
  type: ActionTypes.SKD_OFFER_ADD_TO_CART_ERROR,
  error,
  payload: action.payload,
  meta: action.meta
});

export const getCheckoutCartApp = (payloadData, meta) => ({
  type: ActionTypes.CHECKOUT_CART_APP,
  payload: payloadData,
  meta
});

export const getCheckoutCartAppSuccess = (value, action) => ({
  type: ActionTypes.CHECKOUT_CART_APP_SUCCESS,
  value,
  meta: action.meta
});

export const getCheckoutCartAppError = (error, action) => ({
  type: ActionTypes.CHECKOUT_CART_APP_ERROR,
  error,
  payload: action.payload,
  meta: action.meta
});

export const getCheckoutWebCart = (payloadData, meta) => {
  return {
    type: ActionTypes.CHECKOUT_CART,
    payload: payloadData,
    meta
  };
};

export const getCheckoutWebCartSuccess = (value, action) => ({
  type: ActionTypes.CHECKOUT_CART_SUCCESS,
  value,
  meta: action.meta
});

export const getCheckoutWebCartError = (error, action) => ({
  type: ActionTypes.CHECKOUT_CART_ERROR,
  error,
  payload: action.payload,
  meta: action.meta
});

export const getOutletChange = (payloadData, meta) => ({
  type: ActionTypes.GET_OUTLET_CHANGE,
  payload: payloadData,
  meta: meta
});

export const getOutletChangeSuccess = (value, action) => ({
  type: ActionTypes.GET_OUTLET_CHANGE_SUCCESS,
  value,
  meta: action.meta
});

export const getOutletChangeError = (error, action) => ({
  type: ActionTypes.GET_OUTLET_CHANGE_ERROR,
  error,
  payload: action.payload,
  meta: action.meta
});

export const getOutletChange1 = (payloadData, meta) => ({
  type: ActionTypes.GET_OUTLET_CHANGE1,
  payload: payloadData,
  meta: meta
});

export const getLegal = (payloadData, meta) => ({
  type: ActionTypes.GET_LEGAL,
  payload: payloadData,
  meta
});

export const getLegalSuccess = (value, action) => ({
  type: ActionTypes.GET_LEGAL_SUCCESS,
  value,
  meta: action.meta
});

export const getLegalError = (error, action) => ({
  type: ActionTypes.GET_LEGAL_ERROR,
  error,
  payload: action.payload,
  meta: action.meta
});

export const getAddonsList = (payloadData, meta) => ({
  type: ActionTypes.GET_ADDONS_LIST,
  payload: payloadData,
  meta
});

export const getAddonsListSuccess = (value, action) => ({
  type: ActionTypes.GET_ADDONS_LIST_SUCCESS,
  value,
  meta: action.meta
});

export const getAddonsListError = (error, action) => ({
  type: ActionTypes.GET_ADDONS_LIST_ERROR,
  error,
  payload: action.payload,
  meta: action.meta
});

export const getAppPaymentStatus = (payloadData, meta) => ({
  type: ActionTypes.GET_APP_PAYMENT_STATUS,
  payload: payloadData,
  meta
});

export const getAppPaymentStatusSuccess = (value, action) => ({
  type: ActionTypes.GET_APP_PAYMENT_STATUS_SUCCESS,
  value,
  meta: action.meta
});

export const getAppPaymentStatusError = (error, action) => ({
  type: ActionTypes.GET_APP_PAYMENT_STATUS_ERROR,
  error,
  payload: action.payload,
  meta: action.meta
});

export const getPaymentWebStatus = (payloadData, meta) => ({
  type: ActionTypes.GET_PAYMENT_STATUS,
  payload: payloadData,
  meta
});

export const getPaymentWebStatusSuccess = (value, action) => ({
  type: ActionTypes.GET_PAYMENT_STATUS_SUCCESS,
  value,
  meta: action.meta
});

export const getPaymentWebStatusError = (error, action) => ({
  type: ActionTypes.GET_PAYMENT_STATUS_ERROR,
  error,
  payload: action.payload,
  meta: action.meta
});

export const getCharityProduct = (payloadData, meta) => ({
  type: ActionTypes.GET_CHARITY_PRODUCT,
  payload: payloadData,
  meta
});

export const getCharityProductSuccess = (value, action) => ({
  type: ActionTypes.GET_GET_CHARITY_PRODUCT_SUCCESS,
  value,
  meta: action.meta
});

export const getCharityProductError = (error, action) => ({
  type: ActionTypes.GET_GET_CHARITY_PRODUCT_ERROR,
  error,
  payload: action.payload,
  meta: action.meta
});

export const getFeedback = (payloadData, meta) => ({
  type: ActionTypes.GET_FEEDBACK,
  payload: payloadData,
  meta
});

export const getFeedbackSuccess = (value, action) => ({
  type: ActionTypes.GET_FEEDBACK_SUCCESS,
  value,
  meta: action.meta
});

export const getFeedbackError = (error, action) => ({
  type: ActionTypes.GET_FEEDBACK_ERROR,
  error,
  payload: action.payload,
  meta: action.meta
});

export const resendOfferOtp = (payloadData, meta) => ({
  type: ActionTypes.RESEND_OFFER_OTP,
  payload: payloadData,
  meta
});

export const resendOfferOtpSuccess = (value, action) => ({
  type: ActionTypes.RESEND_OFFER_OTP_SUCCESS,
  value,
  meta: action.meta
});

export const resendOfferOtpError = (error, action) => ({
  type: ActionTypes.RESEND_OFFER_OTP_ERROR,
  error,
  payload: action.payload,
  meta: action.meta
});

export const verifyOfferOtp = (payloadData, meta) => ({
  type: ActionTypes.VERIFY_OFFER_OTP,
  payload: payloadData,
  meta
});

export const verifyOfferOtpSuccess = (value, action) => ({
  type: ActionTypes.VERIFY_OFFER_OTP_SUCCESS,
  value,
  meta: action.meta
});

export const verifyOfferOtpError = (error, action) => ({
  type: ActionTypes.VERIFY_OFFER_OTP_ERROR,
  error,
  payload: action.payload,
  meta: action.meta
});

export const cancelOrder = (payloadData, meta) => ({
  type: ActionTypes.CANCEL_ORDER,
  payload: payloadData,
  meta
});

export const cancelOrderSuccess = (value, action) => ({
  type: ActionTypes.CANCEL_ORDER_SUCCESS,
  value,
  meta: action.meta
});

export const cancelOrderError = (error, action) => ({
  type: ActionTypes.CANCEL_ORDER_ERROR,
  error,
  payload: action.payload,
  meta: action.meta
});

//new controlers
// getControllers
export const getControllers = (payloadData, meta) => ({
  type: ActionTypes.GET_CONTROLLERS,
  payload: payloadData,
  meta
});

export const getControllersSuccess = (value, action) => ({
  type: ActionTypes.GET_CONTROLLERS_SUCCESS,
  value,
  meta: action.meta
});

export const getControllersError = (error, action) => ({
  type: ActionTypes.GET_CONTROLLERS_ERROR,
  error,
  payload: action.payload,
  meta: action.meta
});

export const juspayPaymentMethod = (payloadData, meta) => ({
  type: ActionTypes.JUSPAY_PAYMENT_METHOD_STATUS,
  payload: payloadData,
  meta
});

export const juspayPaymentMethodSuccess = (value, action) => ({
  type: ActionTypes.JUSPAY_PAYMENT_METHOD_STATUS_SUCCESS,
  value,
  meta: action.meta
});

export const juspayPaymentMethodError = (error, action) => ({
  type: ActionTypes.JUSPAY_PAYMENT_METHOD_STATUS_ERROR,
  error,
  payload: action.payload,
  meta: action.meta
});

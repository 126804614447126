import './index.scss';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Input from '../input';
import Button from '../button';

import { getCloseImage } from '../../utils/imageChanges';

class SaveAddress extends Component {
  constructor(props) {
    super(props);
    this.state = {
      saveAddress: props.activeSaveAsValue || ''
    };
  }

  inputChangeHandler = (id, value) => {
    const { toggleClick } = this.props;
    toggleClick('Other', value);
    this.setState({ saveAddress: value });
  };

  removeOtherSavedAs = () => {
    const { toggleClick } = this.props;
    this.setState({ saveAddress: '' });
    toggleClick('Home', 'Home');
  };

  render() {
    const {
      toggleClick,
      activeSaveAddress,
      onSaveAddressClick,
      addressValue,
      landmarkValue,
      locationValue,
      theme
    } = this.props;
    const { saveAddress } = this.state;
    return (
      <div className="save-address">
        <p className="save-address__title">Save As</p>

        {activeSaveAddress !== 'Other' ? (
          <div className="save-address__option">
            <div
              onClick={() => toggleClick('Home', 'Home')}
              role="presentation"
              className={`save-address__type ${theme} u_cursor_pointer ${
                activeSaveAddress === 'Home'
                  ? `save-address__active ${theme}`
                  : ''
              }`}
            >
              Home
            </div>
            <div
              onClick={() => toggleClick('Work', 'Work')}
              role="presentation"
              className={`save-address__type ${theme} u_cursor_pointer ${
                activeSaveAddress === 'Work'
                  ? `save-address__active ${theme}`
                  : ''
              }`}
            >
              Work
            </div>
            <div
              onClick={() => toggleClick('Other', 'Other')}
              role="presentation"
              className={`save-address__type ${theme} u_cursor_pointer ${
                activeSaveAddress === 'Other'
                  ? `save-address__active ${theme}`
                  : ''
              }`}
            >
              Other
            </div>
          </div>
        ) : (
          <div className="save-address__option">
            <div
              className={`save-address__type ${theme} save-address__other-edit ${
                activeSaveAddress === 'Other'
                  ? `save-address__active ${theme}`
                  : ''
              }`}
            >
              Other
            </div>
            <div className={`save-address__input ${theme}`}>
              <Input
                onChange={this.inputChangeHandler}
                id="save address"
                type="text"
                value={saveAddress}
              />
              <div className="save-address__close" role="presentation">
                <img
                  src={getCloseImage(theme)}
                  alt="close"
                  className="save-address__close-img u_cursor_pointer"
                  role="presentation"
                  onClick={() => this.removeOtherSavedAs()}
                />
              </div>
            </div>
          </div>
        )}
        <div className="save-address__button">
          <Button
            className="save-address__button_inactive"
            type="block"
            content="save address"
            size="is-large"
            theme={theme}
            disabled={
              !(
                activeSaveAddress &&
                addressValue &&
                landmarkValue &&
                locationValue
              ) || false
            }
            onClick={() =>
              onSaveAddressClick(
                activeSaveAddress,
                locationValue,
                landmarkValue,
                addressValue
              )
            }
          />
        </div>
      </div>
    );
  }
}

SaveAddress.propTypes = {
  toggleClick: PropTypes.func,
  activeSaveAddress: PropTypes.string
};

SaveAddress.defaultProps = {
  toggleClick: () => {},
  activeSaveAddress: ''
};

export default SaveAddress;

import * as ActionTypes from '../actionTypes';

export const getOrderHistoryList = (payloadData, meta) => ({
  type: ActionTypes.ORDER_HISTORY,
  payload: payloadData,
  meta
});

export const getOrderHistoryListSuccess = (value, action) => ({
  type: ActionTypes.ORDER_HISTORY_SUCCESS,
  value,
  meta: action.meta
});

export const getOrderHistoryListError = (error, action) => ({
  type: ActionTypes.ORDER_HISTORY_ERROR,
  error,
  payload: action.payload,
  meta: action.meta
});

// newly added feedback
export const getFeedbackStatusList = (payload, meta) => ({
  type: ActionTypes.FEEDBACK_STATUS,
  payload,
  meta
});
export const getFeedbackStatusListSuccess = (value, action, meta) => ({
  type: ActionTypes.FEEDBACK_STATUS_SUCCESS,
  value,
  meta
});
export const getFeedbackStatusListError = (error, action) => ({
  type: ActionTypes.FEEDBACK_STATUS_ERROR,
  error,
  payload: action.payload,
  meta: action.meta
});
// new optimised
export const getOrderDetails = (payloadData, meta) => ({
  type: ActionTypes.ORDER_DETAILS,
  payload: payloadData,
  meta
});

export const getOrderDetailsSuccess = (value, action) => ({
  type: ActionTypes.ORDER_DETAILS_SUCCESS,
  value,
  meta: action.meta
});

export const getOrderDetailsError = (error, action) => ({
  type: ActionTypes.ORDER_DETAILS_ERROR,
  error,
  payload: action.payload,
  meta: action.meta
});

// getTicket
export const getTicketStatusList = (payload, meta) => ({
  type: ActionTypes.TICKET_STATUS,
  payload,
  meta
});
export const getTicketStatusListSuccess = (value, action, meta) => ({
  type: ActionTypes.TICKET_STATUS_SUCCESS,
  value,
  meta
});
export const getTicketStatusListError = (error, action) => ({
  type: ActionTypes.TICKET_STATUS_ERROR,
  error,
  payload: action.payload,
  meta: action.meta
});

// new one added by web dev
export const isViewedUpdateApi = (payload, meta) => {
  return {
    type: ActionTypes.IS_VIEWED_UPDATE_API,
    payload,
    meta
  };
};
export const isViewedUpdateApiSuccess = (value, action, meta) => ({
  type: ActionTypes.IS_VIEWED_UPDATE_API_SUCCESS,
  value,
  meta
});
export const isViewedUpdateApiError = (error, action, meta) => ({
  type: ActionTypes.IS_VIEWED_UPDATE_API_ERROR,
  error,
  payload: action.payload,
  meta
});

// feedback API v2
export const feedbackApiV2 = (payload, meta) => {
  return {
    type: ActionTypes.FEEDBACK_API_V2,
    payload,
    meta
  };
};
export const feedbackApiV2Success = (value, action, meta) => ({
  type: ActionTypes.FEEDBACK_API_V2_SUCCESS,
  value,
  meta
});
export const feedbackApiV2Error = (error, action, meta) => ({
  type: ActionTypes.FEEDBACK_API_V2_ERROR,
  error,
  payload: action.payload,
  meta
});
// updateFeedbackApi
export const updateFeedbackApi = (payload, meta) => {
  return {
    type: ActionTypes.UPDATE_FEEDBACK_API,
    payload,
    meta
  };
};
export const updateFeedbackApiSuccess = (value, action, meta) => ({
  type: ActionTypes.UPDATE_FEEDBACK_API_SUCCESS,
  value,
  meta
});
export const updateFeedbackApiError = (error, action, meta) => ({
  type: ActionTypes.UPDATE_FEEDBACK_API_ERROR,
  error,
  payload: action.payload,
  meta
});

import * as ActionTypes from '../actionTypes';

export const getSignup = (payloadData, meta) => ({
  type: ActionTypes.GET_SIGNUP,
  payload: payloadData,
  meta
});

export const getSignupSuccess = (value, action) => ({
  type: ActionTypes.GET_SIGNUP_SUCCESS,
  value,
  meta: action.meta
});

export const getSignupError = (error, action) => ({
  type: ActionTypes.GET_SIGNUP_ERROR,
  error,
  payload: action.payload,
  meta: action.meta
});

export const getVerifyOtp = (payloadData, meta) => ({
  type: ActionTypes.GET_VERIFY_OTP,
  payload: payloadData,
  meta
});

export const getVerifyOtpSuccess = (value, action) => ({
  type: ActionTypes.GET_VERIFY_OTP_SUCCESS,
  value,
  meta: action.meta
});

export const getVerifyOtpError = (error, action) => ({
  type: ActionTypes.GET_VERIFY_OTP_ERROR,
  error,
  payload: action.payload,
  meta: action.meta
});

export const addAddress = (payloadData, meta) => ({
  type: ActionTypes.ADD_ADDRESS,
  payload: payloadData,
  meta
});

export const addAddressSuccess = (value, action) => ({
  type: ActionTypes.ADD_ADDRESS_SUCCESS,
  value,
  meta: action.meta
});

export const addAddressError = (error, action) => ({
  type: ActionTypes.ADD_ADDRESS_ERROR,
  error,
  payload: action.payload,
  meta: action.meta
});

export const getUpdateUser = (payloadData, meta) => ({
  type: ActionTypes.GET_UPDATE_USER,
  payload: payloadData,
  meta
});

export const getUpdateUserSuccess = (value, action) => ({
  type: ActionTypes.GET_UPDATE_USER_SUCCESS,
  value,
  meta: action.meta
});

export const getUpdateUserError = (error, action) => ({
  type: ActionTypes.GET_UPDATE_USER_ERROR,
  error,
  payload: action.payload,
  meta: action.meta
});

export const getEditUser = (payloadData, meta) => ({
  type: ActionTypes.GET_EDIT_USER,
  payload: payloadData,
  meta
});

export const getEditUserSuccess = (value, action) => ({
  type: ActionTypes.GET_EDIT_USER_SUCCESS,
  value,
  meta: action.meta
});

export const getEditUserError = (error, action) => ({
  type: ActionTypes.GET_EDIT_USER_ERROR,
  error,
  payload: action.payload,
  meta: action.meta
});

export const getUserDetail = (payloadData, meta) => ({
  type: ActionTypes.GET_USER_DETAIL,
  payload: payloadData,
  meta
});

export const getUserDetailSuccess = (value, action) => ({
  type: ActionTypes.GET_USER_DETAIL_SUCCESS,
  value,
  meta: action.meta
});

export const getUserDetailError = (error, action) => ({
  type: ActionTypes.GET_USER_DETAIL_ERROR,
  error,
  payload: action.payload,
  meta: action.meta
});

export const getUploadProfilePic = (payloadData, meta) => ({
  type: ActionTypes.UPLOAD_PROFILE_PIC,
  payload: payloadData,
  meta
});

export const getUploadProfilePicSuccess = (value, action) => ({
  type: ActionTypes.UPLOAD_PROFILE_PIC_SUCCESS,
  value,
  meta: action.meta
});

export const getUploadProfilePicError = (error, action) => ({
  type: ActionTypes.UPLOAD_PROFILE_PIC_ERROR,
  error,
  payload: action.payload,
  meta: action.meta
});
export const signUpWithEmail = (payloadData, meta) => ({
  type: ActionTypes.SIGNUP_WITH_EMAIL,
  payload: payloadData,
  meta
});

export const signUpWithEmailSuccess = (value, action) => ({
  type: ActionTypes.SIGNUP_WITH_EMAIL_SUCCESS,
  value,
  meta: action.meta
});

export const signUpWithEmailError = (error, action) => ({
  type: ActionTypes.SIGNUP_WITH_EMAIL_ERROR,
  error,
  payload: action.payload,
  meta: action.meta
});

export const generatePhoneOtp = (payloadData, meta) => ({
  type: ActionTypes.GENERATE_PHONE_OTP,
  payload: payloadData,
  meta
});

export const generatePhoneOtpSuccess = (value, action) => ({
  type: ActionTypes.GENERATE_PHONE_OTP_SUCCESS,
  value,
  meta: action.meta
});

export const generatePhoneOtpError = (error, action) => ({
  type: ActionTypes.GENERATE_PHONE_OTP_ERROR,
  error,
  payload: action.payload,
  meta: action.meta
});

// REQUESTING ACCOUNT DELETE
export const requestAccountDelete = (payloadData, meta) => ({
  type: ActionTypes.REQUESTING_ACCOUNT_DELETE,
  payload: payloadData,
  meta
});

export const requestAccountDeleteSuccess = (value, action) => ({
  type: ActionTypes.REQUESTING_ACCOUNT_DELETE_SUCCESS,
  value,
  meta: action.meta
});

export const requestAccountDeleteError = (error, action) => ({
  type: ActionTypes.REQUESTING_ACCOUNT_DELETE_ERROR,
  error,
  payload: action.payload,
  meta: action.meta
});

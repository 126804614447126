import './index.scss';

import React from 'react';

import {getCallIcon} from '../../utils/imageChanges';

const HelpCall = props => {
  const { customText,theme,onCallClick,number,className } = props;

  return (
    <div className='help-call_wrapper'>
      <div className={`help-call ${className}`} onClick={()=>onCallClick(number)} role="presentation">
        <img src={getCallIcon(theme)} alt="call" />
        <span className="help-call__text">{customText || 'Call Us'}</span>
      </div>
    </div>
  )
}

export default HelpCall;
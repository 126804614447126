/* eslint-disable no-undef */
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { ROUTES as _routes } from '../../constants/app-constants';
// import { getFeedback } from '../../burger-king-lib/redux/cart/actions';
// import { getFeedbackPayload } from '../../payload-handling/cart';
import FeedbackImage from '../../../assets/images/trackorder-on-the-way.png';
// import FeedbackOrderDetail from '../order-detail-feedback';
// import AccountSubSecTitle from '../../components/accout-subsec-title';
import './index.scss';
// import RatingsButton from '../../components/ratings-button';
import OffersToast from '../../components/offers-toast';
// import Input from '../../components/input';
import Button from '../../components/button';
// import Rate from '../../components/rate';
// import SliderComponent from '../../components/range-slider';

class Feedback extends React.Component {
  constructor() {
    super();
    this.state = {
      // user_id: SessionStorage.get(LocalSessionKey.USER_ID),
      // remarks: '',
      // rating: null,
      // ratings: null,
      // quantity: '',
      // hygiene: '',
      // ambience: '',
      // freshness: '',
      // taste: '',
      // temperature: '',
      // app: null,
      // ease: '',
      // speed: '',
      // payment: '',
      // is_right: false,
      // active: false,
      // isMobileView: this.isMobileView(),
      dataFeed: {},
      address: {},
      id: '',
      toastMessage: '',
      type: ''
      // range: 0
    };
  }

  componentDidMount = () => {
    let { dataFeed, address, id, type } = this.state;
    const { location } = this.props;
    const { state } = location;
    if (state) {
      dataFeed = state.order;
      address = dataFeed.address;
      id = state.id;
      type = dataFeed.cart_added_for;
      this.setState({ dataFeed, address, id, type });
    } else {
      const { data, orderID } = this.props;
      dataFeed = data;
      // address = data.address;

      id = orderID;
      // type = dataFeed.cart_added_for;
      this.setState({ dataFeed, address, id, type });
    }
  };

  // componentDidUpdate(prevProps) {
  //   const { feedbackData } = this.props;
  //   if (
  //     feedbackData &&
  //     feedbackData.feedback &&
  //     feedbackData.feedback !== prevProps.feedbackData.feedback
  //   ) {
  //     if (feedbackData.feedback.content && feedbackData.feedback.error === 0) {
  //       alert(feedbackData.feedback.content);
  //       this.onToastMsg();
  //     }
  //     if (feedbackData.feedback.error === 1)
  //       alert(feedbackData.feedback.message);
  //   }
  // }

  onChangeRatingButton = (id, value) => {
    this.setState({ [id]: value });
  };

  onCloseHandler = () => {
    const { onCloseHandler } = this.props;
    onCloseHandler();
  };

  // handleChange = (Id, val, e) => {
  //   const { target } = e;
  //   const { value } = target;
  //   this.setState({ remarks: value, active: true });
  // };

  onChangeRating = (id, value) => {
    this.setState({ [id]: value });
  };

  isMobileView = () => {
    if (window.innerWidth > 1023) {
      return false;
    }
    return true;
  };

  rightContentClickHandler = () => {
    const { history } = this.props;
    history.push(_routes.HELP_SUPPORT);
  };

  // onChangeSlide = value => {
  //   this.setState({ range: value });
  // };

  render() {
    const { toastMessage } = this.state;
    const { theme, constCall, fromTickets, wholeOrder } = this.props;
    const flag = !fromTickets
      ? wholeOrder?.order_detail[0].is_feedback_submitted
      : wholeOrder?.order_detai[0].is_feedback_submitted;
    // console.log(wholeOrder, 'yooo', fromTickets);
    // const { cart_added_for } = dataFeed;?.order_detai[0].is_feedback_submitted
    const Dom = (
      <>
        <div className={`feedback-form ${theme}`}>
          {/* {isMobileView && (
            <AccountSubSecTitle
              title="Rate your Experience"
              goBackOnClick={this.goBack}
              theme={theme}
              rightContent="Help"
              rightContentClick={() => this.rightContentClickHandler()}
            />
          )}
          {isMobileView && (
            <FeedbackOrderDetail isTrack data={dataFeed} theme={theme} />
          )} */}
          <div className="feedback-form_fullContent">
            <div className="feedback-form_header new-class">
              <img
                src={FeedbackImage}
                alt="burger"
                className="feedback-form__image"
              />
              <p className="feedback-form__title1">
                Hey Whopperati!
                <br />
                {!fromTickets
                  ? 'Hope you liked the Burgerking Experience?'
                  : 'We Hope you are satisfied with our Resolution'}
              </p>
            </div>

            <div className="feedback-form__submit custom-css">
              {!fromTickets ? (
                <>
                  <Button
                    theme={theme}
                    content="YES"
                    className="feedback-form__submit-btn"
                    onClick={() => constCall('open', wholeOrder)}
                  />
                  <Button
                    theme={theme}
                    content="NO"
                    className="feedback-form__submit-btn"
                    onClick={() => {
                      constCall('close', wholeOrder);
                    }}
                  />
                </>
              ) : (
                <Button
                  theme={theme}
                  // content="Rate Us"
                  content={`${
                    wholeOrder?.order_detai?.[0]?.is_feedback_submitted
                      ? 'Okay'
                      : 'Rate Us'
                  }`}
                  className="feedback-form__submit-btn"
                  onClick={() => {
                    flag ? closeRating() : constCall();
                  }}
                />
              )}
            </div>
            {toastMessage ? (
              <OffersToast
                content={toastMessage}
                destroyHanlder={this.toastDestroyHandler}
              />
            ) : (
              ''
            )}
          </div>
        </div>
      </>
    );
    return Dom;
  }
}

const mapStateToProps = state => {
  return {
    feedbackData: state.feedback
  };
};

const mapDispatchToProps = dispatch => {
  return {
    // getFeedbackApiData: payloadData => dispatch(getFeedback(payloadData))
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Feedback)
);

import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import './index.scss';
import Footer from '../../containers/footer';
import { getUserData, getUserID } from '../../utils/services';
import HelpCard from '../../components/help-card';
import { ROUTES as _routes } from '../../constants/app-constants';
import AccountSubSecTitle from '../../components/accout-subsec-title';
import { requestAccountDelete } from '../../burger-king-lib/redux/login/actions';

const HelpSupportPage = props => {
  const history = useHistory();
  const deleteAccountresponse = useSelector(
    state => state?.login?.deteleUserResponse
  );
  const dispatch = useDispatch();
  const [userData, setUserData] = useState();
  const { isSeparatePage, theme } = props;
  const helpList = [
    {
      id: 1,
      title: 'How do I contact Customer Care?',
      description:
        'You can contact by writing to us at <a href="mailto:customercare@burgerking.in">customercare@burgerking.in</a>'
    },
    {
      id: 2,
      title: 'Do I need to register to place an order?',
      description:
        'Yes, you need to register on the Burger King Application (App)/ Burger King Website to place an order using your Mobile Number'
    },
    {
      id: 3,
      title: 'Where can I place the order online?',
      description:
        'You can place an online order through Burger King Application (App)/ Burger King Website or food aggregator platform'
    },
    {
      id: 4,
      title: 'I did not get OTP code on my mobile?',
      description:
        'Sometimes there could be a delay in receiving the OTP due to network congestion. If you still haven’t received it within a few minutes, please email us at <a href="mailto:customercare@burgerking.in">customercare@burgerking.in</a> and we will help you out.'
    },
    {
      id: 5,
      title: 'What are your operating hours?',
      description:
        'Operating hours vary from store to store, an you can check the operating time of your neighbourhood store by logging in to the app.'
    },
    {
      id: 6,
      title: 'Is there a minimum order value?',
      description: "No, we don't have a minimum order value."
    },
    {
      id: 7,
      title: 'Why are there no tomatoes in my burgers?',
      description:
        'We, at Restaurant Brands Asia Limited have very high standards of quality as we believe in serving real and authentic food. Due to unpredictable conditions on the quality &amp; supply of tomato crops, we are unable to add tomatoes to our food. Rest assured, our tomatoes will be back soon. Till then we request your patience and understanding.'
    },
    {
      id: 8,
      title: 'Do you charge for delivery?',
      description: 'Yes, we charge a delivery fee.'
    },
    {
      id: 9,
      title: 'How much time do you take to delivery?',
      description:
        'We strive to deliver your orders soon as possible, however, it should reach you within a maximum of 40 minutes.'
    },
    {
      id: 10,
      title: 'Can I order in advance?',
      description: 'No, you cannot order in advance.'
    },
    {
      id: 11,
      title: 'How can I add special instructions to my Order?',
      description:
        'You can tell us your preference in the ‘Special Instructions’ section available at order confirmation.'
    },
    {
      id: 12,
      title: 'Where can I get offers/coupons?',
      description:
        'You can see your offers coupons by opening the offers section in the app.'
    },
    {
      id: 13,
      title: 'Can I redeem multiple coupons in a single order?',
      description: 'Sorry, you cannot club multiple coupons in a single order.'
    },
    {
      id: 14,
      title: 'How do I earn crowns on the Restaurant Brands Asia Limited App?',
      description:
        'You can earn crowns on every order you place via the app. Crowns will be credited to the account within 48 hrs.',
      subDescription:
        'You can win more crowns by playing the game on the BK App.'
    },
    {
      id: 15,
      title: 'What can I do with the crowns that I earned?',
      description:
        'Crowns can be redeemed only against Burger King exciting deals (King deals – Crown Rewards section) on the App.'
    },
    {
      id: 16,
      title: 'Do earned crowns have an expiry?',
      description:
        'Crowns earned before August 1, 2021 are valid for 365 days from the date of earning them.',
      subDescription:
        'Crowns earned after August 1, 2021 are valid for 180 days from the date of earning them.'
    },
    {
      id: 17,
      title: 'Can I Cancel my Order ?',
      description:
        'You can cancel your Order only within one-minute (i.e 60 seconds) of the order being placed on app/website.',
      subDescription:
        'For Cancellation and Refund refer to Terms &amp; Conditions and Refund section (Click and review)'
    },
    {
      id: 18,
      title: 'I am facing issue with APP/Site?',
      description:
        'Please email us on <a href="mailto:customercare@burgerking.in">customercare@burgerking.in</a> along with details of your problem and the device you are using, and we will look into it.'
    },
    {
      id: 19,
      title:
        'Are there all ingredients(vegetables) available to fillings in my Burger? ',
      description:
        'Yes, we have very high standards of quality as we believe in serving fresh food. However, at times due to unpredictable seasonal conditions and depending on the quality and supply of crops / vegetables, we may temporarily discontinue certain ingredients in your Burger.'
    },
    {
      id: 20,
      title: 'What are the charges applicable on dine-in/takeaway?',
      description: 'We charge applicable taxes on dine-in/takeaway.'
    },
    {
      id: 21,
      title: 'Do you deliver products? And what are the charges applicable?',
      description:
        'Yes, we do provide delivery of our products through food aggregators and BK application. We charge applicable taxes and Restaurant/ Handling Charges. Please note that Restaurant / Handling Charges are charges for providing additional services and costs incurred by the Company for offering the food articles/products through its application/ food aggregator platform, which includes, but is not limited to platform infrastructure setup, recurring operating/labor cost and capital cost of infrastructure.'
    },
    {
      id: 22,
      title: 'Do You charge service tax on order(s)?',
      description:
        'No we do not charge any service tax on orders placed online or offline.'
    },
    {
      id: 23,
      title: 'What should I do if the application will not work?',
      description:
        'If this happens, we would recommend: Restart your phone as a first option. If this does not work, you could uninstall your app and again install the same.Lastly, contact customer services for help at <a href="mailto:customercare@burgerking.in">customercare@burgerking.in</a>'
    },
    {
      id: 24,
      title: 'Why does Burger King want to know my phone number?',
      description:
        'The phone number enables us to identify you as a unique guest and helps us avoid the creation of multiple accounts. Burger King will never use it for commercial purposes.'
    },
    {
      id: 25,
      title:
        'The amount was debited from my account but the transaction failed?',
      description:
        'You need not worry. Your amount will be refunded back to your source account. Refund takes up to 5-7 business days from the date of refund to reflect on your bank account.'
    },
    {
      id: 26,
      title: 'Do you charge for carry bags?',
      description:
        'Carry Bags with handles are provided on a chargeable basis. Carry Bags are available on request at all Burger King restaurants and can also be selected from BK App while placing an order at the check-out page.'
    },
    {
      id: 27,
      title: 'Do we have no onion and no garlic(NONG) restaurants in India?',
      description: 'Yes, we have selected NONG restaurants in India.'
    },
    {
      id: 28,
      title: 'Can a customer modify/cancel the online order?',
      description:
        "The online order cannot be modified. Cancellation of Order by customer is applicable only for Cash-on-Delivery Orders and in selected stores only.<br><br> Cancellation of order by Customer will not be chargeable within 60 seconds of order placement. Cancellation of Order by Customer is applicable for orders placed via Burger King Application/ Website and is not applicable for orders placed from any other source.<br><br> Cash on delivery option will be disabled for the next order upon cancellation of order beyond 60 seconds. Cash payment will be enabled after successful delivery of next prepaid order.<br><br> Cancellation of order will not be allowed once the delivery executive reaches the Customer Location (first barrier point). You can get updated on the status of the order by calling the restaurant directly.<br><br> In case the order which is paid through digital payment method is cancelled due to non-availability of the ordered product at the restaurant, the amount will be returned by reversing the transaction by Burger King's restaurant.This refund will be returned on the actual source within 5 to 7 working days.However, RBAL's is not responsible for any delay on the bank side."
    },
    {
      id: 29,
      title: 'Do we have in-store table ordering through QR code?',
      description:
        'Yes, we have in-store table ordering through QR code in the selected restaurants. Please refer to the term and conditions for further information.'
    },
    {
      id: 30,
      title: 'How can I request for account deletion?',
      description:
        'You can request for account deletion by clicking the below request button/text.'
    }
  ];

  const goBackOnClick = () => {
    history.goBack();
  };

  const onTermsClick = () => {
    const { isSeparatePage, history } = props;
    if (isSeparatePage) {
      history.push(_routes.TERMS);
    } else {
      history.push(_routes.TERMS_AND_CONDITIONS);
    }
  };

  const onClickAccountDelete = () => {
    const payload = {
      user_id: getUserID()
    };
    dispatch(requestAccountDelete(payload));
  };

  useEffect(() => {
    const userData = getUserData();
    setUserData(userData);
  }, []);

  return (
    <>
      <div
        className={`help-pg_wrapper ${isSeparatePage ? 'help-pg_full' : ''}`}
      >
        {!isSeparatePage && (
          <AccountSubSecTitle
            title="FAQ's & Support"
            goBackOnClick={goBackOnClick}
            theme={theme}
          />
        )}
        <div className="help-pg__card">
          {isSeparatePage && <AccountSubSecTitle title="FAQ's & Support" />}
          <p className="help-pg__title">How can we help?</p>
          {helpList && helpList.length
            ? helpList.map(data => {
                return (
                  <HelpCard
                    data={data}
                    theme={theme}
                    userData={userData}
                    onClickAccountDelete={onClickAccountDelete}
                    deleteAccountresponse={deleteAccountresponse}
                  />
                );
              })
            : ''}
        </div>
        {isSeparatePage && <Footer />}
      </div>
    </>
  );
};

export default HelpSupportPage;

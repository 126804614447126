import HamburgerLogo from '../../assets/images/hamburger.svg';
import HamburgerRestuarantLogo from '../../assets/images/hamburger-restaurant.svg';

import LocationLogo from '../../assets/images/location.svg';
import LocationRestaurantLogo from '../../assets/images/location-restaurant.svg';

import SearchLogo from '../../assets/images/search.svg';
import SearchRestaurantLogo from '../../assets/images/search-restaurant.svg';

import RightArrow from '../../assets/images/right-arrow.svg';
import RightRestaurantArrow from '../../assets/images/right-arrow-restaurant.svg';
 
import LeftArrow from '../../assets/images/left-arrow.svg';
import LeftRestaurantArrow from '../../assets/images/left-arrow-restaurant.svg';

import TopArrow from '../../assets/images/top-arrow.svg';
import TopArrowRestaurant from '../../assets/images/top-arrow-restaurant.svg';

import DetectLocation from '../../assets/images/detect-location.svg';
import DetectLocationRestaurant from '../../assets/images/detect-location-restaurant.svg';

import MinusSign from '../../assets/images/minus-sign.svg';
import MinusSignRestaurant from '../../assets/images/minus-sign-restaurant.svg';

import PlusSign from '../../assets/images/plus-sign.svg';
import PlusSignRestaurant from '../../assets/images/plus-sign-restaurant.svg';

import CloseSign from '../../assets/images/close-orange-symbol.svg';
import CloseSignRestaurant from '../../assets/images/close-symbol-restaurant.svg';

import cartLogo from '../../assets/images/cart-orange.svg';
import cartRestaurantLogo from '../../assets/images/cart-restaurant.svg';

import loginLogo from '../../assets/images/login-active.svg';
import loginRestaurantLogo from '../../assets/images/login-restaurant.svg';

import bkWallActiveLogo from '../../assets/images/bk-wall-icon-active.svg';

import PayCashImage from '../../assets/images/pay-cash.svg';
import PayCashRestaurantImage from '../../assets/images/pay-cash-brown.svg';

import DeliveryImage from '../../assets/images/delivery.svg';
import RestaurantImage from '../../assets/images/dine-in-brown.svg';

import RecentOrder from '../../assets/images/recent-order.svg';
import RecentOrderRestaurant from '../../assets/images/recent-order-restaurant.svg';

import GiftCard from '../../assets/images/gift-icon.svg';
import GiftCardRestaurant from '../../assets/images/gift-icon-restaurant.svg';

import UserImage from '../../assets/images/user-image.svg';
import UserImageRestaurant from '../../assets/images/user-image-restaurant.svg';

import UserEditImageIcon from '../../assets/images/user-edit-image.svg';
import UserEditImageRestaurantIcon from '../../assets/images/user-edit-image-restaurant.svg';

import EditIcon from '../../assets/images/edit.svg';
import EditRestaurantIcon from '../../assets/images/edit-restaurant.svg';

import UserTagImage from '../../assets/images/user-name-tag-empty.svg';
import UserTagImageRestaurant from '../../assets/images/user-tag-brown.svg';

import CalendarIcon from '../../assets/images/calendar.svg';
import CalendarRestaurantIcon from '../../assets/images/calendar-restaurant.svg';

import RestaurantFinderIcon from '../../assets/images/dine-in.svg';
import RestaurantFinderRestaurantIcon from '../../assets/images/dine-in-brown.svg';

import CallIcon from '../../assets/images/call-icon.svg';
import CallIconRestaurant from '../../assets/images/call-icon-restaurant.svg';

import DirectionLocation from '../../assets/images/location-direction.svg';
import DirectionLocationRestaurant from '../../assets/images/location-direction-restaurant.svg';

import VoucherIcon from '../../assets/images/voucher-icon.svg';
import VoucherIconRestaurant from '../../assets/images/voucher-icon-restaurant.svg';

import NotificationIcon from '../../assets/images/notification-icon.svg';
import NotificationIconRestaurant from '../../assets/images/notification-icon-restaurant.svg';

import SupportIcon from '../../assets/images/support-icon.svg';
import SupportIconRestaurant from '../../assets/images/support-icon-restaurant.svg';

import HomeIcon from '../../assets/images/home.svg';
import HomeIconRestaurant from '../../assets/images/home-restaurant.svg';

import OfferIcon from '../../assets/images/offers/offers-orange.svg';
import OfferIconRestaurant from '../../assets/images/offers/offers-restaurant.svg';

import CloseIcon from '../../assets/images/close-icon.svg';
import CloseIconRestaurant from '../../assets/images/close-icon-restaurant.svg';

import SettingsIcon from '../../assets/images/settings-orange.svg';
import SettingsIconRestaurant from '../../assets/images/settings-restaurant.svg';

import DineInIcon from '../../assets/images/dine-in.svg';

import DeliveryIconRestaurant from '../../assets/images/delivery-brown.svg';

import ParkingIcon from '../../assets/images/parking.svg';
import ParkingIconRestaurant from '../../assets/images/parking-brown.svg';

import TakeAwayIcon from '../../assets/images/take-away.svg';
import TakeAwayIconRestaurnt from '../../assets/images/take-away-brown.svg';

import MenuIcon from '../../assets/images/menu-delivery.svg';
import MenuIconRestaurnt from '../../assets/images/menu-dinein.svg';

import PlayIcon from '../../assets/images/play-icon.svg';
import PlayIconRestaurant from '../../assets/images/play-icon-restaurant.svg';
import PayOnlineImage from '../../assets/images/pay-online.svg';
import PayOnlineRestaurantImage from '../../assets/images/pay-online-restaurant.svg';


import { THEME } from '../constants/app-constants';

export function getLocationInput(value) {
  if (value === THEME.RESTAURANT) return LocationRestaurantLogo;
  return LocationLogo;
}

export function getDirectionImage(value) {
  if (value === THEME.RESTAURANT) return DirectionLocationRestaurant;
  return DirectionLocation;
}
export function getHomeIcon(value) {
  if (value === THEME.RESTAURANT) return HomeIconRestaurant;
  return HomeIcon;
}
export function getHamburger(value) {
  if (value === THEME.RESTAURANT) return HamburgerRestuarantLogo;
  return HamburgerLogo;
}

export function getSearchImage(value) {
  if (value === THEME.RESTAURANT) return SearchRestaurantLogo;
  return SearchLogo;
}

export function getRightArrowImage(value) {
  if (value === THEME.RESTAURANT) return RightRestaurantArrow;
  return RightArrow;
}

export function getTopArrowImage(value) {
  if (value === THEME.RESTAURANT) return TopArrowRestaurant;
  return TopArrow;
}

export function getDetectLocationImage(value) {
  if (value === THEME.RESTAURANT) return DetectLocationRestaurant;
  return DetectLocation;
}

export function getLeftArrowImage(value) {
  if (value === THEME.RESTAURANT) return LeftRestaurantArrow;
  return LeftArrow;
}

export function getMinusImage(value) {
  if (value === THEME.RESTAURANT) return MinusSignRestaurant;
  return MinusSign;
}

export function getPlusImage(value) {
  if (value === THEME.RESTAURANT) return PlusSignRestaurant;
  return PlusSign;
}

export function getCloseImage(value) {
  if (value === THEME.RESTAURANT) return CloseSignRestaurant;
  return CloseSign;
}

export function getCloseIcon(value) {
  if (value === THEME.RESTAURANT) return CloseIconRestaurant;
  return CloseIcon;
}

export function getCartActiveImage(value) {
  if (value === THEME.RESTAURANT) return cartRestaurantLogo;
  return cartLogo;
}

export function getLoginActiveImage(value) {
  if (value === THEME.RESTAURANT) return loginRestaurantLogo;
  return loginLogo;
}

export function getPayCashImage(value) {
  if (value === THEME.RESTAURANT) return PayCashRestaurantImage;
  return PayCashImage;
}

export function getThemeImage(value) {
  if (value === THEME.RESTAURANT) return RestaurantImage;
  return DeliveryImage;
}

export function getRecentOrdersImage(value) {
  if (value === THEME.RESTAURANT) return RecentOrderRestaurant;
  return RecentOrder;
}

export function getGiftCardImage(value) {
  if (value === THEME.RESTAURANT) return GiftCardRestaurant;
  return GiftCard;
}

export function getNotificationImage(value) {
  if (value === THEME.RESTAURANT) return NotificationIconRestaurant;
  return NotificationIcon;
}

export function getVoucherImage(value) {
  if (value === THEME.RESTAURANT) return VoucherIconRestaurant;
  return VoucherIcon;
}

export function getSupportImage(value) {
  if (value === THEME.RESTAURANT) return SupportIconRestaurant;
  return SupportIcon;
}

export function getUserImage(value) {
  if (value === THEME.RESTAURANT) return UserImageRestaurant;
  return UserImage;
}

export function getUserEditImage(value) {
  if (value === THEME.RESTAURANT) return UserEditImageRestaurantIcon;
  return UserEditImageIcon;
}

export function getEditImage(value) {
  if (value === THEME.RESTAURANT) return EditRestaurantIcon;
  return EditIcon;
}

export function getCalendarImage(value) {
  if (value === THEME.RESTAURANT) return CalendarRestaurantIcon;
  return CalendarIcon;
}

export function getRestaurantFinderImage(value) {
  if (value === THEME.RESTAURANT) return RestaurantFinderRestaurantIcon;
  return RestaurantFinderIcon;
}

export function getCallIcon(value) {
  if (value === THEME.RESTAURANT) return CallIconRestaurant;
  return CallIcon;
}

export function getOfferActiveImage(value) {
  if (value === THEME.RESTAURANT) return OfferIconRestaurant;
  return OfferIcon;
}

export function getBkWallActiveImage(value){
  if (value) return bkWallActiveLogo;
}

export function getSettingsIcon(value) {
  if (value === THEME.RESTAURANT) return SettingsIconRestaurant;
  return SettingsIcon;
}

export function getPlayIcon(value) {
  if (value === THEME.RESTAURANT) return PlayIconRestaurant;
  return PlayIcon;
}

export function getPayOnlineImage(value) {
  if (value === THEME.RESTAURANT) return PayOnlineRestaurantImage;
  return PayOnlineImage;
}

export function getUserTagImage(value) {
  if (value === THEME.RESTAURANT) return UserTagImageRestaurant;
  return UserTagImage;
}

export function getDineIcon(value) {
  if (value === THEME.RESTAURANT) return RestaurantImage;
  return DineInIcon;
}

export function getDeliveryIcon(value) {
  if (value === THEME.RESTAURANT) return DeliveryIconRestaurant;
  return DeliveryImage;
}

export function getParkingIcon(value) {
  if (value === THEME.RESTAURANT) return ParkingIconRestaurant;
  return ParkingIcon;
}

export function getTakeawayIcon(value) {
  if (value === THEME.RESTAURANT) return TakeAwayIconRestaurnt;
  return TakeAwayIcon;
}

export function getMenuIcon(value) {
  if (value === THEME.RESTAURANT) return MenuIconRestaurnt;
  return MenuIcon;
}
/* eslint-disable no-unused-expressions */
import './index.scss';

import React, { Component } from 'react';

import ToolTip from '../tooltip';

import InfoCharcoalIcon from '../../../assets/images/info-charcoal.svg';

import { getPriceDelimitedString } from '../../utils/services';
import OrderCancel from '../order-cancel-payment';

class OrderPayment extends Component {
  constructor() {
    super();
    this.state = {
      payListData: [
        {
          name: 'Order Total',
          price: '',
          api_key: 'total_price',
          applicable_for: ['delivery', 'takeaway', 'dinein']
        },
        {
          name: 'Discount',
          price: '',
          api_key: 'discount',
          applicable_for: ['delivery', 'takeaway', 'dinein']
        },
        {
          name: 'Delivery Fee',
          price: '',
          api_key: 'delivery_charges',
          applicable_for: ['delivery']
        },
        {
          name: 'Taxes and Charges',
          price: '',
          info: true,
          api_key: 'total_tax_amount',
          applicable_for: ['delivery', 'takeaway', 'dinein']
        }
      ]
    };
  }

  componentDidMount = () => {
    const { toolTipData, isShowCoupon } = this.props;
    const { coupon_code, offer_title } = toolTipData;
    if (coupon_code && !isShowCoupon) {
      const payList = [
        {
          name: 'Order Total',
          price: '',
          api_key: 'total_price',
          applicable_for: ['delivery', 'takeaway', 'dinein']
        },
        {
          name: 'Delivery Fee',
          price: '',
          api_key: 'delivery_charges',
          applicable_for: ['delivery']
        },
        {
          name: 'Taxes and Charges',
          price: '',
          info: true,
          api_key: 'total_tax_amount',
          applicable_for: ['delivery', 'takeaway', 'dinein']
        }
      ];
      this.setState({ payListData: payList });
    }
  };

  getDataList = () => {
    const { toolTipData } = this.props;
    let data = [];
    if (toolTipData) {
      const { taxes = {} } = toolTipData;
      const newTooltipData = Object.values(taxes);
      if (newTooltipData && newTooltipData.length) {
        data = newTooltipData.map((content, index) => {
          return (
            <div key={index.toString()}>
              {content.value != 0 && (
                <div className="tooltip__image__tax">
                  <div className="tooltip__image__tax-sgst">
                    {content.name}
                    <span className="tooltip__tax-rupees">
                      ₹ {content.value}
                    </span>
                  </div>
                </div>
              )}
            </div>
          );
        });
      }
    }
    return data;
  };

  getCartAddedFor = (cart_added_for, theme, typeOfOrder) => {
    if (theme === 'takeaway' && typeOfOrder) {
      if (typeOfOrder === 'restaurant') typeOfOrder = 'dinein';
      return typeOfOrder;
    }
    return cart_added_for;
  };

  render() {
    const {
      toolTipData = {
        cart_added_for: '',
        coupon_code: '',
        discount: 0,
        charity_amount: 2
      },
      typeOfOrder,
      theme,
      isShowCoupon,
      onTermsConditonClick,
      recentData,
      global = {
        total_payable_amount: 0,
        free_products_id_master: []
      }
    } = this.props;
    const { cart_added_for = '', coupon_code = '', discount = 0 } = toolTipData;
    const { total_payable_amount } = global;
    // console.log(this.props, 'total_payable_amount');
    const { payListData } = this.state;
    const cartAddedFor = this.getCartAddedFor(
      cart_added_for,
      theme,
      typeOfOrder
    );

    return (
      <>
        <div className="order-payment">
          <ul className="u_list_style_type_none order-payment__pay-list">
            {coupon_code && !isShowCoupon ? (
              <>
                <li className="order-payment__pay-list-item ">
                  <div className="order-payment__pay-list-name order-payment__pay-list-name repeatCustomizationCloseHandlery-list-name">
                    Applied Coupon Code
                  </div>
                  <div className="order-payment__pay-list-price order-payment__pay-list-price">
                    {coupon_code}
                  </div>
                </li>
                <li className="order-payment__pay-list-item2">
                  <div className="item2-wholeDiv">
                    <p className="item2-wholeDiv_phrase">Discount</p>
                    <p className="item2-wholeDiv_amount">
                      - ₹ {toolTipData.discount}
                    </p>
                  </div>
                </li>
              </>
            ) : (
              ''
            )}
            {payListData.map(data1 => {
              const { name, info, applicable_for, api_key } = data1;
              const totalOrderAmount =
                // toolTipData?.free_products_id_master?.length > 0
                //   ?
                toolTipData.free_product_coupon_exists
                  ? toolTipData.total_taxable_amount -
                    toolTipData.delivery_charges -
                    toolTipData.packaging_charges +
                    toolTipData.savings_amount +
                    toolTipData.discount
                  : toolTipData.total_taxable_amount -
                    toolTipData.delivery_charges -
                    toolTipData.packaging_charges +
                    toolTipData.savings_amount;
              // : toolTipData.total_taxable_amount -
              //   toolTipData.delivery_charges -
              //   toolTipData.packaging_charges;
              const totalTax =
                toolTipData.total_tax + toolTipData.packaging_charges;
              const totalTax1 =
                toolTipData.total_tax_amount + toolTipData.packing_charges;

              if (applicable_for && applicable_for.indexOf(cartAddedFor) > -1)
                return (
                  <>
                    {name === 'Discount' &&
                    toolTipData.discount == 0 &&
                    toolTipData.savings_amount == 0 ? (
                      ''
                    ) : (
                      <li className="order-payment__pay-list-item">
                        <div className="order-payment__pay-list-name repeatCustomizationCloseHandlery-list-name">
                          {name}
                          {info && (
                            <ToolTip
                              position="left"
                              id="reference"
                              content={this.getDataList()}
                              name={
                                this.getDataList()?.[2]?.props?.children ===
                                false
                              }
                            >
                              <img
                                className="order-payment__pay-list-info u_cursor_pointer"
                                src={InfoCharcoalIcon}
                                alt="InfoCharcoalIcon"
                              />
                            </ToolTip>
                          )}
                        </div>
                        <div
                          className={`order-payment__pay-list-price ${
                            api_key === 'discount' ? 'discount' : ''
                          }`}
                        >
                          {api_key === 'discount' ? (
                            <span className="order-payment__pay-list-currency">
                              - ₹
                            </span>
                          ) : (
                            <span className="order-payment__pay-list-currency">
                              ₹
                            </span>
                          )}

                          {!recentData
                            ? name === 'Order Total'
                              ? getPriceDelimitedString(totalOrderAmount)
                              : api_key === 'total_tax_amount'
                              ? getPriceDelimitedString(totalTax)
                              : api_key === 'discount'
                              ? getPriceDelimitedString(
                                  toolTipData[api_key] +
                                    toolTipData['savings_amount']
                                )
                              : getPriceDelimitedString(toolTipData[api_key])
                            : api_key === 'total_tax_amount'
                            ? getPriceDelimitedString(totalTax1)
                            : api_key === 'total_price'
                            ? toolTipData[api_key] + toolTipData['discount']
                            : toolTipData[api_key]}
                        </div>
                      </li>
                    )}
                  </>
                );
              return '';
            })}
          </ul>

          <div className="order-payment__total-pay">
            <p>TOTAL PAYABLE</p>
            <p>
              <span className="order-payment__total-pay-currency">₹</span>
              {!recentData
                ? getPriceDelimitedString(total_payable_amount)
                : getPriceDelimitedString(toolTipData?.total_payable_amount)}
            </p>
          </div>
        </div>
        {isShowCoupon && (
          <OrderCancel onTermsConditonClick={onTermsConditonClick} />
        )}

        {coupon_code ? (
          <div className="order-payment__save-card-wrapper"></div>
        ) : (
          ''
        )}

        <div className="order-payment__pattern" />
      </>
    );
  }
}

export default OrderPayment;

import {
  GET_HOME_API_LIST,
  GET_HOME_API_LIST_SUCCESS,
  GET_HOME_API_LIST_ERROR,
  GET_MENU_LIST,
  GET_MENU_LIST_SUCCESS,
  GET_MENU_LIST_ERROR,
  GET_VERSION_CONTROL,
  GET_VERSION_CONTROL_SUCCESS,
  GET_VERSION_CONTROL_ERROR,
  GET_TOKEN,
  GET_TOKEN_SUCCESS,
  GET_TOKEN_ERROR,
  GET_CATEGORY,
  GET_CATEGORY_SUCCESS,
  GET_CATEGORY_ERROR,
  GET_SUBCATEGORY,
  GET_SUBCATEGORY_SUCCESS,
  GET_SUBCATEGORY_ERROR,
  GET_NUTRITIONAL,
  GET_NUTRITIONAL_SUCCESS,
  GET_NUTRITIONAL_ERROR,
  GET_OUTLETDATA,
  GET_OUTLETDATA_ERROR,
  GET_OUTLETDATA_SUCCESS,
  GET_INVESTORTREEDATA,
  GET_INVESTORTREEDATA_SUCCESS,
  GET_INVESTORTREEDATA_ERROR
} from '../actionTypes';

const initialState = {
  homeApiList: {},
  menuList: []
};

export default function HomeReducer(state = initialState, action) {
  switch (action.type) {
    case GET_HOME_API_LIST: {
      return {
        ...state,
        Payload: action.payload,
        action: action.type,
        homeApiList: {},
        Error: null,
        meta: action.meta
      };
    }
    case GET_HOME_API_LIST_SUCCESS: {
      return {
        ...state,
        Payload: {},
        action: action.type,
        homeApiList: action.value,
        Error: null,
        meta: action.meta
      };
    }
    case GET_HOME_API_LIST_ERROR: {
      return {
        ...state,
        Payload: {},
        action: action.type,
        homeApiList: {},
        Error: action.error,
        meta: action.meta
      };
    }

    case GET_MENU_LIST: {
      return {
        ...state,
        Payload: action.payload,
        action: action.type,
        menuList: [],
        Error: null,
        meta: action.meta
      };
    }
    case GET_MENU_LIST_SUCCESS: {
      return {
        ...state,
        Payload: {},
        action: action.type,
        menuList: action && action.value && action.value.getHomeOverAllResponse,
        meta: action.meta,
        Error: null
      };
    }
    case GET_MENU_LIST_ERROR: {
      return {
        ...state,
        Payload: action.payload,
        action: action.type,
        menuList: [],
        meta: action.meta,
        Error: action.error
      };
    }

    case GET_VERSION_CONTROL: {
      return {
        ...state,
        Payload: action.payload,
        action: action.type,
        versionControl: {},
        Error: null,
        meta: action.meta
      };
    }
    case GET_VERSION_CONTROL_SUCCESS: {
      return {
        ...state,
        Payload: {},
        action: action.type,
        versionControl: action.value,
        Error: null,
        meta: action.meta
      };
    }
    case GET_VERSION_CONTROL_ERROR: {
      return {
        ...state,
        Payload: {},
        action: action.type,
        versionControl: {},
        Error: action.error,
        meta: action.meta
      };
    }

    case GET_TOKEN: {
      return {
        ...state,
        Payload: action.payload,
        action: action.type,
        data: {},
        Error: null,
        meta: action.meta
      };
    }
    case GET_TOKEN_SUCCESS: {
      return {
        ...state,
        Payload: {},
        action: action.type,
        data: action.value,
        Error: null,
        meta: action.meta
      };
    }
    case GET_TOKEN_ERROR: {
      return {
        ...state,
        Payload: {},
        action: action.type,
        data: {},
        Error: action.error,
        meta: action.meta
      };
    }

    case GET_CATEGORY: {
      return {
        ...state,
        Payload: action.payload,
        action: action.type,
        category: [],
        Error: null,
        meta: action.meta
      };
    }

    case GET_INVESTORTREEDATA: {
      return {
        ...state,
        Payload: action.payload,
        action: action.type,
        category: [],
        Error: null,
        meta: action.meta
      };
    }

    case GET_CATEGORY_SUCCESS: {
      return {
        ...state,
        Payload: {},
        action: action.type,
        category: action.value,
        Error: null,
        meta: action.meta
      };
    }

    case GET_INVESTORTREEDATA_SUCCESS: {
      return {
        ...state,
        Payload: {},
        action: action.type,
        investorTreeList: action.value,
        Error: null,
        meta: action.meta
      };
    }

    case GET_CATEGORY_ERROR: {
      return {
        ...state,
        Payload: action.payload,
        action: action.type,
        category: [],
        Error: action.error,
        meta: action.meta
      };
    }

    case GET_INVESTORTREEDATA_ERROR: {
      return {
        ...state,
        Payload: action.payload,
        action: action.type,
        category: [],
        Error: action.error,
        meta: action.meta
      };
    }

    case GET_SUBCATEGORY: {
      return {
        ...state,
        Payload: action.payload,
        action: action.type,
        subCategory: [],
        Error: null,
        meta: action.meta
      };
    }
    case GET_SUBCATEGORY_SUCCESS: {
      return {
        ...state,
        Payload: {},
        action: action.type,
        subCategory: action.value,
        Error: null,
        meta: action.meta
      };
    }
    case GET_SUBCATEGORY_ERROR: {
      return {
        ...state,
        Payload: action.payload,
        action: action.type,
        subCategory: [],
        Error: action.error,
        meta: action.meta
      };
    }

    case GET_NUTRITIONAL: {
      return {
        ...state,
        Payload: action.payload,
        action: action.type,
        nutrition: {},
        Error: null,
        meta: action.meta
      };
    }
    case GET_NUTRITIONAL_SUCCESS: {
      return {
        ...state,
        Payload: {},
        action: action.type,
        nutrition: action.value,
        Error: null,
        meta: action.meta
      };
    }
    case GET_NUTRITIONAL_ERROR: {
      return {
        ...state,
        Payload: action.payload,
        action: action.type,
        nutrition: {},
        Error: action.error,
        meta: action.meta
      };
    }

    case GET_OUTLETDATA: {
      return {
        ...state,
        Payload: action.payload,
        action: action.type,
        outletData: [],
        Error: null,
        meta: action.meta
      };
    }
    case GET_OUTLETDATA_SUCCESS: {
      return {
        ...state,
        Payload: {},
        action: action.type,
        outletData: action.value,
        Error: null,
        meta: action.meta
      };
    }
    case GET_OUTLETDATA_ERROR: {
      return {
        ...state,
        Payload: action.payload,
        action: action.type,
        outletData: [],
        Error: action.error,
        meta: action.meta
      };
    }

    default: {
      return {
        ...state
      };
    }
  }
}

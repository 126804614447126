import './index.scss';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import BurgerImage from '../../../assets/images/burger-image.png';
import BurgerExpandedImage from '../../../assets/images/burger-expand.png';

class NotificationCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isExpand: false
    };
  }

  notificationClick = () => {
    this.setState({ isExpand: true });
  };

  render() {
    const { data } = this.props;
    const { isExpand } = this.state;
    return (
      <div
        className="notification-card"
        onClick={this.notificationClick}
        role="presentation"
      >
        <div className="notification-card__card u_cursor_pointer">
          <div className={`${isExpand ? '' : 'notification-card__details'}`}>
            <p
              className={`notification-card__description ${
                isExpand ? 'notification-card__description__full' : ''
              }`}
            >
              {data.text}
            </p>
            {isExpand ? (
              <div className="notification-card__expandimage">
                <img
                  src={BurgerExpandedImage}
                  alt="burger"
                  className="notification-card__expand-img"
                />
              </div>
            ) : (
              ''
            )}
            <p className="notification-card__timings">
              18 December 2019, 12:05pm
            </p>
          </div>
          <div>
            {isExpand ? (
              ''
            ) : (
              <div className="notification-card__image">
                <img
                  src={BurgerImage}
                  alt="burger"
                  className="notification-card__img"
                />
                <p className="notification-card__new">New</p>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

NotificationCard.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({}))
};

NotificationCard.defaultProps = {
  data: []
};

export default NotificationCard;

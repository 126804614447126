/* eslint-disable no-undef */
import './index.scss';

import React, { Component } from 'react';
import { withRouter } from 'react-router';

import AccountSubSecTitle from '../../components/accout-subsec-title';
import NotificationCard from '../../components/notification-card';
import NotificationEmptyCard from '../../components/notification-empty';
import Cancelpop from '../../components/cancel-popover';
import { LocalSessionKey } from '../../burger-king-lib/constants/app-constants';
import { LocalStorage } from '../../burger-king-lib/utils/storage.web';

class Notifications extends Component {
  constructor() {
    super();
    this.state = {
      // notification: [
      //   {
      //     text:
      //       'We’ve done the impossible. Get any of our Impossible Whoppers now at Re.1'
      //   },
      //   {
      //     text:
      //       'Your order no. 12345 has been delivered in record time! Tap to rate your experience!'
      //   },
      //   {
      //     text:
      //       '60 years of our flame-grilled, freshly prepared tradition goes into everyorder. Want to know our secret? Head on to BK Wall and see for yourself!'
      //   }
      // ]
      notification: [],
      offerApply: false
    };
  }

  componentDidMount() {
    const { location } = this.props;
    if (location.search.length) {
      const isLoggedIn = LocalStorage.get(LocalSessionKey.USER_DATA) || '';
      if (!Boolean(isLoggedIn)) {
        this.setState({
          offerApply: true
        });
      }
    }
  }
  getOfferData = () => {
    setTimeout(() => {
      this.setState({ offerApply: false });
      this.props.history.push('/');
    }, 3000);
    return (
      <>
        <div className="offerlisting_offer">
          <p className="offerlisting_offer--text">Alert</p>
          {/* <h3 className="offerlisting_offer--heading">
          Kindly Login to continue
        </h3> */}
          <p className="offerlisting_offer--text">Kindly Login to continue.</p>
          <p className="offerlisting_offer--notify"> Login and Re-try</p>
        </div>
      </>
    );
  };

  goBackOnClick = () => {
    const { history } = this.props;
    history.go(-1);
  };

  render() {
    const { goBackOnClick } = this;
    const { notification, offerApply } = this.state;
    const { theme } = this.props;
    return (
      <>
        {offerApply && (
          <Cancelpop content children={this.getOfferData()} theme="delivery" />
        )}
        <div className="notification">
          <AccountSubSecTitle
            title="Notifications"
            goBackOnClick={goBackOnClick}
            theme={theme}
          />
          <div className="notification__card">
            {notification && notification.length ? (
              notification.map(data => {
                return <NotificationCard data={data} />;
              })
            ) : (
              <NotificationEmptyCard />
            )}
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(Notifications);

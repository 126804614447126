import './index.scss';

import React, { Component } from 'react';
import moment from 'moment';

import RecentOrderProdList from '../../components/recent-order-prod-list';
// import ToolTip from '../../components/tooltip';
import OrderPayment from '../../components/order-payment';
import Button from '../../components/button';
// import InfoCharcoalIcon from '../../../assets/images/info-charcoal.svg';
import DeliveredToIcon from '../../../assets/images/location-inactive.svg';
import PaymentIcon from '../../../assets/images/pay-online-inactive.svg';
import OrderedForIcon from '../../../assets/images/ordered-for-inactive.svg';
import DeliveredOnIcon from '../../../assets/images/tickmark.svg';
import EstimatedDeliveryIcon from '../../../assets/images/recent-order-inactive.svg';

import { THEME } from '../../constants/app-constants';
import HelpCall from '../../components/help-call';
import Charity from '../../components/charity';

const { RESTAURANT, DELIVERY, DINE_IN } = THEME;

class RecentOrderDetail extends Component {
  getItemAddedFor = value => {
    const { isTrackOrder } = this.props;
    if (value === RESTAURANT) return 'Restaurant Takeaway at';
    if (value === DINE_IN) return 'Restaurant DIne-in at';
    if (isTrackOrder) return 'Delivered to';
    return 'Delivered to';
  };

  getItemAddress = () => {
    const { data } = this.props;
    if (data && data.cart_added_for === DELIVERY) {
      const { address } = data;
      return `${address && address.label} - ${address && address.location}`;
    }
    return data.outlet_name;
  };

  onOpenReciept = url => {
    window.open(`https://${url}`, '_blank');
  };

  render() {
    const { data, isTrackOrder, theme, onCallClick } = this.props;
    const { getItemAddedFor, getItemAddress, onOpenReciept } = this;
    const {
      ordered_by,
      ordered_user_mobile,
      payment_mode,
      products,
      cart_added_for,
      phone_no,
      status,
      estimated_time,
      delivered_time,
      billme_url
    } = data;
    const { innerWidth } = window;

    return (
      <>
        <div className="rct-ord-det">
          <div
            className={`rct-ord-det__list-wrapper  ${
              isTrackOrder ? 'rct-ord-det__list-wrapper_bg' : ''
            }`}
          >
            {isTrackOrder && innerWidth > 1023 ? (
              <div className="rct-ord-det__order-prepare">
                <p className="rct-ord-det__order-prepare-text">
                  Your order is being prepared
                </p>
                {cart_added_for === DELIVERY && innerWidth > 1023 ? (
                  <p className="rct-ord-det__order-prepare-text">
                    Contact : {phone_no}
                  </p>
                ) : (
                  ''
                )}
                {cart_added_for === DELIVERY && innerWidth < 1023 ? (
                  <HelpCall
                    customText="Call Restaurant"
                    theme={theme}
                    onCallClick={onCallClick}
                    number={phone_no}
                  />
                ) : (
                  ''
                )}
              </div>
            ) : (
              ''
            )}
            <ul
              className={`u_list_style_none rct-ord-det__list ${
                isTrackOrder ? 'u_mar_2rem_not_b_des' : ''
              }`}
            >
              <li className="rct-ord-det__list-item rct-ord-det__list-border">
                <div className="rct-ord-det__list-icon">
                  <img
                    src={DeliveredToIcon}
                    alt="delievered to"
                    className="rct-ord-det__list-img1"
                  />
                </div>
                <div className="newly_added_class">
                  <p className="rct-ord-det__list-title">
                    {getItemAddedFor(cart_added_for)}
                  </p>
                  <p className="rct-ord-det__list-content">
                    {getItemAddress()}
                  </p>
                </div>
              </li>
              {/* {`act-sidemenu-list u_cursor_pointer ${ */}
              <li className="rct-ord-det__list-item rct-ord-det__list-border">
                <div className="rct-ord-det__list-icon">
                  <img
                    src={PaymentIcon}
                    alt="payment"
                    className="rct-ord-det__list-img2"
                  />
                </div>
                <div className="newly_added_class">
                  <p className="rct-ord-det__list-title">Payment Method</p>
                  {/* <p className="rct-ord-det__list-content">{payment_mode} : ₹ {total_payable_amount}</p> */}
                  <p className="rct-ord-det__list-content">{payment_mode}</p>
                </div>
              </li>
              <li className="rct-ord-det__list-item u_display_flex rct-ord-det__list-border">
                <div
                  className={
                    !isTrackOrder
                      ? 'rct-ord-det__list-half'
                      : 'rct-ord-det__list-half u_width'
                  }
                >
                  <div className="rct-ord-det__list-icon">
                    <img
                      src={OrderedForIcon}
                      alt="ordered for"
                      className="rct-ord-det__list-img3"
                    />
                  </div>
                  <div className="u_overflow_hidden newly_added_class">
                    <p className="rct-ord-det__list-title">Ordered for</p>
                    <p className="rct-ord-det__list-content">{ordered_by}</p>
                    <p className="rct-ord-det__list-content">
                      {ordered_user_mobile}
                    </p>
                  </div>
                </div>
                {!isTrackOrder && status === 'DELIVERED' ? (
                  <div className="rct-ord-det__list-border_left rct-ord-det__list-half rct-ord-det__list-half_pad">
                    <div className="rct-ord-det__list-icon">
                      <img
                        src={DeliveredOnIcon}
                        alt="delivered on"
                        className="rct-ord-det__list-img4"
                      />
                    </div>
                    <div className="newly_added_class">
                      <p className="rct-ord-det__list-title">
                        {cart_added_for === DELIVERY
                          ? 'Delivered on'
                          : 'Picked Up on'}
                      </p>
                      <p className="rct-ord-det__list-content ">
                        {moment.utc(delivered_time).format('DD MMM YYYY')},
                      </p>
                      <p className="rct-ord-det__list-content">
                        {moment.utc(delivered_time).format('hh:mm A')}
                      </p>
                    </div>
                  </div>
                ) : (
                  ''
                )}
              </li>
              {isTrackOrder ? (
                <li className="rct-ord-det__list-item rct-ord-det__list-border">
                  <div className="rct-ord-det__list-icon">
                    <img
                      src={EstimatedDeliveryIcon}
                      alt="payment"
                      className=""
                    />
                  </div>
                  <div className="newly_added_class">
                    <p className="rct-ord-det__list-title">
                      Estimated Delivery
                    </p>
                    <p className="rct-ord-det__list-content">
                      {moment(estimated_time)
                        .utc()
                        .format('hh:mm A')}
                    </p>
                  </div>
                </li>
              ) : (
                ''
              )}
            </ul>
          </div>
          <RecentOrderProdList data={products} showImg />
          {/* {
            charity_amount ? 
              <Charity theme={cart_added_for} data={charity_item} checked disabled/>
            : ''
          } */}
          <div className="rct-ord-det__pay">
            <OrderPayment toolTipData={data} recentData />
          </div>
          <div
            style={{
              display: 'flex'
            }}
          >
            {billme_url && (
              <div className="rct-ord-det__reciept">
                <Button
                  theme={theme}
                  content="View Reciept"
                  size="is-small"
                  outlined
                  className="rct-ord-det__button"
                  onClick={() => onOpenReciept(billme_url)}
                />
              </div>
            )}
            {/* {billme_url && (
              <div className="rct-ord-det__reciept">
                <Button
                  theme={theme}
                  content="Chat With Us"
                  size="is-small"
                  outlined
                  className="rct-ord-det__button"
                  onClick={() => {
                    window &&
                      window.YellowMessengerPlugin &&
                      window.YellowMessengerPlugin.openBot();
                  }}
                />
              </div>
            )} */}
          </div>
          {/* <div className="rct-ord-det__pattern" /> */}
        </div>
      </>
    );
  }
}

export default RecentOrderDetail;

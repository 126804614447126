import {
  GET_SIGNUP,
  GET_SIGNUP_SUCCESS,
  GET_SIGNUP_ERROR,
  GET_VERIFY_OTP,
  GET_VERIFY_OTP_SUCCESS,
  GET_VERIFY_OTP_ERROR,
  ADD_ADDRESS,
  ADD_ADDRESS_SUCCESS,
  ADD_ADDRESS_ERROR,
  GET_UPDATE_USER,
  GET_UPDATE_USER_SUCCESS,
  GET_UPDATE_USER_ERROR,
  GET_EDIT_USER,
  GET_EDIT_USER_ERROR,
  GET_EDIT_USER_SUCCESS,
  GET_USER_DETAIL,
  GET_USER_DETAIL_ERROR,
  GET_USER_DETAIL_SUCCESS,
  UPLOAD_PROFILE_PIC,
  UPLOAD_PROFILE_PIC_SUCCESS,
  UPLOAD_PROFILE_PIC_ERROR,
  SIGNUP_WITH_EMAIL,
  SIGNUP_WITH_EMAIL_SUCCESS,
  SIGNUP_WITH_EMAIL_ERROR,
  GENERATE_PHONE_OTP,
  GENERATE_PHONE_OTP_SUCCESS,
  GENERATE_PHONE_OTP_ERROR,
  REQUESTING_ACCOUNT_DELETE,
  REQUESTING_ACCOUNT_DELETE_SUCCESS,
  REQUESTING_ACCOUNT_DELETE_ERROR
} from '../actionTypes';

const initialState = {
  signup: {},
  verifyOtp: {},
  addAddress: {},
  updateUser: {},
  editUser: {},
  userDetail: {},
  deteleUserResponse: {}
};

export default function LoginReducer(state = initialState, action) {
  switch (action.type) {
    case GET_SIGNUP: {
      return {
        ...state,
        Payload: action.payload,
        action: action.type,
        signup: {},
        Error: null,
        meta: action.meta
      };
    }
    case GET_SIGNUP_SUCCESS: {
      return {
        ...state,
        Payload: {},
        action: action.type,
        signup: action.value,
        Error: null,
        meta: action.meta
      };
    }
    case GET_SIGNUP_ERROR: {
      return {
        ...state,
        Payload: action.payload,
        action: action.type,
        signup: {},
        Error: action.error,
        meta: action.meta
      };
    }

    case GET_VERIFY_OTP: {
      return {
        ...state,
        Payload: action.payload,
        action: action.type,
        verifyOtp: {},
        Error: null,
        meta: action.meta
      };
    }
    case GET_VERIFY_OTP_SUCCESS: {
      return {
        ...state,
        Payload: {},
        action: action.type,
        verifyOtp: action.value,
        Error: null,
        meta: action.meta
      };
    }
    case GET_VERIFY_OTP_ERROR: {
      return {
        ...state,
        Payload: action.payload,
        action: action.type,
        verifyOtp: {},
        Error: action.error,
        meta: action.meta
      };
    }

    case ADD_ADDRESS: {
      return {
        ...state,
        Payload: action.payload,
        action: action.type,
        addAddress: {},
        Error: null,
        meta: action.meta
      };
    }
    case ADD_ADDRESS_SUCCESS: {
      return {
        ...state,
        Payload: {},
        action: action.type,
        addAddress: action.value,
        Error: null,
        meta: action.meta
      };
    }
    case ADD_ADDRESS_ERROR: {
      return {
        ...state,
        Payload: action.payload,
        action: action.type,
        addAddress: {},
        Error: action.error,
        meta: action.meta
      };
    }

    case GET_UPDATE_USER: {
      return {
        ...state,
        Payload: action.payload,
        action: action.type,
        updateUser: {},
        Error: null,
        meta: action.meta
      };
    }
    case GET_UPDATE_USER_SUCCESS: {
      return {
        ...state,
        Payload: {},
        action: action.type,
        updateUser: action.value,
        Error: null,
        meta: action.meta
      };
    }
    case GET_UPDATE_USER_ERROR: {
      return {
        ...state,
        Payload: action.payload,
        action: action.type,
        updateUser: {},
        Error: action.error,
        meta: action.meta
      };
    }

    case GET_EDIT_USER: {
      return {
        ...state,
        Payload: action.payload,
        action: action.type,
        editUser: {},
        Error: null,
        meta: action.meta
      };
    }
    case GET_EDIT_USER_SUCCESS: {
      return {
        ...state,
        Payload: {},
        action: action.type,
        editUser: action.value,
        Error: null,
        meta: action.meta
      };
    }
    case GET_EDIT_USER_ERROR: {
      return {
        ...state,
        Payload: action.payload,
        action: action.type,
        editUser: {},
        Error: action.error,
        meta: action.meta
      };
    }

    case GET_USER_DETAIL: {
      return {
        ...state,
        Payload: action.payload,
        action: action.type,
        userDetail: {},
        Error: null,
        meta: action.meta
      };
    }
    case GET_USER_DETAIL_SUCCESS: {
      return {
        ...state,
        Payload: {},
        action: action.type,
        userDetail: action.value,
        Error: null,
        meta: action.meta
      };
    }
    case GET_USER_DETAIL_ERROR: {
      return {
        ...state,
        Payload: action.payload,
        action: action.type,
        userDetail: {},
        Error: action.error,
        meta: action.meta
      };
    }

    case UPLOAD_PROFILE_PIC: {
      return {
        ...state,
        Payload: action.payload,
        action: action.type,
        uploadProfilePic: {},
        Error: null,
        meta: action.meta
      };
    }
    case UPLOAD_PROFILE_PIC_SUCCESS: {
      return {
        ...state,
        Payload: {},
        action: action.type,
        uploadProfilePic: action.value,
        Error: null,
        meta: action.meta
      };
    }
    case UPLOAD_PROFILE_PIC_ERROR: {
      return {
        ...state,
        Payload: action.payload,
        action: action.type,
        uploadProfilePic: {},
        Error: action.error,
        meta: action.meta
      };
    }
    case SIGNUP_WITH_EMAIL: {
      return {
        ...state,
        Payload: action.payload,
        action: action.type,
        signInWithEmail: {},
        Error: null,
        meta: action.meta
      };
    }
    case SIGNUP_WITH_EMAIL_SUCCESS: {
      return {
        ...state,
        Payload: {},
        action: action.type,
        signInWithEmail: action.value,
        Error: null,
        meta: action.meta
      };
    }
    case SIGNUP_WITH_EMAIL_ERROR: {
      return {
        ...state,
        Payload: action.payload,
        action: action.type,
        signInWithEmail: {},
        Error: action.error,
        meta: action.meta
      };
    }

    case GENERATE_PHONE_OTP: {
      return {
        ...state,
        Payload: action.payload,
        action: action.type,
        otp: {},
        Error: null,
        meta: action.meta
      };
    }
    case GENERATE_PHONE_OTP_SUCCESS: {
      return {
        ...state,
        Payload: {},
        action: action.type,
        otp: action.value,
        Error: null,
        meta: action.meta
      };
    }
    case GENERATE_PHONE_OTP_ERROR: {
      return {
        ...state,
        Payload: action.payload,
        action: action.type,
        otp: {},
        Error: action.error,
        meta: action.meta
      };
    }
    case REQUESTING_ACCOUNT_DELETE: {
      return {
        ...state,
        Payload: action.payload,
        action: action.type,
        deteleUserResponse: {},
        Error: null,
        meta: action.meta
      };
    }
    case REQUESTING_ACCOUNT_DELETE_SUCCESS: {
      return {
        ...state,
        Payload: {},
        action: action.type,
        deteleUserResponse: action.value,
        Error: null,
        meta: action.meta
      };
    }
    case REQUESTING_ACCOUNT_DELETE_ERROR: {
      return {
        ...state,
        Payload: action.payload,
        action: action.type,
        deteleUserResponse: {},
        Error: action.error,
        meta: action.meta
      };
    }
    default: {
      return { ...state };
    }
  }
}

import './index.scss';
import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { getLocationInput } from '../../utils/imageChanges';

const LocationInput = props => {
  const { value, theme, customClass, disable, onClickLocation } = props;

  const location = useSelector(state => {
    const outletData = state?.cart?.outletData;
    if (theme === 'delivery') {
      return outletData?.location;
    } else if (theme === 'takeaway') {
      const restaurantDetail = JSON.parse(
        window.sessionStorage.getItem('RESTAURANT_DETAIL')
      );
      return restaurantDetail?.outlet_name;
    } else {
      return outletData?.outlet_name;
    }
  });

  return (
    <div
      className={`location-input ${
        value ? 'location-input-value' : ''
      } ${customClass} u_cursor_pointer`}
      role="presentation"
      onClick={() => {
        if (!disable) {
          onClickLocation();
        }
      }}
    >
      <img src={getLocationInput(theme)} alt="location" />
      <span className="location-input__value">
        {location || 'Tap on it to pick your location…'}
      </span>
    </div>
  );
};

LocationInput.propTypes = {
  value: PropTypes.string,
  theme: PropTypes.string
};

LocationInput.defaultProps = {
  value: '',
  theme: ''
};

export default LocationInput;

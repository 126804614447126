import React from 'react';
import PropTypes from 'prop-types';

import './index.scss'

const ToggleButton = props => {
  const { name, id, label, onChange, disabled, checked, theme } = props;

  return (
    <div className={`custom-toggle ${theme}`}>
      <label className="">
        <input 
          type="checkbox" 
          className="custom-toggle__checkbox" 
          disabled={disabled}
          name={name} 
          id={id}
          checked={checked} 
          onChange={e => onChange(e)}
        />
        <span className={`custom-toggle__custom ${checked ? '' : 'custom-toggle__custom_uncheck'}`}/>
      </label>
    </div>
  );
};

ToggleButton.propTypes = {
  name: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
  color: PropTypes.string,
};

ToggleButton.defaultProps = {
  id: '',
  label: '',
  onChange: null,
  disabled: false,
  checked: false,
  color: ''
};

export default ToggleButton;

import './index.scss';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import BKLOGO from '../../../assets/images/bkLogo.png';
import Input from '../input';
import Button from '../button';
// import Loader from '../loader-spin';
import { MobileNumberValidation } from '../../utils/validation';
import SocialLoginContainer from '../../containers/social-login';

class LoginNumber extends Component {
  constructor() {
    super();
    this.state = {
      mobileNumber: '',
      active: false,
      validation: {
        mobileNumber: false
      },
      currentFieldFocus: 'mobileNumber'
    };
  }

  componentDidMount() {
    if (
      document &&
      document.getElementsByClassName('login-number__mobile-input') &&
      document.getElementsByClassName('login-number__mobile-input')[0]
    )
      document
        .getElementsByClassName('login-number__mobile-input')[0]
        .addEventListener('keyup', this.enterListener);
  }

  componentWillUnmount() {
    if (
      document &&
      document.getElementsByClassName('login-number__mobile-input') &&
      document.getElementsByClassName('login-number__mobile-input')[0]
    )
      document
        .getElementsByClassName('login-number__mobile-input')[0]
        .removeEventListener('keyup', this.enterListener);
  }

  enterListener = event => {
    event.preventDefault();
    if (event.keyCode === 13) {
      this.onClick();
    }
  };

  inputChangeHandler = (id, value) => {
    const { onChange } = this.props;
    this.setState({ [id]: value, active: true });
    if (value && value.length === 10) onChange('get otp');
    else onChange('Enter 10 digit phone number');
  };

  validationChangeHandler = (id, value) => {
    const { validation } = this.state;
    const validationCopy = { ...validation };
    validationCopy[id] = value;
    this.setState({ validation: validationCopy });
  };

  validationOnClickHandler = () => {
    const { validation, mobileNumber } = this.state;
    const validationCopy = { ...validation };
    validationCopy.mobileNumber = MobileNumberValidation(mobileNumber);
    this.setState({ validation: validationCopy });
    return validation;
  };

  onClick = () => {
    const { onClick } = this.props;
    const { mobileNumber } = this.state;
    const validation = this.validationOnClickHandler();

    if (
      Object.keys(validation).every(k => {
        if (!validation[k]) alert('enter number');
        return validation[k];
      })
    ) {
      onClick(mobileNumber);
    }
  };

  render() {
    const { mobileNumber, active, currentFieldFocus } = this.state;
    const {
      mobileNumberBtnTxt,
      theme,
      onFacebookLogin,
      responseGoogle,
      showSocialLogin
    } = this.props;

    return (
      <div className="login-number">
        <img src={BKLOGO} width="50" height="50" />
        <p className="login-number__title">Login</p>
        <p className="login-number__description">
          Enter your mobile number to proceed
        </p>
        <div
          className={`login-number__number ${
            active ? `login-number__number_active ${theme}` : ''
          }`}
        >
          <div className="login-number__code">+91</div>
          <div className="login-number__mobile-input">
            <Input
              theme={theme}
              currentFieldFocus={currentFieldFocus}
              onChange={this.inputChangeHandler}
              placeholder="Enter 10 digit mobile number"
              id="mobileNumber"
              value={mobileNumber}
              type="text"
              isMobileNumber
              customClass="login-number__input"
              validationChange={this.validationChangeHandler}
            />
          </div>
        </div>
        <div className="login-number__button">
          <Button
            theme={theme}
            className="login-number__button_inactive"
            content={mobileNumberBtnTxt}
            type="block"
            size="is-medium"
            disabled={mobileNumber.length !== 10 || false}
            onClick={this.onClick}
          />
        </div>
        {showSocialLogin && (
          <SocialLoginContainer
            onFacebookLogin={onFacebookLogin}
            responseGoogle={responseGoogle}
          />
        )}
      </div>
    );
  }
}

LoginNumber.propTypes = {
  onClick: PropTypes.func,
  onChange: PropTypes.func,
  mobileNumberBtnTxt: PropTypes.string
};

LoginNumber.defaultProps = {
  onClick: () => {},
  onChange: () => {},
  mobileNumberBtnTxt: ''
};

export default LoginNumber;

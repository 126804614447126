import React, { useEffect } from 'react';
import Modal from '../../containers/modal';
import logo from '../../../assets/images/logo.png';
import Button from '../button';

import './index.scss';

const Cancelpop = props => {
  const { yesBtnFunction, noBtnFunction, content, children,theme } = props;

  return (
    <Modal centerPopUp hideClose logo>
      <div className={`cancelpopout ${content && theme} `}>
        {/* <div className="cancelpopout--imgCover" /> */}
        <div className="cancelpopout__content">
          <div className="cancelpopout__content--img">
            <img src={logo} alt="logo" width="100" height="100" />
          </div>
          <div className="cancelpopout__content--data">
            {content ? (
              children
            ) : (
              <>
                <p className="cancelpopout__content--txt">
                  Are you sure to let go of those tasty carvings ?
                </p>
                <div className="cancelpopout__content--btns">
                  <Button
                    className="cancelpopout__content--btns-1 cancelpopout__content--btns-btn"
                    onClick={() => yesBtnFunction()}
                    content="Yes"
                  />
                  <Button
                    className="cancelpopout__content--btns-btn"
                    onClick={() => noBtnFunction()}
                    content="No"
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default Cancelpop;

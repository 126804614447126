import './index.scss';

import React from 'react';
import notificationEmptyImage from '../../../assets/images/Notifications.png';
// import PropTypes from 'prop-types';

const NotificationEmptyCard = () => {
  return (
    <div className="notification-empty">
      <img src={notificationEmptyImage} alt="empty" className="notification-empty__image" />
      <p className="notification-empty__text">You are all caught up!</p>
    </div>
  );
};

export default NotificationEmptyCard;

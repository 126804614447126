import moment from 'moment';

export function getSignUpPayload(number) {
  return {
    phone_no: Number(number)
  };
}

export function getOtpPayload(number, value) {
  return {
    phone_no: Number(number),
    otp: value
  };
}

export function getEmailPayload(mail, accessToken) {
  return {
    email: mail,
    access_token: accessToken
  };
}

export function getGenerateOtpPayload(mail, number) {
  return {
    email: mail,
    phone_no: Number(number)
  };
}

export function getUserUpdatePayload(name) {
  return {
    name
  };
}

export function getEditUserPayload(
  name,
  dob,
  emailId,
  phoneNumber,
  profile_pic,
  gender
) {
  return {
    name,
    dob: dob ? moment(dob, 'DD/MM/YYYY').format('YYYY-MM-DD') : '',
    email: emailId,
    phone_no: phoneNumber,
    profile_pic,
    gender
  };
}

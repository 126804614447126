const { LocalSessionKey } = require('../constants/app-constants');

function parseJson(str) {
  try {
    return JSON.parse(str);
  } catch (e) {
    return str;
  }
}

/* Session Storage */
function setSession(key, str) {
  let parsedVal = str;
  if (parsedVal && typeof str !== 'string') {
    parsedVal = JSON.stringify(parsedVal);
  }
  sessionStorage.setItem(key, parsedVal);
}

function getSession(key) {
  let value = sessionStorage.getItem(key);
  if (!value) {
    return value;
  }
  value = parseJson(value);
  return value;
}

function removeSession(key) {
  if (sessionStorage.getItem(key)) {
    sessionStorage.removeItem(key);
  }
}

function removeAllSession() {
  removeSession(LocalSessionKey.KEY_USER_DATA);
}

/* LocalStorage */
function setLocal(key, str) {
  let parsedVal = str;
  if (parsedVal && typeof str !== 'string') {
    parsedVal = JSON.stringify(parsedVal);
  }
  localStorage.setItem(key, parsedVal);
}

function getLocal(key) {
  let value = localStorage.getItem(key);
  if (!value) {
    return value;
  }
  value = parseJson(value);
  return value;
}

function removeLocal(key) {
  if (localStorage.getItem(key)) {
    localStorage.removeItem(key);
  }
}

function removeAllLocal() {
  removeLocal(LocalSessionKey.KEY_USER_DATA);
  // window.localStorage.removeItem('crowns');
  // window.localStorage.removeItem('USER_DATA');
  // window.localStorage.removeItem('NAME');
}

const SessionStorage = {
  set: setSession,
  get: getSession,
  remove: removeSession,
  clearAll: removeAllSession
};

const LocalStorage = {
  set: setLocal,
  get: getLocal,
  remove: removeLocal,
  clearAll: removeAllLocal
};

module.exports = {
  SessionStorage,
  LocalStorage
};

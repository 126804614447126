import './index.scss';

import React from 'react';

import ToggleBetweenValues from '../../components/toggle-between-values';
import LocationInput from '../../components/location-input';

import { getHamburger, getSearchImage } from '../../utils/imageChanges';
import DineinTheatreHeader from '../../components/dinein-theatre-header';

const MobileHomeHeader = props => {
  const {
    theme,
    onChange,
    value,
    searchClick,
    onClickLocation,
    hamburgerClick,
    showDineinTheatre
  } = props;
  return (
    <>
      {' '}
      <div className="mob-header">
        <div className="mob-header__logo">
          <img
            src={getHamburger(theme)}
            alt="HamburgerLogo"
            className="mob-header__logo-mobile"
            role="presentation"
            onClick={() => hamburgerClick()}
          />
        </div>

        <div className="mob-header__user-choice">
          {showDineinTheatre ? (
            <DineinTheatreHeader />
          ) : (
            <ToggleBetweenValues
              theme={theme}
              leftContent="delivery"
              rightContent="dine-in/takeaway"
              onChange={onChange}
              value={theme}
              checked={theme === 'dinein' || theme === 'takeaway' || false}
            />
          )}
          <div className="mob-header__divider" />
          <LocationInput
            readOnly
            value={value}
            theme={theme}
            disable={showDineinTheatre}
            onClickLocation={onClickLocation}
          />
        </div>
        <div
          className="mob-header__search"
          role="presentation"
          onClick={() => searchClick()}
        >
          <img src={getSearchImage(theme)} alt="search" />
        </div>
      </div>
    </>
  );
};

export default MobileHomeHeader;

import * as ActionTypes from '../actionTypes';

export const getProductList = (payloadData, meta) => ({
  type: ActionTypes.GET_PRODUCT_LIST,
  payload: payloadData,
  meta
});

export const getProductListSuccess = (value, action) => ({
  type: ActionTypes.GET_PRODUCT_LIST_SUCCESS,
  value,
  meta: action.meta
});

export const getProductListError = (error, action) => ({
  type: ActionTypes.GET_PRODUCT_LIST_ERROR,
  error,
  payload: action.payload,
  meta: action.meta
});

export const getProductList2 = (payloadData, meta) => ({
  type: ActionTypes.GET_PRODUCT_LIST2,
  payload: payloadData,
  meta
});

export const getProductList2Success = (value, action) => ({
  type: ActionTypes.GET_PRODUCT_LIST2_SUCCESS,
  value,
  meta: action.meta
});

export const getProductList2Error = (error, action) => ({
  type: ActionTypes.GET_PRODUCT_LIST2_ERROR,
  error,
  payload: action.payload,
  meta: action.meta
});

export const getSearchProductList = (payloadData, meta) => ({
  type: ActionTypes.GET_SEARCH_PRODUCT_LIST,
  payload: payloadData,
  meta
});

export const getSearchProductListSuccess = (value, action) => ({
  type: ActionTypes.GET_SEARCH_PRODUCT_LIST_SUCCESS,
  value,
  meta: action.meta
});

export const getSearchProductListError = (error, action) => ({
  type: ActionTypes.GET_SEARCH_PRODUCT_LIST_ERROR,
  error,
  payload: action.payload,
  meta: action.meta
});

export const getProductFromCart = (payloadData, meta) => ({
  type: ActionTypes.GET_PRODUCT_FROM_CART,
  payload: payloadData,
  meta
});

export const getProductFromCartSuccess = (value, action) => ({
  type: ActionTypes.GET_PRODUCT_FROM_CART_SUCCESS,
  value,
  meta: action.meta
});

export const getProductFromCartError = (error, action) => ({
  type: ActionTypes.GET_PRODUCT_FROM_CART_ERROR,
  error,
  payload: action.payload,
  meta: action.meta
});

export const getSearchSuggestions = (payloadData, meta) => ({
  type: ActionTypes.GET_SEARCH_SUGGESTIONS,
  payload: payloadData,
  meta
});

export const getSearchSuggestionsSuccess = (value, action) => ({
  type: ActionTypes.GET_SEARCH_SUGGESTIONS_SUCCESS,
  value,
  meta: action.meta
});

export const getSearchSuggestionsError = (error, action) => ({
  type: ActionTypes.GET_SEARCH_SUGGESTIONS_ERROR,
  error,
  payload: action.payload,
  meta: action.meta
});

export const getProductAddonDetail = (payloadData, meta) => ({
  type: ActionTypes.GET_MENU_CATEGORY,
  payload: payloadData,
  meta
});

export const getProductAddonDetailSuccess = (value, action) => ({
  type: ActionTypes.GET_MENU_CATEGORY_SUCCESS,
  value,
  meta: action.meta
});

export const getProductAddonDetailError = (error, action) => ({
  type: ActionTypes.GET_MENU_CATEGORY_ERROR,
  error,
  payload: action.payload,
  meta: action.meta
});

export const getAllAddons = (payloadData, meta, extraKey) => ({
  type: ActionTypes.GET_ALL_ADDONS,
  payload: payloadData,
  meta,
  extraKey
});

export const getAllAddonsSuccess = (value, action) => ({
  type: ActionTypes.GET_ALL_ADDONS_SUCCESS,
  value,
  meta: action.meta,
  extraKey: action.extraKey
});

export const getAllAddonsError = (error, action) => ({
  type: ActionTypes.GET_ALL_ADDONS_ERROR,
  error,
  payload: action.payload,
  meta: action.meta
});

export const getAllAddonsLoader = payloadData => ({
  type: ActionTypes.GET_ALL_ADDONS_LOADER,
  payload: payloadData
});

import {
  GET_ADDRESS_LIST,
  GET_ADDRESS_LIST_SUCCESS,
  GET_ADDRESS_LIST_ERROR,
  ADD_ADDRESS,
  ADD_ADDRESS_SUCCESS,
  ADD_ADDRESS_ERROR,
  DELETE_ADDRESS,
  DELETE_ADDRESS_SUCCESS,
  DELETE_ADDRESS_ERROR,
  GET_OUTLET_LIST,
  GET_OUTLET_LIST_SUCCESS,
  GET_OUTLET_LIST_ERROR
} from '../actionTypes';

const initialState = {
  cart: {}
};
export default function CartReducer(state = initialState, action) {
  switch (action.type) {
    case GET_ADDRESS_LIST: {
      return {
        ...state,
        Payload: action.payload,
        action: action.type,
        getAddressList: {},
        Error: null,
        meta: action.meta
      };
    }
    case GET_ADDRESS_LIST_SUCCESS: {
      return {
        ...state,
        Payload: {},
        action: action.type,
        getAddressList: action.value,
        Error: null,
        meta: action.meta
      };
    }
    case GET_ADDRESS_LIST_ERROR: {
      return {
        ...state,
        Payload: action.payload,
        meta: action.meta,
        action: action.type,
        getAddressList: {},
        Error: action.error
      };
    }
    case ADD_ADDRESS: {
      return {
        ...state,
        Payload: action.payload,
        action: action.type,
        addAddress: {},
        Error: null,
        meta: action.meta
      };
    }
    case ADD_ADDRESS_SUCCESS: {
      return {
        ...state,
        Payload: {},
        action: action.type,
        addAddress: action.value,
        Error: null,
        meta: action.meta
      };
    }
    case ADD_ADDRESS_ERROR: {
      return {
        ...state,
        Payload: action.payload,
        meta: action.meta,
        action: action.type,
        addAddress: {},
        Error: action.error
      };
    }

    case DELETE_ADDRESS: {
      return {
        ...state,
        Payload: action.payload,
        action: action.type,
        deleteAddress: {},
        Error: null,
        meta: action.meta
      };
    }
    case DELETE_ADDRESS_SUCCESS: {
      return {
        ...state,
        Payload: {},
        action: action.type,
        deleteAddress: action.value,
        Error: null,
        meta: action.meta
      };
    }
    case DELETE_ADDRESS_ERROR: {
      return {
        ...state,
        Payload: action.payload,
        meta: action.meta,
        action: action.type,
        deleteAddress: {},
        Error: action.error
      };
    }

    case GET_OUTLET_LIST: {
      return {
        ...state,
        Payload: action.payload,
        action: action.type,
        outlets: {},
        Error: null,
        meta: action.meta
      };
    }
    case GET_OUTLET_LIST_SUCCESS: {
      return {
        ...state,
        Payload: {},
        action: action.type,
        outlets: action.value,
        Error: null,
        meta: action.meta
      };
    }
    case GET_OUTLET_LIST_ERROR: {
      return {
        ...state,
        Payload: action.payload,
        meta: action.meta,
        action: action.type,
        outlets: {},
        Error: action.error
      };
    }
    default: {
      return {
        ...state
      };
    }
  }
}

import './index.scss';

import React from 'react';
import PropTypes from 'prop-types';

import Settings from '../../pages/settings';

import recentOrderActiveImage from '../../../assets/images/recent-order-active.svg';
import locationActiveImage from '../../../assets/images/location-active.svg';
import RightArrowWhiteImage from '../../../assets/images/right-arrow-white.svg';
import myVoucherActiveImage from '../../../assets/images/voucher-icon-active.svg';
import notificationActiveImage from '../../../assets/images/notification-icon-active.svg';
import supportActiveImage from '../../../assets/images/support-icon-active.svg';
import settingsActiveImage from '../../../assets/images/settings-icon-active.svg';
import crownImage from '../../../assets/images/offers/crown.png';

import {
  getRecentOrdersImage,
  getLocationInput,
  getRightArrowImage,
  getVoucherImage,
  getNotificationImage,
  getSupportImage,
  getSettingsIcon,
  getBkWallActiveImage
} from '../../utils/imageChanges';

const AccountSideMenuList = props => {
  const {
    activeKey,
    theme,
    onMenuClick,
    showSettingsList,
    activeSettingsList,
    userAvailablePoints
  } = props;
  const { innerWidth } = window;
  return (
    <ul className="u_list_style_none act-sidemenu-list_wrapper">
      <li
        key="crown-history"
        className={`act-sidemenu-list  act-sidemenu-list_crown ${theme} u_cursor_pointer ${
          activeKey === 'crown-history'
            ? `act-sidemenu-list_active ${theme}`
            : ''
        }`}
        role="presentation"
        onClick={() => onMenuClick('crown-history')}
      >
        <div className="act-sidemenu-list__crown">
          <div className="act-sidemenu-list__crown-image">
            <img
              src={crownImage}
              alt="crown"
              className="act-sidemenu-list__crown-img"
            />
          </div>
          <div className="act-sidemenu-list__crown-details">
            <p className="act-sidemenu-list__crown-name">
              {userAvailablePoints}
            </p>
            <p className="act-sidemenu-list__crown-text">BK Crowns</p>
          </div>
        </div>
      </li>
      <li
        key="recent-orders"
        className={`act-sidemenu-list   u_cursor_pointer ${
          activeKey === 'recent-orders'
            ? `act-sidemenu-list_active ${theme}`
            : ''
        }`}
        role="presentation"
        onClick={() => onMenuClick('recent-orders')}
      >
        <div className="act-sidemenu-list__name">
          <span className="act-sidemenu-list__icon">
            <img
              src={
                activeKey === 'recent-orders'
                  ? recentOrderActiveImage
                  : getRecentOrdersImage(theme)
              }
              alt="recent-orders"
            />
          </span>
          Recent Orders
        </div>
      </li>
      {!window.sessionStorage.getItem('THEATRE_RESPONSE') && (
        <li
          key="saved-addressess"
          className={`act-sidemenu-list   u_cursor_pointer ${
            activeKey === 'saved-addressess'
              ? `act-sidemenu-list_active ${theme}`
              : ''
          }`}
          role="presentation"
          onClick={() => onMenuClick('saved-addressess')}
        >
          <div className="act-sidemenu-list__name">
            <span className="act-sidemenu-list__icon">
              <img
                src={
                  activeKey === 'saved-addressess'
                    ? locationActiveImage
                    : getLocationInput(theme)
                }
                alt="saved-addressess"
              />
            </span>
            Saved Addresses
          </div>
        </li>
      )}
      <li
        key="my-vouchers"
        className={`act-sidemenu-list   u_cursor_pointer ${
          activeKey === 'my-vouchers' ? `act-sidemenu-list_active ${theme}` : ''
        }`}
        role="presentation"
        onClick={() => onMenuClick('my-vouchers')}
      >
        <div className="act-sidemenu-list__name">
          <span className="act-sidemenu-list__icon">
            <img
              src={
                activeKey === 'my-vouchers'
                  ? myVoucherActiveImage
                  : getVoucherImage(theme)
              }
              alt="my-vouchers"
            />
          </span>
          Saved King Deals
        </div>
      </li>
      <li
        key="bk-wall"
        className={`act-sidemenu-list   u_cursor_pointer ${
          activeKey === 'bk-wall' ? `act-sidemenu-list_active ${theme}` : ''
        }`}
        role="presentation"
        onClick={() => onMenuClick('bk-wall')}
      >
        <div className="act-sidemenu-list__name">
          <span className="act-sidemenu-list__icon">
            <img src={getBkWallActiveImage(theme)} alt="bk-wall" />
          </span>
          BK Wall
        </div>
      </li>

      {innerWidth > 1023 && (
        <li
          key="notifications"
          className={`act-sidemenu-list act-sidemenu-list__auto  u_cursor_pointer ${
            activeKey === 'notifications'
              ? `act-sidemenu-list_active ${theme}`
              : ''
          }`}
          role="presentation"
          onClick={() => onMenuClick('notifications')}
        >
          <div className="act-sidemenu-list__name">
            <span className="act-sidemenu-list__icon act-sidemenu-list__icon-mob">
              <img
                src={
                  activeKey === 'notifications'
                    ? notificationActiveImage
                    : getNotificationImage(theme)
                }
                alt="notifications"
              />
            </span>
            Notifications
          </div>
          <div className={`act-sidemenu-list__notify ${theme}`} />
        </li>
      )}
      <li
        key="support"
        className={`act-sidemenu-list u_cursor_pointer ${
          activeKey === 'support' ? `act-sidemenu-list_active ${theme}` : ''
        }`}
        role="presentation"
        onClick={() => onMenuClick('support')}
      >
        <div className="act-sidemenu-list__name">
          <span className="act-sidemenu-list__icon">
            <img
              src={
                activeKey === 'support'
                  ? supportActiveImage
                  : getSupportImage(theme)
              }
              alt="support"
            />
          </span>
          FAQ's & Support
        </div>
      </li>
      <li
        key="settings"
        className={`act-sidemenu-list   u_cursor_pointer ${
          activeKey === 'settings' ? `act-sidemenu-list_active ${theme}` : ''
        }`}
        role="presentation"
        onClick={() => onMenuClick('settings')}
      >
        <div className="act-sidemenu-list__name">
          <span className="act-sidemenu-list__icon">
            <img
              src={
                activeKey === 'settings'
                  ? settingsActiveImage
                  : getSettingsIcon(theme)
              }
              alt="settings"
            />
          </span>
          Settings
        </div>
        <img
          src={
            activeKey === 'settings'
              ? RightArrowWhiteImage
              : getRightArrowImage(theme)
          }
          className={`act-sidemenu-list__arrow ${
            showSettingsList ? 'act-sidemenu-list__arrow_active' : ''
          }`}
          alt="arrow"
        />
      </li>
      {showSettingsList ? (
        <Settings
          theme={theme}
          activeKey={activeSettingsList}
          handleShowSettingsList={props?.handleShowSettingsList}
        />
      ) : (
        ''
      )}
    </ul>
  );
};

AccountSideMenuList.propTypes = {
  data: PropTypes.shape({})
};

AccountSideMenuList.defaultProps = {
  data: {}
};

export default AccountSideMenuList;

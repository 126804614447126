import './index.scss';

import React from 'react';

import Button from '../button';
import Modal from '../../containers/modal';

const ToggleCartEmpty = props => {
  const { onCancelClick, onConfirmClick, theme } = props;
  return (
    <Modal
      centerPopUp
      onCloseHandler={onCancelClick}
      modalCustomClass="toggle-modal-width"
      hideClose
    >
      <div className="toggle-change">
        <div className="toggle-change__content">
          <p className="toggle-change__title">
            Switch to
            {theme === 'delivery' ? ' restaurant' : ' delivery'} mode?
          </p>
          <p className="toggle-change__description">
            Items in your cart will be cleared due to change of prices and item
            availability in restaurants
          </p>
          <div className="toggle-change__button">
            <div
              className="toggle-change__cancel-btn"
              onClick={onCancelClick}
              role="presentation"
            >
              <Button content="cancel" size="is-small" outlined theme={theme} />
            </div>
            <div
              className="toggle-change__confirm-btn"
              onClick={onConfirmClick}
              role="presentation"
            >
              <Button content="confirm" size="is-small" theme={theme} />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ToggleCartEmpty;

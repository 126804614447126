import './index.scss';

import React, { Component } from 'react';

class OffersToast extends Component {
  constructor(props){
    super(props);
    this.state = {
      content: props.content,
    }
  }

  componentDidMount() {
    this.setInterval();
  }

  destroy = () => {
    this.setState({ content: '' })
  }

  setInterval = () => {
    const { destroyHanlder } = this.props;
    let { timeout } = this.props;
    if (!timeout) timeout = 2500;
    let i = 0;
    let offset = 0;
    const interval = setInterval(() => {
      i += 1;
      offset = Math.round((1000 * i) / timeout);
      if (offset > 100) {
        clearInterval(interval);
        destroyHanlder();
      }
    }, 10);
  };

  render() {
    const { content } = this.state;
    const { isError } = this.props;
    return (
      content ? 
        <div className="offers-toast_wrapper"> 
          <div className={`offers-toast ${isError ? 'offers-toast_error' : ''}`}>
            {content}
          </div>
        </div>
        :''
    );
  }
}

export default OffersToast;
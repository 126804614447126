import './index.scss';

import React, { Component } from 'react';

import Input from '../input';

const CODE_LENGTH = new Array(4).fill(0);

class VerifyOTP extends Component {
  // input = React.createRef();

  constructor() {
    super();
    // this.input = React.createRef();
    this.state = {
      value: '',
      focused: false,
      width: this.setInputWidth()
    };
  }

  componentDidMount() {
    // this.handleClick();
  }

  componentDidUpdate(prevProps) {
    const { verifyOtpError } = this.props;
    if (verifyOtpError && prevProps.verifyOtpError !== verifyOtpError) {
      // CODE_LENGTH = new Array(4).fill(0);
      this.setValue();
    }
  }

  componentWillUnmount() {
    clearInterval(this.myInterval);
  }

  setValue = () => {
    this.setState({ value: '' });
  };

  setInputWidth = () => {
    if (window.innerWidth > 1023) {
      return 90;
    }
    if (window.innerWidth > 320 && window.innerWidth < 375) {
      return 60;
    }
    return 74;
  };

  // handleClick = () => {
  //   this.input.current.focus();
  // };

  // handleFocus = () => {
  //   this.setState({ focused: true });
  // };

  handleBlur = () => {
    this.setState({
      focused: false
    });
  };

  // handleKeyUp = e => {
  //   const { key, target } = e;
  //   const { id } = target;
  //   if (key === 'Backspace') {
  //     const { value } = this.state;
  //     // const otpValue = value.slice(0, value.length - 1);
  //     this.setState({ value: otpValue });
  //     this.onChangeValueUpdate(id, otpValue);
  //   }
  // };

  onChangeValueUpdate = (id, value) => {
    const { onChange } = this.props;
    onChange(id, value);
  };

  handleChange = (Id, val, e) => {
    const { target } = e;
    const { value: targetValue, id } = target;
    const { value } = this.state;
    let otpValue = value;
    if (!(value.length > 4)) {
      otpValue = targetValue.slice(0, 4);
    }
    this.setState({ value: otpValue });
    this.onChangeValueUpdate(id, otpValue);
  };

  // onClickEnteredValue = index => {
  //   const { value } = this.state;
  //   const values = value.split('');
  //   this.setState({ selectedEnterIndex: index, selectedIndexValue: values[index] })
  //   this.input.current.focus();
  // }

  render() {
    const {
      value,
      focused,
      width,
      selectedIndexValue,
      selectedEnterIndex
    } = this.state;
    const { theme, otpDisable, customClass } = this.props;

    // const values = value.split('');

    // const selectedIndex =
    //   selectedEnterIndex ||
    //   (values.length < CODE_LENGTH.length
    //     ? values.length
    //     : CODE_LENGTH.length - 1);

    // const hideInput = !selectedEnterIndex || !(values.length < CODE_LENGTH.length);

    return (
      <>
        <div className={`otp-input ${theme}`}>
          <div
            className={`otp-input__wrap ${
              value.length !== 0
                ? 'otp-input__display-wrapper_active'
                : 'otp-input__display-wrapper_inactive'
            }`}
            onClick={this.handleClick}
            role="presentation"
          >
            <Input
              id="value"
              value={value}
              type="text"
              currentFieldFocus="value"
              customClass={`otp-input__input ${customClass}`}
              onChange={this.handleChange}
              onFocus={this.handleFocus}
              // onKeyUp={this.handleKeyUp}
              placeholder="One time password"
              isNumber
              onpaste={this.onChange}
              disabled={otpDisable}
            />
            {/* <Input
              theme={theme}
              onChange={this.onChangeValueUpdate}
              onFocus={this.handleFocus}
              ref={this.input}
              placeholder="Mobile Number"
              id="mobileNumber"
              value={value}
              type="text"
              isNumber
              customClass="otp-input__input"
              // validationChange={this.validationChangeHandler}
            /> */}
            {/* {CODE_LENGTH.map((v, index) => {
              const selected = values.length === index;
              const filled = values[index];
              return (
                <>
                  <div
                    className={`otp-input__display-wrapper ${
                      (selected && focused) || filled
                        ? 'otp-input__display-wrapper_active'
                        : 'otp-input__display-wrapper_inactive'
                    }`}
                    style={{
                      width: `${width}px`
                    }}
                    // onClick={() => this.onClickEnteredValue(index)}
                  >
                    <div className="otp-input__display">{values[index]}</div>
                  </div>
                </>
              );
            })} */}
          </div>
        </div>
        {/* <div className="otp-input__timer">
          {error ? (
            <p className="otp-input__error">
              Too many incorrect attempts. Try again in 03:34
            </p>
          ) : (
            ''
          )}
          {seconds === 0 ? (
            ''
          ) : (
            <p className="otp-input__resend">
              Resending in{' '}
              <span className="otp-input__time-left">00 : {seconds}</span>
            </p>
          )}
        </div> */}
      </>
    );
  }
}

VerifyOTP.propTypes = {
  // onOtpChange: PropTypes.func
};

VerifyOTP.defaultProps = {
  // onOtpChange: () => {}
};

export default VerifyOTP;

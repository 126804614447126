import React from 'react';
import './index.scss';
import Read from '../../../assets/images/read.svg';

const OrderCancel = props => {
  const { onTermsConditonClick } = props;
  const theme = window.sessionStorage.getItem('theme');
  return (
    <div className="cancel-order">
      <div className="cancel-order__content">
        {theme === 'delivery' && (
          <>
            <div className="cancel-order__img">
              <img src={Read} alt="read" height="30" />
            </div>
            <p className="cancel-order__heading">
              Review your order and address details to avoid cancellation of
              your order.
            </p>
          </>
        )}
      </div>

      <div className="cancel-order__note">
        {theme === 'delivery' && (
          <p className="cancel-order__p">
            <span className="cancel-order__notes">Note:</span> If you choose to
            cancel your order, you can do it only within 60 seconds after
            placing your order.
          </p>
        )}
        <p
          className="cancel-order__link"
          onClick={() => onTermsConditonClick()}
        >
          <b>
            <u>Refer to Terms & Conditions</u>
          </b>
        </p>
      </div>
    </div>
  );
};

export default OrderCancel;

import { put, call, fork, takeLatest, takeEvery } from 'redux-saga/effects';
import {
  CHECKOUT_CART,
  GET_ADDONS_LIST,
  GET_PAYMENT_STATUS,
  GET_CHARITY_PRODUCT,
  GET_FEEDBACK,
  GET_LEGAL,
  CANCEL_ORDER,
  GET_CONTROLLERS,
  JUSPAY_PAYMENT_METHOD_STATUS
} from '../actionTypes';
import {
  GET_ADDONS_API,
  CHECKOUT_CART_API_WEB,
  GET_PAYMENT_STATUS_WEB_API,
  CHARITY_PRODUCT_API,
  FEEDBACK_API,
  CANCEL_ORDER_API,
  GET_CONTROLLERS_API,
  GET_LEGAL_API,
  JUSPAY_PAYMENT_METHOD_API
} from '../../constants/api-constants';
import * as API from '../../utils/ajax';
import * as Actions from './actions';

function* getCheckoutCartWebAsync(action) {
  try {
    const response = yield call(
      API.post,
      CHECKOUT_CART_API_WEB,
      action.payload
    );
    yield put(Actions.getCheckoutWebCartSuccess(response, action));
  } catch (error) {
    yield put(Actions.getCheckoutWebCartError(error, action));
  }
}

function* getAddonsListAsync(action) {
  try {
    const response = yield call(API.post, GET_ADDONS_API, action.payload);
    yield put(Actions.getAddonsListSuccess(response, action));
  } catch (error) {
    yield put(Actions.getAddonsListError(error, action));
  }
}

function* getPaymentWebStatusAsync(action) {
  try {
    const response = yield call(
      API.post,
      `${GET_PAYMENT_STATUS_WEB_API}`,
      action.payload
    );
    yield put(Actions.getPaymentWebStatusSuccess(response, action));
  } catch (error) {
    yield put(Actions.getPaymentWebStatusError(error, action));
  }
}

function* getJusPayPaymentMethodStatusAsync(action) {
  try {
    const response = yield call(
      API.post,
      `${JUSPAY_PAYMENT_METHOD_API}`,
      action.payload
    );
    yield put(
      Actions.juspayPaymentMethodSuccess(response, action, action?.meta)
    );
  } catch (error) {
    yield put(Actions.juspayPaymentMethodError(error, action));
  }
}

function* getLegalAsync(action) {
  try {
    const response = yield call(API.get, GET_LEGAL_API, action.payload);
    yield put(Actions.getLegalSuccess(response, action));
  } catch (error) {
    yield put(Actions.getLegalError(error, action));
  }
}

function* getCharityProductAsync(action) {
  try {
    const response = yield call(API.post, CHARITY_PRODUCT_API, action.payload);
    yield put(Actions.getCharityProductSuccess(response, action));
  } catch (error) {
    yield put(Actions.getCharityProductError(error, action));
  }
}

function* getFeedbackAsync(action) {
  try {
    const response = yield call(API.post, FEEDBACK_API, action.payload);
    yield put(Actions.getFeedbackSuccess(response, action));
  } catch (error) {
    yield put(Actions.getFeedbackError(error, action));
  }
}

function* getControllersAsync(action) {
  try {
    const response = yield call(API.get, GET_CONTROLLERS_API, action.payload);
    yield put(Actions.getControllersSuccess(response, action));
  } catch (error) {
    yield put(Actions.getControllersError(error, action));
  }
}

function* cancelOrderAsync(action) {
  try {
    const response = yield call(API.post, CANCEL_ORDER_API, action.payload);
    yield put(Actions.cancelOrderSuccess(response, action));
  } catch (error) {
    yield put(Actions.cancelOrderError(error, action));
  }
}

export function* watchCartSaga() {
  yield takeLatest(CHECKOUT_CART, getCheckoutCartWebAsync);
  yield takeLatest(GET_ADDONS_LIST, getAddonsListAsync);
  yield takeLatest(GET_PAYMENT_STATUS, getPaymentWebStatusAsync);
  yield takeLatest(GET_CHARITY_PRODUCT, getCharityProductAsync);
  yield takeLatest(GET_LEGAL, getLegalAsync);
  yield takeLatest(GET_FEEDBACK, getFeedbackAsync);
  yield takeLatest(CANCEL_ORDER, cancelOrderAsync);
  yield takeLatest(GET_CONTROLLERS, getControllersAsync);
  yield takeLatest(
    JUSPAY_PAYMENT_METHOD_STATUS,
    getJusPayPaymentMethodStatusAsync
  );
}
const cartSaga = [fork(watchCartSaga)];
export default cartSaga;

import './index.scss';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ToggleButton from '../toggle-button';

class NotificationSettingsCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checkedValue: {
        alert: true
      }
    };
  }

  onChange = e => {
    const { target } = e;
    const { id, checked } = target;
    const { checkedValue } = this.state;
    checkedValue[id] = checked;
    this.setState({ checkedValue })
  }

  render() {
    const { onChange } = this;
    const { theme, toggleData } = this.props;
    const { title,description } = toggleData;
    const { checkedValue } = this.state;
    const { alert } = checkedValue;
    return (
      <li className="notification-settings-card__item">
        <div>
          <p className="notification-settings-card__item-title">
            {title}
          </p>
          {description ? <p className="notification-settings__item-description">
            {description}
          </p> : ''}
        </div>
        <ToggleButton id="alert" name="" onChange={onChange} checked={alert} theme={theme} />
      </li>
    )
  }
}

export default NotificationSettingsCard;
import { call, fork, put, takeEvery, takeLatest } from 'redux-saga/effects';

import {
  CREATE_CLUB_OFFER_ONBOARDING,
  GET_BKWALL_DATA,
  GET_CART_OFFERS,
  GET_COUPONS,
  GET_CROWN_HISTORY,
  GET_DAILY_REWARDS,
  GET_FREE_CROWN,
  GET_LIMITED_DEALS,
  GET_OFFERS,
  GET_ORGANIZATION_LIST,
  GET_TOP_DEALS,
  GET_USER_AVAILABLE_POINTS,
  GET_USER_COUPONS
} from '../actionTypes';

import {
  CART_OFFERS,
  CREATE_CLUB_OFFER_ONBOARDING_API,
  CROWN_HISTORY_API,
  DAILY_REWARDS_API,
  EARN_FREE_CROWNS_API,
  GET_BKWALL_DATA_API,
  GET_COUPONS_API,
  GET_OFFERS_API,
  GET_USER_AVAILABLE_POINTS_API,
  GET_USER_COUPONS_API,
  LIMITED_DEALS_API,
  ORGANIZATION_LIST_API,
  TOP_DEALS_API
} from '../../constants/api-constants';

import * as API from '../../utils/ajax';
import * as Actions from './actions';

function* getOffersAsync(action) {
  try {
    const response = yield call(API.post, GET_OFFERS_API, action.payload);
    yield put(Actions.getOffersSuccess(response, action));
  } catch (error) {
    yield put(Actions.getOffersError(error, action));
  }
}

function* getUserAvailablePointsAsync(action) {
  try {
    const response = yield call(
      API.post,
      GET_USER_AVAILABLE_POINTS_API,
      action.payload
    );
    yield put(Actions.getUserAvailablePointsSuccess(response, action));
  } catch (error) {
    yield put(Actions.getUserAvailablePointsError(error, action));
  }
}

function* getCouponsAsync(action) {
  const { payload } = action;
  const { meta } = action;
  try {
    const response = yield call(API.post, GET_COUPONS_API, action.payload);
    yield put(Actions.getCouponsSuccess(response, action));
  } catch (error) {
    yield put(Actions.getCouponsError(error, action));
  }
}

function* getUserCouponsAsync(action) {
  try {
    const response = yield call(API.post, GET_USER_COUPONS_API, action.payload);
    yield put(Actions.getUserCouponsSuccess(response, action));
  } catch (error) {
    yield put(Actions.getUserCouponsError(error, action));
  }
}

function* getFreeCrownsAsync(action) {
  const { meta } = action;

  try {
    const response = yield call(API.get, EARN_FREE_CROWNS_API, action.payload);
    yield put(Actions.getFreeCrownsSuccess(response, action));
  } catch (error) {
    yield put(Actions.getFreeCrownsError(error, action));
  }
}

function* getLimitedDealsAsync(action) {
  try {
    const response = yield call(API.post, LIMITED_DEALS_API, action.payload);
    yield put(Actions.getLimitedDealsSuccess(response, action));
  } catch (error) {
    yield put(Actions.getLimitedDealsError(error, action));
  }
}

function* getTopDealsAsync(action) {
  try {
    const response = yield call(API.post, TOP_DEALS_API, action.payload);
    yield put(Actions.getTopDealsSuccess(response, action));
  } catch (error) {
    yield put(Actions.getTopDealsError(error, action));
  }
}

function* getDailyRewardsAsync(action) {
  const { meta } = action;

  try {
    const response = yield call(API.post, DAILY_REWARDS_API, action.payload);
    yield put(Actions.getDailyRewardsSuccess(response, action));
  } catch (error) {
    yield put(Actions.getDailyRewardsError(error, action));
  }
}

function* getCrownHistoryAsync(action) {
  const { meta } = action;

  try {
    const response = yield call(API.post, CROWN_HISTORY_API, action.payload);
    yield put(Actions.getCrownHistorySuccess(response, action));
  } catch (error) {
    yield put(Actions.getCrownHistoryError(error, action));
  }
}

function* getBkWallDataAsync(action) {
  const { meta } = action;

  try {
    const response = yield call(API.get, GET_BKWALL_DATA_API, action.payload);
    yield put(Actions.getBkWallDataSuccess(response, action));
  } catch (error) {
    yield put(Actions.getBkWallDataError(error, action));
  }
}

function* getCartOffersAsync(action) {
  try {
    const response = yield call(API.post, CART_OFFERS, action.payload);
    yield put(Actions.getCartOffersSuccess(response, action));
  } catch (error) {
    yield put(Actions.getCartOffersError(error, action));
  }
}

function* getOrganizationListAsync(action) {
  const { callBack } = action.meta;
  try {
    const response = yield call(API.get, ORGANIZATION_LIST_API, action.payload);
    if (callBack) {
      callBack(response);
    }
    yield put(Actions.getOrganizationListSuccess(response, action));
  } catch (error) {
    yield put(Actions.getOrganizationListError(error, action));
  }
}

function* createClubOfferOnboardingAsync(action) {
  const { callBack } = action.meta;
  try {
    const response = yield call(
      API.post,
      CREATE_CLUB_OFFER_ONBOARDING_API,
      action.payload
    );
    if (callBack) {
      callBack(response);
    }
    yield put(Actions.createClubOfferOnboardingSuccess(response, action));
  } catch (error) {
    yield put(Actions.createClubOfferOnboardingError(error, action));
  }
}

export function* watchOffersSaga() {
  yield takeLatest(GET_OFFERS, getOffersAsync);
  yield takeLatest(GET_USER_AVAILABLE_POINTS, getUserAvailablePointsAsync);
  yield takeLatest(GET_COUPONS, getCouponsAsync);
  yield takeLatest(GET_USER_COUPONS, getUserCouponsAsync);
  yield takeLatest(GET_FREE_CROWN, getFreeCrownsAsync);
  yield takeLatest(GET_LIMITED_DEALS, getLimitedDealsAsync);
  yield takeLatest(GET_TOP_DEALS, getTopDealsAsync);
  yield takeEvery(GET_DAILY_REWARDS, getDailyRewardsAsync);
  yield takeLatest(GET_CROWN_HISTORY, getCrownHistoryAsync);
  yield takeLatest(GET_BKWALL_DATA, getBkWallDataAsync);
  yield takeLatest(GET_CART_OFFERS, getCartOffersAsync);
  yield takeLatest(GET_ORGANIZATION_LIST, getOrganizationListAsync);
  yield takeLatest(
    CREATE_CLUB_OFFER_ONBOARDING,
    createClubOfferOnboardingAsync
  );
}

const offersSaga = [fork(watchOffersSaga)];
export default offersSaga;
